.rating {

  display: flex;
  font-size: rem(14px);

  .fa-star {

    position: relative;
    margin: 0 1px;

    &::before {

      position: relative;
      color: #2A5CAA;
      z-index: 1;
    }

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      content: "\f005";
      color: $gray-300;
      
    }
  }

  &[data-rating="0"] {

    i {

      &:before {
        color: $gray-300;
      }
    }
  }

  &[data-rating="0.1"],
  &[data-rating="0.2"],
  &[data-rating="0.3"],
  &[data-rating="0.4"],
  &[data-rating="0.5"],
  &[data-rating="0.6"],
  &[data-rating="0.7"],
  &[data-rating="0.8"],
  &[data-rating="0.9"] {

    i {

      &:nth-child(1) {

        &::before {

          content: '\f089';
        }
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="1"] {

    i {

      &:nth-child(1) {
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="1.1"],
  &[data-rating="1.2"],
  &[data-rating="1.3"],
  &[data-rating="1.4"],
  &[data-rating="1.5"],
  &[data-rating="1.6"],
  &[data-rating="1.7"],
  &[data-rating="1.8"],
  &[data-rating="1.9"] {

    i {

      &:nth-child(2) {

        &::before {

          content: '\f089';
        }
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }
  

  &[data-rating="2"] {

    i {

      &:nth-child(2) {
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="2.1"],
  &[data-rating="2.2"],
  &[data-rating="2.3"],
  &[data-rating="2.4"],
  &[data-rating="2.5"],
  &[data-rating="2.6"],
  &[data-rating="2.7"],
  &[data-rating="2.8"],
  &[data-rating="2.9"] {

    i {

      &:nth-child(3) {

        &::before {

          content: '\f089';
        }
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="3"] {

    i {

      &:nth-child(3) {
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="3.1"],
  &[data-rating="3.2"],
  &[data-rating="3.3"],
  &[data-rating="3.4"],
  &[data-rating="3.5"],
  &[data-rating="3.6"],
  &[data-rating="3.7"],
  &[data-rating="3.8"],
  &[data-rating="3.9"] {

    i {

      &:nth-child(4) {

        &::before {

          content: '\f089';
        }
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="4"] {

    i {

      &:nth-child(4) {
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="4.1"],
  &[data-rating="4.2"],
  &[data-rating="4.3"],
  &[data-rating="4.4"],
  &[data-rating="4.5"],
  &[data-rating="4.6"],
  &[data-rating="4.7"],
  &[data-rating="4.8"],
  &[data-rating="4.9"] {

    i {

      &:nth-child(5) {

        &::before {

          content: '\f089';
        }
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }

  &[data-rating="5"] {

    i {

      &:nth-child(5) {
        
        ~ i {

          &:before {
            color: $gray-300;
          }
        }
      }
    }
  }
}

