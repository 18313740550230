.room-tour-combo {

  margin: 30px 0;
  width: 95%;

  h3 {

    font-size: 1.5rem !important;
  }
}

.combo-card {

  margin-top: 20px;
  padding: 20px 20px 0;
  box-shadow: 0 0 10px 1px rgba($black, 0.3);
  border-radius: 8px;
  background: $white;

  h4 {

    font-size: rem(19px);
    margin-bottom: 0;
  }

  .arrow-btn {

    display: inline-block;
    font-family: 'swiper-icons';
    font-size: rem(20px);
    padding: 0 5px;
    border: none;
    background: none;
    outline: none;
    margin: 0 !important;
    cursor: pointer;
    color: $default-color;

    &::after {

      content: 'next';
    }
  }

  .card-panel {

    position: relative;
    display: flex;
    align-items: flex-start;
    
    &::after {

      position: absolute;
      top: 41px;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      border-top: 1px solid rgba($gray-150, 0.4);
    }

    &[aria-hidden="true"] {

      display: none;
    }

    &.only-child {

      align-items: center;
    
      &::after {

        display: none;
      }

      h4 {

        display: none;
      }

      .card-combo-result {

        flex-basis: 50%;
      }

      .card-info-text {

        font-size: rem(14px);

        h4 {

          display: initial;
          font-size: rem(19px) !important;
        }
      }

      .card-result {

        margin-top: 0 !important;
        padding-top: 0 !important;
        border: none !important;
        margin-left: -20px;
        border-top: 1px solid rgba($gray-150, 0.4);
      }

      .card-results {

        margin-left: -1px !important;
        padding-right: 0 !important;
      }

      .card-btn {
        flex-basis: 50%;
        flex-shrink: 1;
        padding-left: 20px;

        a {

          flex-grow: 1;
          padding: 15px 0;
          text-align: right;
  
          &:first-child {
  
            border-bottom: 1px solid rgba($gray-150, 0.4);
          }
        }
      }

      @include media-breakpoint-down(sm) {

        flex-direction: column;

        .card-info-text {

          font-size: rem(12px);

          h4 {

            display: initial;
            font-size: rem(16px) !important;
          }
        }

        .card-btn {

          margin-top: -15px;
          padding-left: 6px;
        }
      }
    }

    .card-info {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-grow: 1;
      padding-right: 10px;
    }

    .card-info-text {

      padding-right: 20px;
      margin-right: auto;
      margin-bottom: 5px;

      p {

        margin-bottom: 0;
      }
    }

    .card-btn {

      display: flex;
      flex-direction: column;
      flex-basis: 160px;
      flex-shrink: 0;
      margin-left: auto;
      margin-top: -15px;

      span {

        display: inline;
        font-family: "Montserrat";
        font-size: rem(18px);
        font-weight: 700;
        margin-right: 25px;
      }

      a {

        display: block;
        flex-grow: 1;
        padding: 15px 0;
        text-align: right;
      }

      .btn-green {

        font-size: rem(13px);
        font-weight: 500;
        padding: 1px 15px !important;
        border-radius: 0;
      }

      @include media-breakpoint-up(md) {

        .btn-green {

          display: none;
        }
      }
    }

    .card-combo-result {

      flex-grow: 1;

      .card-result {

        display: flex;
        flex-wrap: wrap;
        margin-top: 17px;
        padding-top: 17px;
        position: relative;
        overflow: hidden;

        .card-results {

          display: flex;
          align-items: center;
          flex-basis: 50%;
          font-size: rem(12px);
          padding-right: 20px;
          min-width: calc(50% + 20px);
          margin-bottom: 20px;
          margin-left: -20px;
          padding-left: 20px;
          border-left: 1px solid rgba($gray-150, 0.4);

          &:only-of-type {

            flex-grow: 1;

            .card-icon {

              width: 63px;
            }
          }

          h5 {

            font-size: rem(13px);
            font-weight: 600;
            margin-bottom: 0;
          }

          h4 {

            font-size: rem(13px);
            margin-bottom: 0;
          }

          .card-icon {

            margin-right: 20px;
            flex-shrink: 0;
            width: 41px;
          }

          .card-result-text {

            h4 {

              font-size: rem(13px);
              margin-bottom: 0;
            }

            p {

              margin-bottom: 0;
            }
          }

        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .card-panel {
      flex-wrap: wrap;
  
      .card-info {

        flex-direction: column;
        align-items: flex-start;
      }

      .card-combo-result {

        .card-result {

          flex-direction: column;
          margin-left: 0;

          .card-results {

            padding-right: 0;
            margin-left: 0;
            padding-left: 0;
            border-left: none;

            + .card-results {
            
              padding-top: 20px;
              border-top: 1px solid rgba($gray-150, 0.4);
            }
          }

          .card-info-text {

            max-width: 100%;
          }

        }

      }

      .card-btn {
        position: relative;
        margin-left: 0;
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
        text-align: center;
        background: $green-45;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 6px;
        z-index: 5;
        flex-basis: 100%;
        margin-top: -5px;

        .arrow-btn {

          display: none;
        }

        .btn-green {

          text-align: center !important;
          display: block;
          margin: 0;

          + .btn-green {

            border-left: 1px solid $default-color;
          }
        }
      }
    }
  }
  
  @include media-breakpoint-down(xs) {
  
    h4 {
  
      font-size: rem(18px);
    }

    .card-panel {

      flex-wrap: wrap;

      &.card-combo-results {

        .card-combo-result {

          min-width: 100%;
        }

        .card-icon {

          flex-shrink: 0;
          width: auto;
        }
      }

      .card-icon {

        flex-shrink: 0;
        width: 60px;
      }

      .card-info-text {

        padding-right: 0;
      }

      .card-info {

        margin-left: 0;
        width: calc(100% - 60px);
        font-size: rem(12px);

      }
    }
  }
}

.badge {

  line-height: normal;
  padding: 8px 15px;
  border-radius: 20px;
  background: $green-45;
  color: $white;
  white-space: initial;

  @include media-breakpoint-down(sm) {

    margin-left: 0;
    margin-top: 10px;
    font-size: 70%;
  }
}
