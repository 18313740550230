.content-destination-revamp {

  padding-top: 70px;
  padding-bottom: 4.5rem;

  .container {
    max-width: 1350px;
  }

  .container-md {

    max-width: 1082px;
  }

  h2 {

    font-size: rem(48px);
  }

  @include media-breakpoint-down(md) {

    padding-top: 50px;
    padding-bottom: 30px;

    h2 {
      font-size: rem(40px);
    }
  }

  @include media-breakpoint-down(sm) {

    font-size: rem(14px);

    h2 {
      text-align: center;
    }
  }
}

.pt-8 {

  padding-top: 80px;

  @include media-breakpoint-down(md) {

    padding-top: 50px;
  }
}

.flex-basis-sm-6 {

  flex-basis: 50%;

  @include media-breakpoint-down(xs) {

    flex-basis: 100%;
  }
}

.destination-info {

  .card {

    padding: 20px;
    border-radius: 10px;
    height: auto;

    h4 {

      font-size: rem(22px);
      font-weight: 500;
    }

    a {

      font-size: rem(14px);
      color: $dark-gray;
    }
  }

  @include media-breakpoint-down(sm) {

    .card {

      h4 {

        font-size: rem(18px);
      }
    }
  }
}

.mm-list {

  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: -20px;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: calc(50% - 20px);
    list-style: none;
    margin-left: 20px;
    margin-top: 20px;

    .mm-icons {

      flex-shrink: 0;
      margin-right: 5px;
      width: 34px;

      img {

        width: 30px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  @include media-breakpoint-down(md) {

    font-size: rem(14px);
  }

  @include media-breakpoint-down(sm) {

    font-size: rem(12px);
  }
}

.destination-gallery {

  position: relative;
  display: flex;
  flex-wrap: wrap;

  .img-lg {

    width: 50%;
    padding-bottom: 34%;
    position: relative;
    overflow: hidden;

    .img-objectfit {

      width: calc(100% + 1px);
    }
    
  }

  .img-mds {

    display: flex;
    flex-wrap: wrap;
    width: 50%;

    .img-md {

      width: 50%;
      padding-bottom: 34%;
      position: relative;
      overflow: hidden;
    }
  }

  .img-lg,
  .img-md {

    a {
      display: block;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba($black, 0.3);
        @include transition(all 0.3s ease);
        opacity: 0;
      }
    }

    &:hover {

      a {

        &::after {

          opacity: 1;
        }
      }
    }
  }

  .popup-btn {

    position: absolute;
    bottom: 20px;
    right: 40px;
    padding: 6px 10px 0;
    background: rgba($white, 0.7);
    border-radius: 5px;
    text-align: center;
    z-index: 2;
    font-size: rem(30px);
    height: 42px;
    cursor: pointer;

    a {

      color: $default-color;
    }

    span {

      font-size: rem(12px);
      font-weight: 500;
      display: block;
    }

    .fa-ellipsis {

      display: block;
      line-height: 13px;
    }

    svg {

      line-height: 1;
      @include transform(translateY(-16px));
    }
  }

  @include media-breakpoint-down(sm) {

    flex-direction: column;

    .img-lg {

      width: 100%;
      padding-bottom: 58%;
    }

    .img-mds {

      width: 100%;
    }

    .popup-btn {

      right: 15px;
      bottom: 15px;

      span {

        font-size: rem(10px);
      }
    }
  }
}

.destination-badges {

  border-bottom: 1px solid $default-color;
  padding-bottom: 30px;

  h5 {

    font-size: rem(14px);
    font-weight: 900;
  }

  .destination-badges-title {

    font-size: rem(20px);
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    span {

      flex-shrink: 0;
    }

    &::before,
    &::after {

      flex-grow: 1;
      border-top: 1px solid $default-color;
      width: 100%;
      height: 1px;
      content: '';
      margin-right: 20px;
    }

    &::after {

      margin-right: 0;
      margin-left: 20px;
    }
  }

  .row {

    justify-content: center !important;

    .img-fluid {

      max-width: 100%;
      width: auto;
      max-height: 72px;
    }
  }
}

.destination-cards {

  position: relative;
  overflow: hidden;

  &#rooms {

    .swiper-holder-2 {

      .swiper-slide {

        min-height: 460px !important;
      }

      @include break-until(1601px) {

        .swiper-slide {

          min-height: 380px !important;
        }
      }

      @include break-until(1441px) {

        .swiper-slide {

          min-height: 340px !important;
        }
      }

      @include media-breakpoint-down(lg) {

        .swiper-slide {

          min-height: 300px !important;
        }
      }

      @include media-breakpoint-down(md) {

        .swiper-slide {

          min-height: 250px !important;
        }
      }

    }
  }

  .swiper-holder-2,
  .swiper-change {

    max-width: initial;

    .swiper {

      padding: 20px 0;

      &.no-blur {

        max-width: 1072px;
        padding-left: 20px;
        padding-right: 20px;
        
        .swiper-slide {

          // width: 100% !important;
          width: 24vw !important;
          filter: blur(0);
          pointer-events: initial;

          img  {

            filter: blur(0);
          }

          &.swiper-slide-duplicate {

            display: none;
          }
        }

        .swiper-wrapper {

          @include transform(none !important);

          .swiper-slide {
            
            &:last-child {

              margin-right: 0 !important;
            }
          }
        }

        .swiper-button-next,
        .swiper-button-prev {

          display: none;
        }

        @include break-until(1024px) {

          .swiper-slide {

            // width: calc(50% - 10px) !important;
            width: 37vw !important;
            max-width: 320px;
          }
        }

        @include media-breakpoint-down(md) {

          // width: calc(100% - 160px);
        }

        @include break-until(576px) {

          width: 100%;

          .swiper-wrapper {

            overflow: auto;
            padding-top: 10px;
            padding-bottom: 20px;
  
            .swiper-slide {
              
              &.swiper-slide-duplicate-active {
  
                margin-right: 20px !important;
              }
            }
          }

          .swiper-slide {

            // width: calc(50% - 10px) !important;
            // width: 37vw !important;
            // max-width: 320px;

            width: 61vw !important;
            max-width: 338px;
          }
        }
        
      }
    }

    .swiper-wrapper {

      height: 100% !important;
    }

    .swiper-slide {

      height: auto !important;
    }

    .swiper-slide {

      border-radius: 10px;
      box-shadow: none;
      filter: blur(6px);
      pointer-events: none;

      img {

        filter: blur(0);
        opacity: 1 !important;
      }

      &.swiper-slide-active {

        filter: blur(0);
        pointer-events: initial;
      }

      @include break-from(1025px) {

        &.swiper-slide-next,
        &.swiper-slide-prev {

          filter: blur(0);
          pointer-events: initial;
        }
      }

    }

    .swiper-button-next {

      right: 8.5%;
    }

    .swiper-button-prev {

      left: 8.5%;
    }

    @include media-breakpoint-up(lg) {

      .swiper-slide {

        max-width: 460px;
      }
    }

    @include media-breakpoint-down(md) {

      // margin-left: -70px;
      // margin-right: -70px;

      .swiper-button-next {

        right: 10px;
      }

      .swiper-button-prev {

        left: 10px;
      }
    }
  }
}

.gradient-bg {

  .destination-cards .swiper-holder-2 .swiper.no-blur {
	
    max-width: 76vw;

    @include media-breakpoint-down(md) {

      max-width: 100%;
    }
  }
}

.card-room {

  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: $gray-250;

  &.card-room-sq {

    padding-bottom: 100%;

    img {

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.card-room-rec {

    padding-bottom: 110%;

    img {

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.card-room-default {
    cursor: default;
  }

  img {

    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    @include transition(all 0.3s ease);
  }

  .room-text {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {

      font-size: rem(30px);
      font-weight: 700;
      color: $white;
      text-shadow: 2px 2px 5px rgba($default-color, 0.7);
    }
  }

  &:hover {

    img {

      @include transform(scale(1.1));
    }
  }

  @include media-breakpoint-down(sm) {

    .room-text {

      span {

        font-size: rem(14px);
      }
    }
  }
}

.destination-madventure {

  display: flex;
  margin: 30px 0;

  .des-madventure-logo {

    flex-shrink: 0;
  }

  .des-madventure-content {

    flex-grow: 1;
    margin-left: 30px;
  }

  @include media-breakpoint-down(sm) {

    flex-direction: column;

    .des-madventure-logo {

      text-align: center;
    }

    .des-madventure-content {

      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.dest-card {

  position: relative;
  overflow: hidden;
  border-radius: 5px;
  height: 379px;

  .dest-card-content {

    display: flex;
    flex-direction: column;
    font-size: rem(15px);
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include linear-gradient(to top, $black -4%, transparent 15%);
    color: $white;

    .btn {

      font-size: rem(40px);
      font-weight: 900;
      padding-left: 30px;
      padding-right: 30px;
      color: $white;
      text-shadow: 3px 3px 10px rgba($default-color, 0.7);
      border: 1px solid $white;
      border-radius: 50px;

      &:hover {

        background: rgba($white, 0.3)
      }
    }

    span {

      padding: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {

    height: 280px;

    .dest-card,
    .img-objectfit {

      min-height: 280px;
    }

    .dest-card-content {

      .btn {

        font-size: rem(23px);
      }
    }

  }

  @include media-breakpoint-down(sm) {

    height: 230px;

    .dest-card,
    .img-objectfit {

      min-height: 230px;
    }

  }

}

.destination-community {

  h3 {

    font-family: $font-family-base;
    font-size: rem(40px);
    font-weight: 900;
    margin-bottom: 20px;
  }

  .col-rounded {

    display: flex;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background: $gray;

    .col-graphics {

      min-width: 60%;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    .col-content-text {
      padding: 20px;

      h4 {

        font-size: rem(20px);
        font-weight: 900;
        margin-bottom: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    h3 {

      text-align: center;
    }

    .col-rounded {

      flex-direction: column;

      .col-graphics {

        min-width: 100%;
      }
    }

  }
}

.destination-sheet {

  h2 {

    text-align: center;
  }

  h3 {

    font-size: rem(50px);
    text-align: center;
  }

  .tab-pane {

    svg {

      padding: 0 60px;
      width: 100%;
      height: auto;
    }
  }

  .article-btn {

    a {
      
      font-size: rem(45px);
      font-family: 'moontime', 'sans-serif';
      color: $default-color;
      
      &::after {
  
        display: inline-block;
        vertical-align: middle;
        font-family: FontAwesome;
        font-weight: 400;
        content: "\f054";
        display: inline-block !important;
        margin-left: 15px;
        font-size: 20px;
        margin-top: -8px;
      }

      @include media-breakpoint-down(md) {
        
        font-size: rem(30px);

        &::after {
          
          font-size: 16px;
        }
      }
    }

  }

  .swiper-holder-3 {
    
    border: none;

    .swiper {

      padding-right: 0;

      .swiper-slide {

        max-width: 290px;

        &:first-child {
  
          margin-left: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {

    h3 {

      font-size: rem(40px);
    }

    .tab-pane {

      svg {

        padding: 0 0;
      }
    }
  }

  @include media-breakpoint-down(md) {

    h3 {

      font-size: rem(30px);
      line-height: normal;
    }
  }

  .nav-pills {

    border-bottom: 30px solid $yellow;

    .nav-item {

      width: 15.3%;
      text-align: center;
      margin-right: 5px;

      &:last-child {

        margin-right: 0;
      }

      .nav-link {

        display: flex;
        font-size: rem(14px);
        font-weight: 500;
        align-items: center;
        justify-content: center;
        border-radius: 15px 15px 0 0;
        background: $gray;
        color: $default-color;
        height: 100%;
        text-align: center;

        &.active {

          background: $yellow;
        }
      }
    }

    @include media-breakpoint-down(lg) {

      .nav-item {

        width: calc(16.65% - 4px);
      }
    }

    @include media-breakpoint-down(md) {

      border-bottom: none;

      .nav-item {

        width: 100%;
        margin-bottom: 3px;
        margin-right: 0;

        .nav-link {

          border-radius: 5px;
          text-align: left;
          justify-content: flex-start;
        }
      }
    }
  }

  .acf-map {

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    min-height: 200px;

    >div,
    >div>.gm-style {

      border-radius: 20px;
      overflow: hidden;
    }
  }
}

.order-negative {

  order: -1;
}

.section-video-playlist {

  position: relative;
  z-index: 2;

  .container-md {

    position: relative;
  }

  .video-playlist-title {

    position: absolute;
    top: 0;
    right: 15px;
    width: calc(35% - 11px);
    display: flex;
    background: $gray-900;
    z-index: 2;

    h4 {

      font-size: rem(14px);
      padding: 17px 15px;
      background: $gray-900;
      color: $white;
      border-bottom: 1px solid rgba($light-black, 0.5);
      margin-bottom: 0;
      width: 100%;

      span {

        display: block;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    @include media-breakpoint-down(sm) {

      position: relative;
      width: 100%;
      right: 0;

      h4 {

        width: 100%;
        margin-top: 0;
      }
    }
  }

  .section-video-playlist-inner {

    background: $gray-900;
  }

  .ug-gallery-wrapper {

    .ug-strip-panel {

      width: 35% !important;
      top: 51px !important;
      height: calc(100% - 51px) !important;

      .ug-thumbs-strip-inner {

        top: 0 !important;
      }

      .ug-thumb-wrapper {

        min-height: auto !important;
        height: auto;
        position: relative !important;
        top: 0 !important;
      }

      .ug-thumbs-strip,
      .ug-thumbs-strip-inner,
      .ug-thumb-wrapper {

        width: 100% !important;

        .ug-thumb-inner {

          display: flex;
          padding-top: 10px;
          padding-bottom: 10px;

          .ug-thumb-icon {

            flex-shrink: 0;
          }

          .ug-thumb-title,
          .ug-thumb-desc {

            width: 100% !important;
          }

          .ug-thumb-desc {

            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .ug-thumb-title {

            font-size: rem(13px);
            width: 100%;

            span {

              display: block;
              width: 100%;
            }
          }
        }
      }

      .ug-thumbs-strip {

        height: 100% !important;
        overflow: auto;
      }
    }

    .ug-videoplayer {

      width: 65% !important;
    }

    @include media-breakpoint-down(sm) {

      display: flex;
      flex-direction: column;
      height: 558px !important;

      .ug-strip-panel {

        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        width: 100% !important;
        order: 2;

        .ug-thumb-wrapper {

          min-height: 60px !important;
        }
      }

      .ug-videoplayer {

        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        width: 100% !important;
        order: 1;
      }
    }

    @include media-breakpoint-down(xs) {

      .ug-strip-panel {

        .ug-thumb-wrapper {

          .ug-thumb-title,
          .ug-thumb-desc {

            display: block;
          }

          .ug-thumb-desc {

            line-clamp: 1 !important;
            -webkit-line-clamp: 1 !important;
          }
        }
      }
    }

  }

  .yotu-playlist {

    padding: 0 !important;

    >div {

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      background: $gray-900;

      .yotu-wrapper-player {

        width: 65% !important;
        margin-top: -52px;

        .yotu-player {

          box-shadow: none !important;
        }
      }

      .yotu-mode-list {

        position: relative;
        width: calc(35% - 1px);
        margin-left: 1px;
        height: 100%;
        padding-top: 0;
        background: $gray-900;
        overflow: auto;

        li {

          margin-right: 0 !important;
          padding-bottom: 0 !important;
          margin-bottom: 0;
          border-bottom: 1px solid rgba($light-black, 0.5);

          &.active {

            background-color: $light-black;
          }

          &.hide {

            display: none !important;
          }

          >a {

            display: flex;
            align-items: center;
            padding: 8px 15px;

            &:hover {

              background: $light-black;

              h3 {

                text-decoration: none;
              }
            }

            h3 {

              font-size: rem(11px);
              font-family: $font-family-base;
              line-height: normal !important;
              padding-bottom: 0;
              margin-bottom: 0 !important;
              max-height: 45px !important;
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 3;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              color: $white;
            }

            .yotu-video-thumb-wrp {

              width: 30%;
              margin-right: 10px;
              background: $gray-200;
              font-size: rem(10px);
            }

            .yotu-video-title {

              width: 80%;
            }

            .yotu-video-description {

              display: none;
            }
          }
        }

      }

      .yotu-pagination {

        padding: 10px;
        width: 35%;
        margin-left: auto;
        background: $white;

        @include media-breakpoint-up(md) {

          position: absolute;
          right: 0;
          bottom: -50px;
        }
      }

      @include media-breakpoint-down(sm) {

        flex-direction: column;

        .yotu-wrapper-player,
        .yotu-mode-list,
        .yotu-pagination {

          width: 100% !important;
          margin-top: 0;
          margin-left: 0;
        }

        .yotu-mode-list {

          max-height: 300px !important;
          height: auto !important;

          li {

            >a {

              .yotu-video-thumb-wrp {

                width: 60px;
              }
            }
          }
        }
      }
    }
  }
}

.destination-info {
  
  .rplg {
    padding: 0 !important;

    .rplg-badge2 {
      margin: 0 !important;
      box-shadow: none !important;

      .rplg-badge2-border {
        display: none !important;
      }

      .rplg-badge2-btn {
        z-index: 0 !important;
        padding-left: 0 !important;
        font-family: "Montserrat" !important;

        &:hover {
          background: none !important;
        }

        .rplg-badge2-score>div:nth-child(1) {
          display: none !important;
        }
      }
    }
  }
}

.trustyou-reviews {
  
  padding-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-200;

  img {

    display: block;
  }

  .star-rating {

    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {

      margin-right: 5px;

      &.rate-text {

        font-weight: 500;
        margin-right: 10px;
      }

      &.rate-count {

        font-weight: 700;
      }
    }
  }

  .swiper-holder-2 {

    .trustyou-reviews-slider {
  
      padding-bottom: 10px;
  
      .swiper-pagination {
  
        bottom: -10px !important;
        
        .swiper-pagination-bullet {
          
          margin: 8px !important;
          width: 5px;
          height: 5px;
          box-shadow: none;
          background-color: $gray-250;

          &.swiper-pagination-bullet-active {

            box-shadow: none;
            background: #2A5CAA;
          }
        }
      }
  
    }
  }

}

.trustyou-reviews-slider {

  font-size: rem(14px);
  margin-bottom: 10px !important;

  h3 {

    font-family: $font-family-base;
    font-size: rem(16px);
    font-weight: 700;
  }

  .slick-dots {
    bottom: -10px !important;

    li {
      
      width: 10px;
      height: 10px;
    }
  }
}