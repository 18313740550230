// ==========================================================================
// Woocommerce Styles
// ==========================================================================

.logged-in {
    .woocommerce-billing-fields__field-wrapper {

        #billing_country_field {
            &.form-row-first {
                width: 100%;
            }
        }
    }
}

.woocommerce-account {

    @include media-breakpoint-down(sm) {

        .container {

            max-width: 100%;
        }    
    }

    .woocommerce {
        min-height: inherit;
        text-align: center;
        display: flex;

        .sub-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 70px;
        }

        a {
            cursor: pointer;
        }

        .woocommerce-MyAccount-navigation {

            flex-shrink: 0;
        }

        .woocommerce-MyAccount-content {

            width: 100%;
            margin-left: 90px;

            &:after {

                height: calc(100% - 6em);
            }

            #pills-tab {               
                @include flex-container( $align: center );
                flex: 0 0 100%;
                display: block;
                li {
                    @include flex-container( $align: center );
                    
                    margin-left: 57px;
                    a {
                        flex: 0 0 35%;
                        color: $black;

                        &.active {
                            background: none;
                            color: $black;
                            font-weight: 700; 
                            position: relative;

                            &:before {
                                position: absolute;
                                content: '';
                                height: 2px;
                                bottom: 0;
                                margin: 0;
                                left: 17px;
                                right: 0;
                                width: 140px;
                                background: #000000;
                                transition: 0.5s;
                            }
                        }
                    }
                }    
                
                @include media-breakpoint-down(sm) {

                    li {

                        margin-left: 0;

                        a {

                            flex: 0 0 50%;
                            padding: 5px 0;
                            padding-right: 10px;

                            &.active {
    
                                &:before {
                                    bottom: 0;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .woocommerce-form-row {

                width: 50%;
                max-width: 440px;

                .password-input {

                    width: 100%;
                }
            }

            .password-strength {

                li {

                    margin-right: 8px;

                    img {

                        width: 15px;
                        height: 15px;
                    }
                }
            }

            @include media-breakpoint-down(sm) {

                .page-title {

                    display: none;
                }
            }
        }

        @include breakpoint(medium) {
            
            flex-direction: column;

            .woocommerce-MyAccount-content {

                margin-left: 0;

                .password-strength li {

                    font-size: 12px;
                }
            }

            .my_account_orders {

                td#thumbnail img {

                    margin-right: 10px;
                }

                .product-title {

                    padding-right: 40px;
                }

                .mobile-golder-ticket {

                    top: 21px !important;

                    img {
                        margin-right: 0 !important;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {

            .my_account_orders {

                .header {

                    border-bottom: 1px solid $gray;
                    margin-bottom: 15px;
                }

                thead {

                    display: block !important;
                }

                th {

                    border: none;
                    padding: 5px;

                    &:first-child {

                        display: none;
                    }

                    &:nth-child(2) {
    
                        ~ th {
        
                            display: none;
                        }
                    }
                }
            }

        }

        @include media-breakpoint-down(xs) {

            .woocommerce-MyAccount-content {

                .woocommerce-form-row {

                    width: 100%;
                    max-width: 100%;                
                }
            }

            .woocommerce-MyAccount-navigation {

                .input-select {

                    width: 100%;
                }
            }
        }
    }
}

.woocommerce-lost-password {
    .woocommerce {
        min-height: inherit;
        text-align: center;
        flex-direction: column;

        .heading-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 70px;
        }

        .row {
            margin: 0 auto;
            max-width: 406px;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
        }
    }

    .woocommerce-message {

        position: absolute;
        width: 100%;
        margin-top: -50px;
    }
}

.woocommerce-notices-wrapper {
    position: fixed;
    width: 50%;
    z-index: 10;
}

.woocommerce-account {

    .woocommerce-notices-wrapper {

        position: absolute;
        width: 100%;
        // margin-top: -50px;
    }
}

.woocommerce-error,
.woocommerce-info {
    width: 98.5%;
    margin: 0;
}

.woocommerce-notices-wrapper + .woocommerce-message {
    width: 100%;
    position: absolute;
    top: 4em;
}

.woocommerce-order-received {
    .woocommerce {
        h1 {
            font-size: $font-size-base * 1.875;
        }

        h2 {
            font-size: $font-size-base * 2;
        }

        .order-again {
            display: none;
        }

        @include media-breakpoint-down(md) {

            h2 {

                font-size: rem(25px);
            }
        }

        @include media-breakpoint-down(sm) {

            h2 {

                font-size: rem(22px);
            }
        }
    }
}

.woocommerce {

    ul {
        &.order_details {
            li {
                margin-right: .8em;
                padding-right: .8em;
            }
        }
    }

    #coupon-checkout,
    .coupon-checkout {
        .form-row-first {

            input {
                border: 1px solid $gray;
                border-radius: 3rem;
                padding: 0.45rem;
                font-size: 0.75rem;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .form-row-last {

            button,
            a {
                border: 1px solid $gray-50;
                border-radius: 3rem;
                padding: 0.45rem 0.75rem;
                float: right;
                font-size: 0.75rem;
                text-align: center;
                font-style: italic;
                background: $gray-50;
                color: $black;
                cursor: pointer;

                &.yellow {
                    background: $yellow;
                    border: 1px solid $yellow;
                }
            }
        }
    }
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
    width: 49.5%;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
    background-color: $black;
    color: $white;
    padding: .5em 2.3em;
    border: 2px solid $black;
    text-transform: uppercase;
    border-radius: 30px;

      &:hover {
        background-color: transparent;
        border: 2px solid $black;
        color: $black;
      }

      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.site-content {
    .content {
        &.page {
            .woocommerce {
                .woocommerce-MyAccount-content {
                    h1 {
                        text-align: left;
                        font-size: $h1-font-size * .6;
                        margin-bottom: 30px;

                        @include break-from( medium ) {
                            font-size: $h1-font-size;
                        }
                    }
                }
            }
        }
    }
}

.woocommerce {
    position: relative;
    padding-top: 0;

    @include break-from( medium ) {
        min-height: 1240px;
        padding-top: 0;
    }

    table {
        &.shop_table {
            border: 0;
            font-size: rem(13px);

            tfoot {
                .woocommerce-table__line-item {
                    .reservation {
                        ul {
                            @include break-from( medium ) {
                                @include flex-container( $align: flex-start );
                            }

                            li {
                                @include flex-item();
                                list-style: none;
                                font-size: $font-size-base * 0.875;

                                &:first-child {
                                    flex: 0 0 30%;
                                    font-size: $font-size-base;
                                }

                                a {

                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .quantity .qty {
        display: none;
    }

    .woocommerce-order-details {

        #ticket-info-popup {
            .modal-dialog {
                .modal-header {
                    display: inline-block;
                    padding-top: $spacer * 2;
                    border-bottom: 0;

                    h2 {
                        font-weight: 500;
                        margin-bottom: 0;
                        font-size: $font-size-base * 1.25;

                        @include break-from( medium ) {
                            font-size: $font-size-base * 1.875;
                        }

                        &.manage-booking {
                            font-size: $font-size-base * 1.875;
                        }
                    }

                    .close {
                        position: absolute;
                        border: 0;
                        right: $spacer;
                        top: $spacer;
                    }
                }
                .modal-body {
                    padding: 0 ($spacer * 1.5) ($spacer * 2.5);
                    text-align: center;
                    border: 0;

                    #activate-golden-ticket {
                        h2 {
                            font-size: $font-size-base * 1.25;
                            font-weight: 500;

                            @include break-from( medium ) {
                                font-size: $font-size-base * 1.875;
                            }

                        }
                    }

                    #datepicker {
                        right: -3.5em;
                    }

                    ul {
                        &.ticket-date {
                            li {
                                padding-right: 0;
                                position: relative;

                                &:before {
                                    content: '';
                                    width: 40px;
                                    height: 40px;
                                    background-image: url(../images/Search.png);
                                    background-size: cover;
                                    position: absolute;
                                    right: 22px;
                                    bottom: 3px;
                                }

                                b {
                                    font-weight: 300;
                                    text-transform: initial;
                                }

                                input {
                                    text-align: center;
                                    border: 1px $gray solid;
                                    border-radius: $spacer * .8;
                                    padding-top: $spacer * .4;
                                    padding-bottom: $spacer * .4;
                                    padding-left: $spacer * .7;
                                    margin-top: $spacer * .8;
                                    width: 90%;
                                    text-align: left;
                                    font-style: italic;
                                }
                            }
                        }
                    }

                    p {
                        &#ticket-title {
                            margin-bottom: 0;
                            font-weight: 700;
                        }

                        b {
                            font-weight: 700;
                        }

                        span {
                            &.btn-promo {
                                padding: .3em 0;
                                display: inline-block;
                                width: 90px;
                                margin-bottom: .5em;
                                text-align: center;
                                border: 1px solid $black;
                                margin-right: 5px;
                            }

                            &.btn-promo-cam {
                                padding: .3em 0;
                                display: inline-block;
                                // width: 130px;
                                margin-bottom: .5em;
                                text-align: center;
                                border: 1px solid $black;
                                margin-right: 5px;
                            }
                        }
                    }

                    a.btn {
                        &.btn-third {
                            font-size: 1.25;
                            color: $white;

                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }

        &.order-details-room {
            ul:not(.wc-item-meta){
                padding: 0;

                li {
                    text-transform: uppercase;
                    list-style: none;
                    display: inline-block;
                    padding-right: $spacer * 2;
                }
            }

            ul {
                &.wc-item-meta {
                    padding: 0;
                }
            }
        }
    }

    &.buy-golden-ticket {
        min-height: inherit;
        padding: 0;
        margin: 0;
    }

    div.product {
        .woocommerce-tabs {
            ul.tabs {
                display: none;
            }
        }
    }

    .woocommerce-Tabs-panel {
        h2 {
            text-transform: uppercase;
            font-size: $h2-font-size * .6;
        }
    }

    .related {
        &.products {
            h2 {
                text-transform: uppercase;
                font-size: $h2-font-size * .6;
            }
        }
    }

    ul.products {
        li.product .price {
            color: $black;
        }
    }

    h3:not(.moontime) {
        font-family: inherit;
        font-size: $font-size-base;
    }

    p {
        &.separator {
            position: relative;

            &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            border: 1px solid $gray;
            bottom: -8px;
            right: 0;
            }
        }
    }


    a.button,
    button.button,
    input.button {
        background-color: $black;
        color: $white;
        border-radius: 0;
        padding: .5em 2.3em;
        border: 2px solid $black;
        text-transform: uppercase;
        border-radius: 30px;

            &:hover {
            background-color: transparent;
            border: 2px solid $black;
            color: $black;
            }

            &:focus {
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .woocommerce-checkout-review-order {
        position: static !important;

        td {
            div.quantity {
                display: none;
            }
        }

        #payment {
            @include break-until ( small ) {
                h3:not(.combo-title) {
                float: left;
                }
                .cc-support {                    
                    float: none;
                    clear: both;
                }

                .combo-title {

                    margin-top: 10px;
                }
            }
        }
    }

    form {
        &.woocommerce-form-login,
        &.woocommerce-form-register,
        &.edit-account,
        &.woocommerce-checkout,
        &.lost_reset_password {
            .form-row {
                display: block;

                input.input-text {
                    padding: .8em .6em;
                    border: 1px solid $gray;
                    border-radius: 8px;

                    &.red {
                        color: $red;
                    }

                    @include media-breakpoint-down(sm) {

                        font-size: rem(12px);
                        height: 43px;
                    }
                }

                select.input-select {
                    padding: .8em .6em;
                    border: 1px solid $gray;
                    border-radius: 8px;
                }

                @include media-breakpoint-down(sm) {

                    label,
                    .select2-selection--single {

                        font-size: rem(12px);
                    }
                }
            }
        }

        &.lost_reset_password {
            padding: 40px 20PX;

            .woocommerce-form-row {

                width: 100%;
            }
        }

        &.woocommerce-form-login,
        &.lost_reset_password {
            margin: 0 auto;
            max-width: 406px;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            .woocommerce-form-login__submit {
                float: unset;
                margin: auto;
            }
        }

        @include media-breakpoint-down(sm) {

            font-size: rem(12px);
        }
    }

    form {
        &.edit-account {
            .form-row {
                display: flex;

                input.input-text,
                select.input-select {
                    width: 100%;
                }

                .password-input {
                    width: 35%;

                    @include break-until( small ) {
                        width: 100%;
                    }

                    .woocommerce-password-strength,
                    .woocommerce-password-hint {
                        display: none !important;
                    }
                }
            }

            .form-row-first,
            .form-row-last {
                width: 35%;
                float: left;

                @include break-until( small ) {
                    width: 100%;
                }
            }

            .btn-primary {
                @include break-until( small ) {
                    padding: 0.5em 1.3em;
                }
            }

            @include break-until( small ) {
                fieldset {
                    margin-top: 20px;

                    legend {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #121212;
                    }
                }
            }

            ul {
                &.password-strength {
                    padding: 0;
                    text-align: left;
                    width: 100%;
                    float: right;

                    @include break-until ( small ) {
                        display: none;
                    }

                    li {
                        display: block;
                        color: $gray-150;
                        font-size: $font-size-base * 0.575;
                        padding-bottom: .5em;

                        @include break-from( medium ) {
                            display: inline-block;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    form {
        .woocommerce-address-fields {
            .woocommerce-input-wrapper {
                width: 100%;
            }

            input.input-text {
                padding: .8em .6em;
                border: 1px solid $gray;
            }

            select.input-select {
                padding: .8em .6em;
                border: 1px solid $gray;
            }
        }
    }

    form.checkout_coupon {
        padding: 1em 0 .5em 1em;

        @include break-from( medium ) {
            width: 65%;
        }

        .input-text {
            padding: .8em .6em;
            border: 1px solid $gray;
        }
    }

    .woocommerce-notices-wrapper {
        .woocommerce-message {
            border-top-color: $gray;

            &:before {
                color: $gray;
            }
        }
    }

    .thankyou-order {
        .nav-links {
            display: none;
        }
    }

    .shop_table {

        &.activation-details {
            border-radius: 0;
            border-left: 0;
            border-right: 0;

            td,
            th {
                border-top: 0;
            }
        }

        a {
            &#ticket-item {
                cursor: pointer;
            }
        }

        p {
            input#coupon_code {
                border-radius: 30px;
                height: 34px;
            }
        }

        .cart-coupon {

            #coupon-checkout,
            .coupon-checkout {

                display: flex;
                flex-wrap: wrap;
                width: 100%;
                
                .form-row-first {

                    flex-grow: 1;
                    width: initial;
                }

                .form-row-last {

                    width: initial;
                    flex-shrink: 0;
                }
            }

            .error-mssg-coupon {

                display: flex;
                align-items: baseline;
                color: $red;
                font-size: rem(12px);
                line-height: normal;
                width: 100%;

                i {

                    margin-right: 5px;
                }
            }
        }

        &.thankyou-order {

            table-layout: fixed;

            @include media-breakpoint-down(xs) {

                font-size: rem(12px);
            }

            .nav-links {
                display: none;
            }

            tbody {
                td {
                    h2 {
                        font-size: $font-size-base;
                        margin: 0;
                        padding-left: 0;
                    }
                }
            }

            td {

                padding-top: 13px;
                padding-bottom: 13px;

                @include media-breakpoint-down(sm) {

                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            tfoot {

                table-layout: fixed;

                td {

                    width: 50%;

                    &.product-name {

                        span {

                            word-break: break-word;
                        }

                        a {

                            color: $default-color;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .sm-text {

                            display: block;
                            font-size: 12px;
                            line-height: 1.4;
                        }
                    }
                }
            }
        }

        &.woocommerce-MyAccount-orders {
            // padding-top: $spacer * .6;

            tbody {
                &.list {
                    tr {
                        position: relative;

                        td {
                            background: $white;

                            .collapses-arrow {

                                font-size: rem(17px);
                                padding: 10px;
                                position: absolute;
                                right: 0;
                                top: 7px;
                                @include transform(transition(all 0.5s ease));
                                cursor: pointer;
        
                                &.open {
        
                                    @include transform(rotate(90deg));
                                }
                            }

                            &.thumbnail-product {
                                @include break-until( medium ) {
                                    display: inline-block;
                                }
                            }

                            &.product-title {
      
                                @include media-breakpoint-down(sm) {

                                    display: inline-block;
                                }

                                .mobile-golder-ticket {
                                    position: absolute;
                                    right: 5px;
                                    top: 5px;

                                    &.inactive {
                                        .arrow-left {
                                            display: none;
                                        }
                                        .arrow-down {
                                            float: right;
                                            display: inline-block;
                                        }
                                    }

                                    &.active {

                                        .arrow-left {
                                            float: right;
                                            display: none;
                                        }
                                        .arrow-down {
                                            @include transform(rotate(90deg));
                                        }
                                    }
                                }
                            }

                            &.product-info {

                                &.active {
                                    display: flex;
                                    visibility: visible;
                                    align-items: center;
                                    opacity: 1;
                                    transition: visibility 0s linear 0s, opacity 300ms;
                                    background: none;
                                    padding: 0;
                                    padding-top: 0 !important;
                                    padding-left: 100px;
                                    margin: 0;
                                    box-shadow: none;
                                }

                                @include break-until( medium ) {
                                    display: none;
                                    padding-left: 4em;
                                }

                                @include media-breakpoint-down(sm) {

                                    .ticket-item {

                                        margin-left: 10px;
                                    }
                                }
                            }

                            a.mobile-golder-ticket {
                                @include break-from( medium ) {
                                    display: none;
                                }
                            }

                            small {
                                span {
                                    &.desktop {
                                        display: none;
                                        @include break-from( medium ) {
                                            display: inline-block;
                                        }
                                    }
                                    &.mobile {
                                        display: inline-block;
                                        @include break-from( medium ) {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            @include media-breakpoint-down(md) {

                                font-size: rem(14px);
                            }
        
                            @include media-breakpoint-up(md) {
        
                                .collapses-arrow {
        
                                    display: none;
                                }
                            }
        
                            @include media-breakpoint-down(sm) {
        
                                padding-bottom: 0;
                                padding-top: .3rem;
        
                                &:nth-child(2) {
        
                                    margin-top: -51px;
                                    padding-left: 80px;
                                    padding-right: 35px;
                                    padding-bottom: 0;
                                }
        
                                &:nth-child(3) {
        
                                    padding-left: 80px;
                                    padding-right: 35px;
                                    padding-bottom: 10px;
        
                                    ~ td {
        
                                        background: $light-gray-2;
                                        padding-top: 10px;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                        // margin-left: -15px;
                                        // margin-right: -15px;
                                        margin-top: 0;
                                    }
                                }
        
                                &:nth-child(4) {
        
                                    margin-top: 35px;
                                    padding-top: 20px !important;
                                    box-shadow: inset 0 2px 2px $gray-50;
                                }
        
                                &:last-child {
        
                                    padding-bottom: 20px;
                                    margin-bottom: -15px;
                                    box-shadow: inset 0 -1px 1px $gray-50;
                                }
        
                                &.woocommerce-orders-table__cell-order-status {
        
                                    &:before {
        
                                        content: 'Status:' !important;
                                        font-weight: normal;
                                        padding-right: 5px;
                                    }
                                }
        
                                &.woocommerce-orders-table__cell-order-total {
        
                                    &:before {
        
                                        content: 'Total:' !important;
                                        font-weight: normal;
                                        padding-right: 5px;
                                    }
                                }
        
                                &.collapses {
        
                                    display: none;
        
                                    &.show {
        
                                        display: block !important;
                                    }
                                }
                            }
        
                            @include media-breakpoint-down(xs) {
        
                                font-size: rem(13px);
                            }
                            
                        }

                        &:nth-child(1) {
                            td {
                                padding-top: $spacer * .2;

                                @include break-from( medium ) {
                                    padding-top: $spacer * .7;
                                }
                            }
                        }

                        @include media-breakpoint-down(sm) {

                            margin-bottom: 10px;
                        }
                    }
                }

                td {
                    border-top: 0;
                    padding: 0 0 ($spacer * .5);
                    vertical-align: middle;
                    padding-left: 10px;
                    padding-right: 10px;
                    line-height: normal;

                    img {
                        width: 50px;
                        // padding-right: .2em;
                    }

                    h2 {
                        font-size: $font-size-base;
                        margin: 0;
                        padding-left: 0;
                    }

                    @include break-from( medium ) {

                        &:nth-child(1) {
                            // width: 10%;
                            width: 100px;
                        }

                        &:nth-child(2) {
                            width: 35%;
                        }

                        &:nth-child(3) {
                            width: 30%;
                        }

                        &:nth-child(4) {
                            width: 25%;
                        }

                        &:first-child {
                            padding-right: 16px;
                        }
                    }

                    @include break-until( medium ) {

                        &:nth-child(1) {
                            width: 70px;
                        }
                    }

                }
            }
        }

        &.account-section {
            border: 0;
            margin-bottom: $spacer * .5;

            thead {
                th {
                    padding: 0;
                    text-transform: uppercase;

                    @include break-from( medium ) {
                        &:nth-child(1) {
                            width: 10%;
                        }

                        &:nth-child(2) {
                            width: 35%;
                        }

                        &:nth-child(3) {
                            width: 55%;
                        }
                    }

                    a {
                        color: $black;
                        font-weight: 500;
                    }

                    &.underline {
                        span {
                            position: relative;

                            a {
                                font-weight: 700;
                            }

                            &:after {
                                position: absolute;
                                content: '';
                                height: 1px;
                                margin: 0 auto;
                                left: 0;
                                right: 0;
                                width: 100%;
                                background: $black;
                                bottom: -10px;

                                @include break-from( medium ) {
                                    bottom: -8px;
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                td {
                    border-top: 0;
                    padding: 0 0 ($spacer * .5);
                    vertical-align: top;

                    &:first-child {
                        @include break-from( medium ) {
                            padding-right: $spacer;
                        }
                    }

                    img {
                        width: 90px;
                        padding-right: .2em;
                    }

                    h2 {
                        font-size: $font-size-base;
                        margin: 0;
                        padding-left: 0;
                    }
                }
            }

            tfoot {
                th,
                td {
                    vertical-align: top;
                    border-top: 0;
                    padding: 0 0 ($spacer * .5);
                }

                tr {
                    &.payment_method {
                        display: none;
                    }

                    &.regular {
                        th {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        &.activation-details {
            border-bottom: 0;
            border-top: 0;
            margin-bottom: $spacer * .5;

            thead {
                th {
                    padding: ($spacer * 1.3) 0 ($spacer * .5);
                    border-top: 1px $gray-150 solid;
                    font-weight: 500;

                    &.no-border {
                        border-top: 1px $gray-150 solid;
                    }
                }
            }

            tbody {
                th {
                    border-top: 1px $gray-150 solid;
                    padding: 0 0 ($spacer * .5);
                    vertical-align: top;
                    font-weight: 400;

                    &:last-child {
                        padding: 0;
                    }

                    &.no-border {
                        border-top: 1px $gray-150 solid;
                    }

                    p {
                        margin-bottom: 0;
                        padding: 0 0 ($spacer * .5);
                        font-weight: 400;


                        &.separator {
                            &:after {
                                display: none;
                            }

                            &:last-child {
                                padding: 0 0 ($spacer * .5);
                            }
                        }

                        &:last-child {
                            padding: 0 0 ($spacer * 1.2);
                            border-bottom: 1px $gray-150 solid;
                            font-size: $font-size-base * 0.875;
                            color: $gray-150;
                            font-style: italic;
                        }
                    }
                }
            }
        }

        &.woocommerce-checkout-review-order-table {
            border-spacing: 0;
            border: 0;
            overflow: hidden;
            background: $white;
            box-shadow: 0 0 12px rgba($black, 0.3);
            margin-bottom: $spacer;

            @include break-from( medium ) {
                border-radius: 3em;
                // border-bottom-right-radius: 3em;
                // border-bottom-right-radius: 3em;
                // box-shadow: 0 0 0 0;
                background: $white;
                position: absolute;
                top: 7.7em;
                right: 0;
                width: 25%;
            }

            @include break-until(medium) {

                margin-top: -60px;

                &.active {

                    thead {
    
                        background: $light-gray !important;
                        color: $gray-250 !important;                        
                    }
                }
            }

            &.golden-ticket {
                tbody {
                    tr {
                        &:first-of-type {
                            td {
                                border-top: 0;
                            }
                        }
                    }
                }
            }

            thead {
                position: relative;
                background: $light-gray;
                padding: $spacer * .6;
                display: table;
                width: 100%;
                border-top-right-radius: 3em;
                border-top-left-radius: 3em;

                th {
                    span.normal {
                        text-align: left;
                        font-style: inherit;
                        color: $blue;
                        font-size: 0.75rem;
                        display: block;
                        font-weight: 400;
                    }
                }

                @include break-until(medium) {

                    background: $white;
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;
                }
            }

            tbody {
                background: $white;
                padding: 0 15px;
                display: table;
                width: 100%;
                border-spacing: 1px;
                display: none;

                @include break-from( medium ) {
                    display: block;
                }

                @include break-from(medium) {

                    padding-left: 15px;
                    padding-right: 15px;
                }

                .font-slim {

                    span {

                        font-weight: normal !important;
                    }
                }
                

                tr {

                    &:first-child {

                        td {

                            border: none;
                        }
                    }
                    &.cart-subtotal {
                        display: none;
                    }

                    &.order-total:not(.policy-terms) {
                        text-transform: uppercase;

                        th {
                            text-align: right;
                            font-weight: 600;
                        }
                    }

                    &.order-total {
                        &.policy {
                            display: none;
                            text-transform: inherit;

                            @include break-from( medium ) {
                                display: block;
                            }

                            .policy-terms {
                                font-size: rem(9px);
                                margin-bottom: 0;
                                font-weight: 400;
                                line-height: normal;

                                a {
                                    color: $black;
                                    text-decoration: underline;
                                }
                            }
                        }

                        th {

                            padding-left: 0 !important;
                        }

                        td {

                            padding-right: 0 !important;
                        }
                    }
                }

                td {
                    padding: 10px 0;
                    width: 100%;

                    span {
                        &.date {
                            display: inline-block;
                            font-size: rem(13px);
                            font-style: inherit;
                            float: right;

                            &.float-left {

                                font-weight: 700;
                            }
                        }
                    }

                    &.title {
                        text-align: left;

                        @include break-from( medium ) {
                            text-align: center;
                        }
                    }

                    &.product-name {

                        position: relative;
                        a.remove {
                            display: inline-block !important;
                            vertical-align: inherit;
                            position: absolute;
                            top: 52%;
                            left: -5px;
                            @include transform(translateY(-50%));
                        }
                    }

                    &.add-ons {
                    
                        padding-top: 19px;
                    }

                }

                th {
                    font-weight: 400;
                    text-align: left !important;

                    .amount {

                        font-weight: 700 !important;
                    }
                }

                .add-ons-spacing {
                        
                    td {

                        padding: 5px 0;
                    }

                    &:first-child {

                        display: none;

                        + tr {

                            .add-ons {
    
                                border: none;
                            }
                        }
                    }
                }

            }

            tfoot {
                position: relative;
                background: $yellow;
                border-bottom-right-radius: 3em;
                border-bottom-left-radius: 3em;
                display: none;

                &:hover {

                    background: darken($yellow, 5%);
                }

                &.disabled {

                    background: $gray-50;

                    a {

                        pointer-events: none !important; 
                    }

                    &:hover {

                        background: $gray-50;
                    }
                }

                &.email-error {
                    background: $gray-50;
                }

                @include break-from( medium ) {
                    display: table-row-group;
                }

                td {
                    text-align: left;

                    &.footer-title {
                        text-align: center;
                    }

                    h2 {
                        text-transform: uppercase;
                        font-size: $h5-font-size;
                        padding: 0;
                        margin: 0;

                        a {
                            color: $black;
                            display: block;
                            padding: 27px 30px;
                            margin: -9px -12px;

                            &::after {

                                position: absolute;
                            }
                        }
                    }
                }
            }

            th {
                font-size: rem(13px);

                a.more-order-detais {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 4px 0 rgba(0,0,0,.6)!important;
                    border-radius: 15px;
                    padding: 1px 4px;
                    width: 26px;
                    height: 26px;
                    line-height: 1.8;
                    // display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    @include transform(translateY(-50%));
                    background: $yellow;
                    cursor: pointer;
                    color: $default-color;

                    &.active {
                        background: $yellow;
                        @include transform(translateY(-50%) rotate(180deg));
                    }

                    @include break-from( medium ) {
                        display: none;
                    }
                }

                &.title {
                    text-align: left;

                    h2 {
                        font-size: rem(18px);
                        padding: 0;
                        margin: 0;
                    }

                    p {
                        margin: 0;

                        &.search {
                            font-size: $font-size-base * .7;

                            a {
                                color: $black;
                            }

                            @include break-from( medium ) {
                                position: relative;
                                left: -5em;
                            }
                        }
                    }

                    @include break-from( medium ) {
                        text-align: center;
                    }
                }
            }

            .cart_item {
                
                &#inclusion {
                    .product-name {
                        .d-flex {
                            .remove {
                                display: none !important;
                            }   
                        }
                    }
                } 

                &:first-child {

                    td {

                        padding-top: 14px;
                    }
                }

                .product-name {

                    .cqoc_container {

                        display: inline-flex;

                        .cqoc_product_name {

                            display: inline-block;
                            padding-left: 14px;

                            + small {

                                margin-right: 3px;
                            }
                        }
                    }

                    .d-flex {

                        align-items: center;

                        .remove {

                            margin-top: 1px;
                        }
                    }

                    @include break-from ( medium ) {
                        width: 75%;
                    }
                }

                .product-total {
                    @include break-from ( medium ) {
                        text-align: right;
                    }
                }
            }
        }
    }

    .woocommerce-form-coupon-toggle {
        background: $gray;
        width: 100%;
        @include break-from( medium ) {
            width: 63%;
        }

        .woocommerce-info {
            border-top-color: $yellow;

            &:before {
                color: $black;
            }

            a {
                color: $black;
            }
        }
    }

    .checkout {
        &.woocommerce-checkout {

            font-size: rem(14px);

            @include break-from( medium ) {
                padding-right: 330px;
            }
            position: relative !important;
            width: 100%;

            #customer_details {
                .col-md-12 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &.booking-madmonkey {
                .wc-flexible-cancellation {
                    display: none;
                }
            }

            &.processing {
                .blockUI.blockOverlay {
                    position: fixed !important;
                    top: 0 !important;
                    left: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                    background-color: rgba($white, 0.9) !important;

                    &::before {
                        height: 6em;
                        width: 12em;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        @include break-from( medium ) {
                            background-size: cover;
                        }
                        margin: auto;
                        -webkit-transform: translate(-50%,-50%);
                        -ms-transform: translate(-50%,-50%);
                            transform: translate(-50%,-50%);
                        display: block;
                        -webkit-animation: none;
                        -moz-animation: none;
                        animation: none;
                        background-image:url('../images/booking-confirmation.gif');
                        background-position: center center;
                        background-size: 70%;
                        background-repeat: no-repeat;
                        line-height: 7;
                        text-align: center;
                        font-size: 2em;
                        color: $white;
                        z-index: 10;
                    }
                }
            }

            p {
                &#account_password_field {
                    float: right;
                    width: 49.5%;

                    label {
                        display: none;
                    }
                }
            }

            ul {
                &.password-strength {
                    padding: 0;
                    text-align: left;
                    width: 35%;
                    float: right;

                    @include break-from( medium ) {
                        text-align: right;
                        width: 100%;
                    }

                    li {
                        display: block;
                        padding-left: $spacer * .5;
                        color: $gray-150;
                        font-size: $font-size-base * 0.575;
                        padding-bottom: .5em;

                        @include break-from( medium ) {
                            display: inline-block;
                            font-size: 12px;
                        }
                    }
                }
            }

            #payment {
                padding-top: 0;
                background: $white;

                @include break-from( medium ) {
                    padding-top: $spacer * .8;
                    width: 95%;
                }

                h3 {
                    font-size: $font-size-base * 1.5;

                    @include media-breakpoint-down(sm) {

                        font-size: rem(16px);
                    }
                }

                .cc-support {
                    position: relative;

                    img {
                        right: 0;

                        @include break-from( medium ) {
                            position: absolute;
                            width: inherit;
                            top: -30px;
                        }
                    }
                }

                ul.payment_methods {
                    padding: 0;
                }

                .wc_payment_methods {
                    @include break-until ( small ) {
                        margin-bottom: 40px;
                    }
                }

                #place_order {
                    border-radius: 0px;
                    font-weight: bold;
                    padding: 0.75rem;
                    font-size: 1.25rem;
                    cursor: pointer;
                    width: auto !important;
                    border: 0;
                    display: inline-block;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    margin-bottom: 15px;
                    left: 15px;
                    right: 15px;

                    button {
                        visibility: hidden !important;
                    }

                    &.disabled {

                        background: $gray-50;
                        pointer-events: none !important;  
                        opacity: 1;
                    }

                    @include media-breakpoint-down(md) {

                        border-radius: 5px; 
                        box-shadow: 0 0 6px 1px rgba($black, 0.3);                       
                    }
                }

                .place-order {
                    display: block;
                    visibility: visible;
                    padding: 0;

                    @include break-from( medium ) {
                        display: none;
                    }
                }
            }
        }

        .input-text {

            font-size: rem(14px);
        }

        .select2-container {
            .select2-selection--single {
                height: 43px;
                font-size: rem(14px);
            }
        }

        .select2-container--default {
            .select2-selection--single {
                .select2-selection__rendered {
                    line-height: 42px;
                }

                .select2-selection__arrow {
                    height: 38px;
                }
            }
        }

        #wc_checkout_add_ons {
            display: none;
        }

        .woocommerce-billing-fields {
            h2 {
                &.golden-ticket-title {
                    font-size: $font-size-base * 2.5;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 20px;
                    padding-right: 60px;

                    @include media-breakpoint-down(md) {

                        font-size: rem(24px);                        
                    }

                    @include media-breakpoint-down(sm) {

                        font-size: rem(20px);
                        padding-right: 50px;
                    }
                }

                &.booking-title {
                    font-size: $font-size-base * 1.5;
                    font-weight: 500;

                    @include break-from( medium) {
                        font-size: $font-size-base * 2.2;
                    }

                    @include media-breakpoint-down(sm) {

                        font-size: rem(20px);
                        min-height: 48px;
                        padding-right: 60px;
                    }
                }

                &.booking-sub-title {
                    font-size: $font-size-base * 1.25;
                    font-weight: 400;
                    padding-bottom: .4em;
                    padding-right: 60px;

                    a {
                        font-weight: 600;
                    }

                    span {
                        font-weight: 600;
                    }

                    @include media-breakpoint-down(md) {
                        
                        font-size: rem(16px);
                    }
                }

            }

            .mo-openid-app-icons {
                display: inline-block;
                vertical-align: middle;

                .mo_openid-login-button,
                .login-button {
                    margin-top: 0 !important;
                }

                p {
                    &:first-child {
                        display: none;
                    }
                }
                
                
            }

            #billing_email_field {
                span {
                    font-size: $font-size-base;

                    &.red {
                        color: $red;
                        font-style: italic;
                    }

                    &.blue {
                        color: $blue;

                        .lost-password {
                            font-weight: 600;
                        }
                    }
                }
            }

            h3 {
                font-size: $font-size-base * 1.25;
                font-weight: 400;

                @include media-breakpoint-down(sm) {

                    font-size: rem(16px);
                }

                &#details-info {
                    span {
                        font-size: $font-size-base;

                        &.red {
                            color: $red;
                            font-style: italic;
                        }

                        &.blue {
                            color: $blue;

                            .lost-password {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .guest-checkout {
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
                border-radius: 7px;
                padding: $spacer;
                margin: $spacer 3px;

                @include break-from( medium ) {
                    @include flex-container( $align: flex-start );
                }

                @include media-breakpoint-down(sm) {

                    padding: 15px;
                }

                .details {
                    @include flex-item();

                    h2,
                    p {
                        padding: 0;
                        margin: 0;

                        &.booking-sub-title {
                            img {
                                height: 40px;
                                width: 40px;
                            }
                        }
                    }

                    .details-flex {

                        display: flex;
                        align-items: center;

                        .login-popup {

                            margin-left: 10px;
                            flex-shrink: 0;
                            
                            img {
    
                                position: relative;
                                overflow: hidden;
                                width: 36px;
                                height: 36px;
                                border-radius: 50%;
                                box-shadow: 0 0 4px 1px rgba($black, 0.2);
                            }
                        }

                        .login-button {

                            display: inline-block;
                        }


                        .mo-openid-app-icons {

                            margin: 0;
                            flex-shrink: 0;
                            
                            p {

                                display: none;
                            }

                            svg,
                            i {

                                margin-top: 0 !important;
                                box-shadow: 0 0 4px 1px rgba($black, 0.3);

                                &:hover {

                                    @include transform(scale(1) !important); 
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(sm) {

                        font-size: rem(12px);

                        .details-flex { 
                                                        
                            margin-bottom: 10px;

                            .login-popup {
                                
                                margin-left: 30px;
                                margin-right: -3px;

                                // img {
        
                                //     width: 28px;
                                //     height: 28px;
                                // }

                            }

                            .mo-openid-app-icons {
    
                                svg {
                                    
                                    width: 26px !important;
                                    height: 26px !important;
                                    box-shadow: 0 0 4px 1px rgba($black, 0.3);
                                    margin-top: 0 !important;
                                    @include transform(scale(0.8));
                                    margin-right: -7px;
                                }
                            }

                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {

                .form-row {

                    width: 100% !important;
                    float: none !important;
                    clear: both !important;
                }
            }
        }

        .wc-flexible-cancellation {
            padding: ($spacer * .8) 0;
            position: relative;

            h2 {
                font-size: $font-size-base * 1.5;
                padding-bottom: 0;
            }

            img {
                width: inherit !important;
            }

            .flexible-cancellation {
                border: 1px $light-gray solid;
                border-radius: 5px;
                padding: $spacer * .7;

                @include break-from( medium ) {
                    @include flex-container( $align: center );
                    align-items: stretch;
                    width: 90%;
                }

                .item {
                    background: $light-gray;
                    padding: $spacer;
                    border-radius: 5px;

                    @include break-from( medium ) {
                        flex: 0 0 50%;

                        &:nth-child(1) {
                            border-right: 10px solid $white;
                        }

                        &:nth-child(2) {
                            border-left: 10px solid $white;
                        }
                    }

                    h5 {
                        font-size: $font-size-base;
                        font-weight: 500;
                    }

                    span {
                        &.title {
                            padding-left: 5px;
                        }

                        &.icon-question {
                            position: relative;
                            top: 8px;
                            left: -8px;
                        }
                    }

                    #cancellation-desc {
                        font-size: $font-size-base * 0.75;
                        background: $white;
                        top: 11.5em;
                        z-index: 10;
                        right: 0;
                        width: 100%;
                        padding: 1em;
                        border: 1px solid $light-gray;
                        border-radius: 5px;
                        position: absolute;
                        transition: visibility 0s, opacity 0.5s linear;

                        @include break-from( medium ) {
                            width: 50%;
                        }
                    }

                    &.active {
                        ul {
                            li {
                                &:before {
                                    background-image: url(../images/checkmark.png);
                                }
                            }
                        }
                    }

                    ul {
                        padding: 0;

                        li {
                            padding: ($spacer * .3) ($spacer * .3) ($spacer * .3) $spacer;
                            list-style: none;
                            font-size: $font-size-base * 0.875;
                            position: relative;

                            &:before {
                                content: " ";
                                background-image: url(../images/greycheck-mark.png);
                                width: 21px;
                                height: 21px;
                                position: absolute;
                                top: 5px;
                                left: -3px;
                            }
                        }
                    }
                }
            }
        }
    }

    .booking-breadcrumb {
        padding: 0;
        width: 75%;
        text-align: center;
        margin: .3em auto ($spacer * 3);
        display: none;

        @include break-from( medium ) {
            @include flex-container( $align: center );
            border: 0;

            #details-info {
                span:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-left-color: $dark-gray;
                    border-width: 28px;
                    margin-top: -16px;
                }
            }

            span {
                position: relative;
                float: right;
                padding: 0 18px;

                &:after,
                &:before {
                    left: 142%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;

                }
            }

            span:after {
                border-left-color: $dark-gray;
                border-width: 26px;
                margin-top: -14px;
            }

            span:before {
                border-color: rgba(194, 225, 245, 0);
                border-left-color: $yellow;
                border-width: 28px;
                margin-top: -16px;
            }
        }

        li {
            @include flex-item();
            list-style: none;
            text-align: center;
            padding: .96em;
            background: $dark-gray;

            &.active {
                background: $yellow;

                span {
                    &:before {
                        border-left-color: $yellow;
                    }

                    &:after {
                        border-left-color: $yellow;
                      }
                }
            }

            @include break-from( medium ) {
                &:first-of-type {
                    border-top-left-radius: 2em;
                    border-bottom-left-radius: 2em;
                }

                &:last-of-type {
                    border-top-right-radius: 2em;
                    border-bottom-right-radius: 2em;
                }
            }

            a {
                color: $white;
            }
        }
    }

    .custom-order-thankyou {
        @include break-from( medium ) {
            @include flex-container( $align: flex-start );
        }

        .custom-order-details {
            flex: 0 0 50%;

            .img-objectfit {

                max-height: 350px;
                border-radius: 10px;
            }

            .sub-title {

                font-size: rem(24px);

                img {

                    width: 47px;
                    margin-right: 10px;
                }

                @include media-breakpoint-down(sm) {

                    font-size: rem(20px);
                    
                    img {

                        width: 40px;
                    }
                }
            }

            .mt-45 {

                margin-top: 30px;
            }
        }

        .travel-insurance {
            padding-left: $spacer;
            padding-right: $spacer;
            flex: 0 0 50%;
            max-width: 50%;

            h3 {
                font-size: $font-size-base * .7;

                &.moontime {
                    font-size: $font-size-base * 4;
                    line-height: 60%;
                }
            }

            .safetywing-price-widget {
                display: block !important;
            }

            .welcome-note {
                background: rgba(253, 253, 253, 0.3);
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4);
                border-radius: 10px;
                padding: $spacer;
                padding-bottom: 10px;

                ul {
                    padding: 0;
                    text-align: center;
                    li {
                        display: inline-block;
                        padding: $spacer;
                    }
                }
            }

            @include media-breakpoint-down(sm) {

                padding-left: 0;
                padding-right: 0;
            }

            @include break-until(1053px) {

                max-width: 100%;
            }
        }

        .img-objectfit {
            height: 400px !important;
        }
    }

    // My account
    .woocommerce-MyAccount-navigation {
        width: 100%;
        padding-top: 0;

        @include break-until( medium ) {
            float: inherit;
        }

        @include break-from( medium ) {
            padding-top: 5.5em;
            width: auto;
        }

        ul {
            padding: 0;

            @include break-until( small ) {
                display: none;

                li {
                    flex-shrink: 0;

                    a {
                        font-size: rem(14px);
                        display: block;
                        border: 1px solid $gray-200;
                        border-radius: 10px;
                        padding: 7px 10px;
                    }

                    &.is-active {
                        a {
                            background: $yellow;
                            border-color: $yellow;
                        }
                    }
                }
            }

            li {
                list-style: none;
                text-align: left;
                padding: ($spacer * .1) 0;

                @include break-from( medium ) {
                    padding: ($spacer * .5) 0;
                }

                @include break-from( medium ) {
                    text-align: right;
                }

                &.is-active {
                    a {
                        color: $black;
                        font-weight: 500;
                    }
                }

                a {
                    text-transform: uppercase;
                    color: $gray-150;
                }
                
                &.woocommerce-MyAccount-navigation-link--golden-tickets {
                    a {
                        color: $purple;
                        font-weight: 600;
                    }
                }
            }
        }

        .custom-select-holder {
            margin-top: -40px;
            margin-bottom: 30px;
            position: relative;

            .select2-selection {
                height: 40px;

                .select2-selection__rendered {
                    line-height: 38px;
                    text-align: left;
                }
                .select2-selection__arrow {
                    height: 38px;
                }
            }

            .select2-container--open {
                .select2-results {
                    ul {
                        display: initial;

                        li {
                            padding: 6px !important;
                        }
                    }
                }
            }

        }

        select {
            display: none;

            @include break-until( small ) {
                display: block;
            }

            &.input-select {
                padding: 0.8em 0.6em;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                margin: 0 auto 30px;
            }
        }
    }

    .woocommerce-Addresses {
        header {
            h3 {
                text-transform: uppercase;
                font-weight: 800;
            }
        }
    }

    .woocommerce-MyAccount-content {
        position: relative;
        text-align: left;

        @include break-from ( medium ) {
            width: 80%;
        }

        @include break-until( medium ) {
            float: inherit;
            width: 100%;
        }

        h1 {
            font-size: $h1-font-size;
        }

        #form-row-pic {
            font-size: 12px;
            display: none;
        }

        .profile-pic {

            display: flex;
            align-items: center;
            margin: 0 0 30px;
            text-transform: capitalize;

            &.manage-ticket {
                section {
                    @include break-from( medium ) {
                        flex: 0 0 100%;
                    }
                }
            }

            img {
                object-fit: cover;
                width: 58px;
                height: 58px;
                border-radius: 50%;
                display: inline;

                @include break-from( medium ) {
                    display: inherit;
                }
            }

            h2 {
                font-size: $font-size-base;
                padding-left: $font-size-base;
                font-weight: bold;
                display: inline;
                margin-bottom: 0;

                @include break-from( medium ) {
                    display: inherit;
                }

                span {
                    display: block;
                }
            }

            span {
                padding-left: $font-size-base;
            }

            #change-woo-image {

                font-size: 12px;
            }
        }

        &:after {
            @include break-from( medium ) {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                border: .5px solid #000;
                top: 6em;
                left: -2.8em;
            }
        }

        .woocommerce-notices-wrapper {

            position: relative;
            width: 100%;

            .woocommerce-message {

                border-top-color: #8fae1b;

                &::before {

                    color: #8fae1b;
                }
            }
        }

        @include media-breakpoint-down(sm) {

            .edit-account {

                P {
    
                    &:last-child {
    
                        text-align: center;
                    }
                }
            }
        }
        
    }

    table {
        border-spacing: 0;

        &.my_account_orders {
            border: 0;
            font-size: 1rem;

            th {
                border-bottom: 1px solid $gray;
                padding: 10px 8px !important;
                font-size: 1.2em;
                font-weight: 400;

                span {
                    text-transform: uppercase;
                }
            }

            td {
                border-top: 0;
                padding: 10px 8px;
                line-height: normal;

                p {
                    margin-bottom: 2rem;

                    a {
                        color: $white;
                    }
                }

                a {
                    color: $black;

                    img {
                        width: 50px;
                    }
                }

                &#thumbnail{

                    min-width: 90px;

                    img {
                        border-radius: 10px;
                        object-fit: cover;
                        width: 50px !important;
                        height: 50px;
                    }

                    @include media-breakpoint-down(sm) {

                        min-width: 50px;

                        img {
                            width: 50px !important;
                            height: 50px;
                        }
                    }
                }

                &.product-title {

                    min-width: auto !important;
                    padding-left: 5px !important;
                    margin-top: -5px !important;
                }
            }
        }
    }

}

.woocommerce table.shop_table_responsive tr td::before,
.woocommerce-page table.shop_table_responsive tr td::before {
    content: " " !important;
}

.woocommerce-checkout {
    

    @include media-breakpoint-down(sm) {

        #custom_fc_button {
            right: inherit;
            left: 10px;
        }
    }

    .stretched-link {
        &::after {
            z-index: 2;
        }
    }
}

#rc_accepts_marketing_field {

    display: none;
}

.wc_payment_method.payment_method_offlinecc {

    display: flex;
    margin-bottom: 0;

    .input-radio {

        margin-top: 9px;
    }

    label {

        margin-bottom: 0;
    }
}

.create-account {

    width: 100% !important;
}

.woocommerce-checkout #payment div.payment_box {

    padding: 0;
    background: none;
    
    &::before {

        display: none;
    }

    .wc-stripe-elements-field {

        padding: 11px;
        border-radius: 5px;
    }
}

.woocommerce-lost-password {

    .woocommerce-notices-wrapper {

        position: relative;
        width: 100%;
        margin-bottom: 40px;
    }
}

.woocommerce-checkout-payment {

    ul.wc_payment_methods {

        li {

            &:first-child {

                label {

                    display: none;
                }
            }
        }
    }

    .room-combo {
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
        border-radius: 7px;
        padding: 20px;
        margin: $spacer 3px;        
        
        h2 {
            font-size: $font-size-base * 1.25; 
        }

        hr {
            position: relative;             
        }

        .combo-details {            
            @include break-from( medium ) {
                @include flex-container( $align: flex-start );
            } 

            .detail {
                flex: 0 0 50%;
            }
        }
    }
}

.checkout-right-holder {

    display: flex;
    flex-direction: column;

    .currency-checkout {

        display: inline-block;
        order: 13;
        flex-grow: 0;
        margin-left: auto;

        .select2-container--default .select2-selection--single .select2-selection__rendered {

            line-height: 31px !important;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {

            margin-top: 2px !important;
        }
    }

    .checkout-right-holder {

        position: relative;

        .currency-checkout {

            display: none;
        }
    }

    @include break-from(medium) {
        position: absolute;
        top: 5px;
        right: 0;
        width: 330px;

        .currency-checkout {

            order: -1;
        }


        .shop_table.woocommerce-checkout-review-order-table {

            position: relative;
            top: auto;
            right: auto;
            width: 100% !important;
        }
    }

    @include break-until(1053px) {

        position: relative;

        .currency-checkout {

            margin-top: 20px;
            margin-bottom: -60px;
        }
    }

    @include media-breakpoint-down(sm) {

        .currency-checkout {

            margin-top: 40px;
            margin-bottom: -80px;
        }
    }
}

.change-password {

    h5 {

        font-size: rem(16px);
        font-weight: 700;
    }
}

.form-row {
    
    .checkbox {

        display: flex !important;
        line-height: normal !important;
        // color: $gray-150;
        cursor: pointer;

        .input-checkbox {
            flex-shrink: 0;
            cursor: pointer;
        }

        @include media-breakpoint-down(sm) {

            font-size: rem(12px);
            font-weight: 400;
        }
        
    }
}

.age-check-form {

    display: flex;
    align-items: flex-start;
    padding: 0 3px;

    input {
        cursor: pointer;
        margin-top: 2px;
        margin-right: 8px;
    }

    span {

        color: #ff0000;
        font-weight: 700;
    }

    a {

        color: $default-color;
        text-decoration: underline;
    }

    label {
        cursor: pointer;
        font-weight: 600;
    }

    @include media-breakpoint-down(sm) {

        font-size: rem(12px);
        font-weight: 400;
    }
}

.select2-container {

    font-size: rem(14px);
}

.select2-dropdown {

    z-index: 1010;
}

.payment_methods {

    input {

        font-size: rem(14px !important);
    }
}

.tick-account {

    .create-account {

        font-weight: 600;
        padding-top: 0 !important;

        @include media-breakpoint-down(sm) {

            font-weight: 400;
        }
    }
}

.woocommerce table.shop_table_responsive tbody tr td.empty-table {

    @include media-breakpoint-down(sm) {

        text-align: center !important;
        font-size: rem(13px);
    }
}

.discover-section {

    .discover-cols {

        display: flex;
        margin-left: -20px;

        .discover-col {

            display: flex;
            margin-left: 20px;
            flex-grow: 1;
            flex-basis: 50%;
            align-items: center;

            .discover-col-info {
                
                margin-left: 5px;

                strong,
                span {

                    display: block;
                    line-height: 1.3;
                }

                p {

                    margin-bottom: 0;
                }

            }
        }

        .divider {

            display: flex;
            flex-direction: column;
            font-size: rem(12px);
            font-weight: 700;
            align-items: center;
            margin: 0 10px;

            &::before,
            &::after {

                flex-grow: 1;
                display: block;
                width: 1px;
                height: 100%;
                content: '';
                border-left: 1px solid $default-color;
            }
            
        }

        @include media-breakpoint-down(xs) {

            flex-direction: column;
            margin-left: 0;

            .discover-col {

                margin: 10px 0;
            }

            .divider {

                flex-direction: row;
                margin: 0;

                &::before,
                &::after {

                    flex-grow: 1;
                    display: block;
                    width: 100%;
                    height: 1px;
                    content: '';
                    border-left: 0;
                    border-top: 1px solid $default-color;
                    margin-right: 5px;
                }

                &::after {

                    margin-right: 0;
                    margin-left: 5px;
                }
            }
        }
    }

    .discover-promo {

        input {

            min-width: 120px;
            font-weight: 700;
            border: none;
            background: none;
            appearance: none;
        }

        label {

            margin-bottom: 0;
        }

        small {

            display: block;
            font-size: rem(14px);
            font-style: italic;
            margin-top: 10px;
        }

        .discover-promo-btn {

            border: 1px dashed $default-color;
            padding: 1px 7px;
            border-radius: 5px;
            background: $light-gray;

            &.copied {

                background: $gray-150;
                border: 1px solid $gray-150;
                color: $white;

                &::before {

                    display: inline-block;
                    width: 9px;
                    height: 10px;
                    margin-right: 5px;
                    content: '';
                    background: url('../images/check_green.png') no-repeat;
                    background-size: 100%;
                }
            }
        }

        .alternate-input {

            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        @include media-breakpoint-down(xs) {

            label {

                display: block;
            }

        }
    }

    .discover-buttons {

        display: flex;
        margin-left: -15px;


        .btn-third {

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 700;
            padding: 10px 15px;
            flex-grow: 1;
            flex-basis: 50%;
            max-width: 50%;
            margin-left: 15px;
            box-shadow: 0 0 7px 0 rgba($black, 0.2);

            &:hover {

                background: darken($yellow, 10%);
            }
        }

        @include media-breakpoint-down(xs) {

            flex-direction: column;

            .btn-third {

                margin-bottom: 20px;
                max-width: 100%;
            }

        }
    }
}

.trending {

    .trending-title {

        display: flex;
        align-items: center;

        h2 {

            flex-shrink: 0;
            margin-right: 20px;
        }

        .swiper-pagination {

            bottom: -30px;

            .swiper-pagination-bullet {

                background: $dark-gray;
                opacity: 1;

                &.swiper-pagination-bullet-active {

                    background: $green-55;
                }
            }


            @include media-breakpoint-up(md) {

                position: relative;
                text-align: left;
                bottom: 0;
                margin-bottom: 5px;
            }
        }
    }

    .swiper-holder-3 {

        width: calc(50vw - 33px);

        border-top: none;

        @include break-until(1053px) {

            width: 100%;
        }
    }

    .swiper {

        padding-left: 5px;
        padding-right: 5px;

        .swiper-slide {

            max-width: 310px;
            height: auto;

            &:first-child {

                margin-left: 0;
            }
        }

        .swiper-button-disabled {

            opacity: 0;
        }

        @include media-breakpoint-down(md) {

            max-width: auto;
        }
    }
}

.cross-promo-holder {
    
    .cross-promo {

        position: relative;
        background: $light-gray-3;
        padding: 20px;
        
        h2 {

            margin-bottom: 20px;
        }
        
        .cross-promo-img {

            position: relative;
            border-radius: 10px;
            overflow: hidden;

            img {
    
                border-radius: 10px;
            }
        }

        .cross-promo-detail {

            margin-top: 15px;

            ul {

                display: flex;
                flex-wrap: wrap;
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;

                li {

                    font-weight: 700;
                    flex-grow: 1;
                    flex-basis: 50%;
                    max-width: 50%;
                    padding: 5px;
                }
            }

            @include media-breakpoint-down(sm) {

                li {

                    font-size: rem(14px);
                }
            }
        }
    }
}

#billing_country_field,
#billing_email_field {

    width: 49.5%;
    float: left;
    clear: none;
}

#billing_phone_field,
#guest_email_field {

    width: 49.5%;
    float: right;
    clear: none;
}

#guest_email_field {
    .input-text {
        margin-bottom: 0.5rem;
    }
}

#add-guest-email {
    cursor: pointer;
}

#billing_email_field {

    clear: both;
}

.form-row {

    .select2-selection--single {

        border: 1px solid #e3e3e3;
        border-radius: 8px;
    }
}

#customer_details {

    .woocommerce-billing-fields__field-wrapper {

        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;

        .form-row {

            float: none;
            clear: none;
            width: calc(50% - 10px);
            margin-left: 10px;

            &.datepicker {

                order: 11;
            }

            &#guest_email_field {

                order: 12;
            }

            &#kl_newsletter_checkbox_field {

                width: 100%;
                order: 13;
            }

            @include media-breakpoint-down(sm) {

                &.datepicker {

                    order: 12;
                }
    
                &#guest_email_field {
    
                    order: 11;

                    p {

                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.woocommerce-orders-table__row {

    .woocommerce-orders-table__cell-order-number {
        &:first-child {
            a {
                display: block;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                background: $gray-200;
                position: relative;
                z-index: 1;
            }
        }
    }
}

.woocommerce {
    .swiper-button-icon {
        img {
            width: 50px !important;
            max-width: 50px !important;
            height: 50px !important;
        }
    }
}