
.section-long-passes {
  h2 {
    font-size: rem(48px);

    span,
    em {
      font-style: normal;
      font-weight: normal;
    }

    &.price {
      span {
        color: $default-color;
      }
    }
  }

  .section-highlights {
    background: $gray-100;
    border-radius: 24px;
    padding: 50px;

    h2 {
      font-size: rem(32px);
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &.gradient-background {
    background: rgb(143,110,158);
    background: radial-gradient(circle, rgba(143,110,158,1) 0%, rgba(71,102,130,1) 0%, rgba(143,110,158,1) 67%);
    background-size: 130% 150%;
    background-position: 100% 0;

    .madpass-title {
      color: $white;
    }
  }

  &.activate-golden-ticket {
    h4 {
      font-size: rem(35px);
      font-weight: normal !important;
    }

    h5 {
      font-weight: normal !important;
      line-height: 1.4;
    }
  }

  &.section-background {
    display: flex;
    align-items: center;
    min-height: 580px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: $white;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      @include linear-gradient(to right, rgba($black, 0.9) 0, transparent 70%);
    }

    .container-lg {
      position: relative;
      z-index: 2;
    }

    @include media-breakpoint-down(md) {
      min-height: 300px;

      &::after {
        @include linear-gradient(to right, rgba($black, 0.9) 0, transparent 100%);
      }
    }

    @include media-breakpoint-down(sm) {
      h2 {
        font-size: rem(25px);
      }
    }
  }

  @include media-breakpoint-down(sm) {

    &.hero-section {

      padding-top: 0;
    }

    h2 {

      font-size: rem(30px);
    }

    .section-highlights {
      padding: 20px;
    }

    &.activate-golden-ticket {
      h4 {
        font-size: rem(24px);
      }
  
      h5 {
        font-size: rem(18px);
        line-height: normal;
      }
    }
  }
}

.graphics-banner {
  position: relative;
  min-height: 350px;
  overflow: hidden;

  .img-objectfit {
    min-height: 350px;
  }
  
  @include media-breakpoint-down(sm) {

    margin-left: -15px;
    margin-right: -15px;

  }
}

.long-passes-banner {

  h1 {
    font-size: rem(48px);

    span {

      // color: $blue-500;
      font-size: rem(40px);
      font-weight: normal;
    }
  }

  h4 {

    font-size: rem(20px);
    font-weight: 400;
    margin-top: 30px;
    color: $default-color;
  }

  @include media-breakpoint-down(sm) {
    h1 {
      font-size: rem(33px);

      span {

        font-size: rem(28px);
      }
    }

    h4 {
      font-size: rem(18px);
    }
  }
}

.grid-cards {

  .grid-card-item {
    padding: 20px;
    height: 100%;
    border: 1px solid $gray-200;
    border-radius: 24px;

    img {

      width: auto;
      max-height: 26px;
    }
  
    h2 {
      font-size: rem(24px);
      font-weight: 500;
      color: $default-color;
    }
    
    p {

      margin-bottom: 0;
    }

    &.mmxp-grid {

      strong {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        border-radius: 50%;
        border: 1px solid $purple;
        color: $purple;
      }

      h4 {
        font-weight: 700;
        color: $default-color;
      }
    }

    @include media-breakpoint-down(sm) {  
      h2 {
        font-size: rem(18px);
      }
    }
  }

}

.long-passes-currency {

  margin-left: auto;
  margin-right: auto;
  width: 190px;

  .currency-exchange {
    &.is-dark {
      .select2-dropdown {
        background-color: $white !important;
        color: $default-color !important;
      }

      .select2-container.select2-container--default.select2-container--open:not(.select2-container--below) .select2-dropdown--below ul li.select2-results__option--highlighted.select2-results__option--selectable,
      .select2-container.select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: $purple-600 !important;
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .currency-exchange {

      .select2-container--default {

        background: transparent;

        .select2-selection--single {
          .select2-selection__rendered {
            color: $white !important;

            .img-flag {
              margin-right: 5px;              
            }

            .select2-selection__placeholder {
              width: 150px;
              font-size: rem(14px);
              line-height: 1.6;
              color: $white;
            }
          }
        }
      }

    }
  }

  @include media-breakpoint-down(md) {

    .currency-exchange {
      .select2-container--default {

        padding-left: 0 !important;
        border: 1px solid $white !important;
        background: transparent;

        &.select2-container--open {
          &:not(.select2-container--below) {
            .select2-dropdown--below ul li {
              font-size: rem(14px !important);
            }
          }
        }
        .select2-selection--single {

          padding-right: 25px;
          padding-top: 4px;
          
          .select2-selection__rendered {
            .img-flag {
              @include transform(translateX(0) !important);
              margin-left: 10px;
              margin-right: 5px;
              + span {
                display: inline-block;
              }
            }
            .select2-selection__placeholder {

              font-size: rem(14px);
              color: $default-color;
              width: 160px;
              line-height: 1.6;
              padding-left: 30px !important;

              &::after {
                left: 8px;
                width: 22px;
                height: 22px;
                top: 3px;
              }
            }
          }
        }
      }

      .currency {
        +.select2-container--default:after {
          display: block !important;
        }
      }
    }

  }
}

.madpass-title {

  h2 {
    margin-bottom: 20px;

    em {
      font-style: normal;
      color: $blue-500;
    }
  }

  p {
    font-size: rem(24px);
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;

    em {
      font-weight: 500;
    }
  }

  @include media-breakpoint-down(sm) {
    p {
      font-size: rem(18px);
    }
  }
}

.graphic-rounded {

  position: relative;
  border-radius: rem(24px);
  overflow: hidden
}

.card {

  &.opacity {
    background: rgba($white, 0.8) !important;
  }

  &.card-lg-rounded {

    background: $gray-100;
    border: none;
    border-radius: rem(32px);

    .graphic-rounded {
      padding-bottom: 70%;

      .img-objectfit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .card-body {
  
      font-size: rem(16px);
      align-items: flex-start;
  
      p {
        overflow: visible;
        display: block;
        line-clamp: initial;
        -webkit-line-clamp: initial;
        margin-bottom: 10px;
      }

      h2 {
        font-size: rem(36px);
        color: $blue-500;
        font-weight: 700;

        span {
          font-weight: 400;
        }
      }
    }

    .btn {
      color: $blue-500;
    }

    @include media-breakpoint-down(sm) {
      &.card-lg-rounded {
        padding: 20px;

        .card-body {
          h2 {
  
            font-size: rem(20px);
          }
        }
      }
    }
  }

  &.card-outline {

    justify-content: center;
    
    .stretched-link {

      &::after {
        @include transition(all 0.3s ease);
      }

      &:hover {
        &::after {
          background-color: rgba($purple-200, 0.2);
        }
      }
    }
    
    a {
      font-size: rem(20px);
      color: $default-color;
      font-family: $font-family-base;
      font-weight: 500;
    }
  }

  .card-content-collapse {

    &.show {
      overflow: visible !important;
      line-clamp: initial !important;
      -webkit-line-clamp: initial !important;
      height: auto !important;
    }

    &.sm {

      @include media-breakpoint-down(sm) {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        height: 96px;
      }
    }

    p {

      &:last-child {

        margin-bottom: 0;
      }
    }
  }
}

.embed-background {
  padding: 20px;
  border-radius: 24px;
  border: 1px solid $gray-200;
  background: $white;

  .embed-responsive {
    border-radius: 14px;
    overflow: hidden;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.mobile-madpass {

  padding: 20px 15px;
  border-radius: 16px;
  background: rgba($white, 0.8);

  .nav-tab {
    ul {
      padding-left: 0;
    }
    li {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid $gray-200;
      border-radius: rem(10px);

      &.active {
        border-color: $purple-300;
      }

      label {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-bottom: 0;
        margin-left: 10px;
        flex-grow: 1;
        

        span {
          font-size: rem(14px);
          margin-left: auto;
          font-weight: 400;
          color: $default-color;
          height: 21px;

          .woocs_preloader_ajax {
            &::after {
              margin: 0;
            }
          }
        }
      }

      input {
        flex-shrink: 0;
      }
    }
  }

  .mobile-madpass-inner {

    display: flex;
    align-items: center;
    padding: 20px 15px;
    text-align: center;
    border-radius: rem(10px);
    background: rgba($purple-200, 0.2);
    min-height: 100px;

    .mobile-madpass-left {
      p {
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .mobile-madpass-right {
      margin-left: auto;
      padding-left: 20px;
    }

    h2 {
      font-size: rem(20px);
      color: $default-color;
      margin: 10px 0;
      span {
        color: $default-color;
      }
    }
  }

}

.tab-radio-content {

  &:not(.show) {

    display: none;
  }
}

.widget-embed {
  position: relative;
  min-height: 100px;
  border: 1px solid $gray-200;
  border-radius: 10px;
	padding: 8px;
	padding-right: 4px;

  h3 {
    font-family: $font-family-base;
    color: $default-color;
  }

  @include media-breakpoint-down(sm) {
    padding: 5px 5px;
  }
  
}

.madpass-page {
  padding-bottom: 0 !important;
}

.modal.terms {
  ul {
    padding-left: 20px;

    li {
      margin-bottom: 15px;
    }
  }
}
