// ==========================================================================
// Partners Styles
// ==========================================================================
.site-content {
    .partners-page {

        &.content {
            padding-top: 0;
        }

        .full-banner {
            padding-top: $spacer * 4.5;
            padding-bottom: $spacer * 4;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include break-until ( small ) {
                padding-top: $spacer * 9.5;
                padding-bottom: 0;
                background-position: 83% 0;
            }

            h2 {
                padding-bottom: 0;
                color: $white;

                @include break-until ( small ) {
                    margin-bottom: 80px;
                }
            }

            .container {
                @include break-until ( small ) {
                    background-image: linear-gradient(1turn,#000 64%,transparent 76%)
                }
            }

            .description {

                &.sm {

                    @include break-from( medium ) {
                        width: 47%;
                    }
                }

                @include break-from( medium ) {
                    width: 53%;
                }

                @include break-until ( small ) {
                    display: flex;
                    flex-flow: column;
                    text-align: center;

                    p {
                        order: 2;
                    }

                    a {
                        order: 1;
                        margin: 0 auto 15px;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }

            p {
                padding-bottom: 0;
                color: $white;
            }
            a {
                &.et_pb_button {
                    text-decoration: none;
                    margin: 0;
                    border-radius: 20px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .slogan {
            background: #f8f8f8;
            padding-top: $spacer * 2.5;
            padding-bottom: $spacer  * 2.5;

            h2 {
                font-family: 'MoonTime',Helvetica,Arial,Lucida,sans-serif;
                font-size: 45px;
                font-weight: normal;
                padding: 0;
                margin: 0;
                line-height: 1.1;
            }
        }

        .price-structure {

            .price-table {
                text-align: center;

                .card {
                    background: $white;
                    padding: 30px;                                       
                    border-radius: 24px;
                    box-shadow: none;
                    border: 1px solid $gray-200;

                    h4 {
                        font-size: rem(28px);
                        text-transform: uppercase;
                        font-weight: 700;
                        padding-bottom: 20px;
                        margin: 0;
                        color: $default-color;
                    }

                    .price-content {    

                        h2 {
                            font-size: rem(35px);
                            font-weight: normal;
                            background: transparent;
                            color: $black;
                            margin-bottom: 20px;

                            span {
                                &.per-tickets {
                                    display: block;
                                    text-align: center;
                                    font-size: rem(20px);
                                    font-family: 'Montserrat';
                                    font-weight: normal;
                                    padding-top: 20px;
                                    padding-bottom: $spacer * .2;
                                }
                            }
                        }

                        p {

                            margin-bottom: 0;
                        }

                        ul {
                            padding: ($spacer * 2) 0 0;
                            list-style: none;

                            li {
                                position: relative;

                                &:before {
                                    content: "\2022";
                                    color: $primary;
                                    font-weight: bold;
                                    position: absolute;
                                    left: -.5em;
                                    font-size: 36px;
                                    top: -15px;
                                }

                                &:nth-child(n+3) {
                                    display:none;
                                }

                                p {
                                    text-align: left;
                                }
                            }
                        }

                        span {
                            &#read-more {
                                cursor: pointer;
                                color: $yellow;
                                text-transform: uppercase;
                                font-family: 'Montserrat';
                                font-weight: 700;
                            }
                        }

                        p {
                            &:nth-child(4) {
                                &::before {
                                    content: "";
                                    display: inline-block;
                                    background: url('../images/ellipse.png') no-repeat;
                                    background-size: contain;
                                    width: 8px;
                                    height: 8px;
                                    margin-right: 5px;
                                }
                            }
                        }

                        .price-content-info {

                            max-width: 600px;
                            margin-left: auto;
                            margin-right: auto;
                            color: $gray-300;
                        }
                    }
                    
                    @include media-breakpoint-down(sm) {

                        padding: 20px;

                        h4 {
                            
                            font-size: rem(24px);
                        }

                        .price-content {

                            h2 {

                                font-size: rem(40px);
                            }
                        }

                    }
                }
            }

            .fs-50 {

                line-height: 0.8;

                @include media-breakpoint-down(lg) {

                    &.montserrat-extrabold {

                        display: block;
                    }
                }

                @include media-breakpoint-down(sm) {

                    font-size: rem(37px) !important;
                }

                @include media-breakpoint-down(xs) {

                    font-size: rem(30px) !important;
                }
            }

            .fs-20 {

                @include media-breakpoint-down(sm) {

                    font-size: rem(17px) !important;
                }
            }

            .currency-exchange {

                display: inline-block;
            }
        }

        .about-golden-ticket {
            padding-top: $spacer * 3;

            .about {
                color: $white;

                .description {

                    color: $default-color;

                    h2 {
                        font-size: $font-size-base * 2;
                        color: $primary;
                    }

                }

                .notice-article {
                    margin-top: $spacer * 2;
                    padding: $spacer;
                    background: $black;

                    h2 {
                        font-size: $font-size-base * 2;
                        color: $primary;
                    }

                    .link-notice {
                        text-align: left;

                        @include break-from( medium ) {
                            text-align: right;
                        }

                        a {
                            color: $primary;
                        }
                    }
                }
            }

            .image {
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .link-golden-ticket {
            .icon {
                margin-bottom: 10px;
                img {
                    height: 26px;
                    width: auto;
                }
            }

            .modal-lg {
                max-width: 700px;
                max-height: 600px;
            }

            .modal-content {
                border-radius: 20px;

                @include media-breakpoint-up(lg) {
                    max-height: calc(100% - 100px);
                }

                .modal-header {
                    padding: 0;
                    position: relative;

                    h2 {
                        font-size: rem(24px);

                        span {
                            font-weight: 300;
                        }
                    }

                    .close {
                        right: 30px;
                        top: 50%;
                        position: absolute;
                        padding: 0;
                        
                        span {
                            font-size: rem(30px);
                        }
                    }

                    .modal-title {
                        padding: 20px 30px;
                        border-bottom: 1px solid $gray-200;
                    }
                }

                .modal-body {
                    padding: 30px;
                }

                @include media-breakpoint-down(sm) {

                    .modal-header {
                        padding-left: 20px;
                        padding-right: 20px;

                        h2 {

                            font-size: 24px;
                        }

                        .modal-title {

                            padding-bottom: 15px;
                        }
                    }

                    .modal-body {


                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

            .accordion {
                .card {
                    width: 100%;
                    border: 1px solid $gray-200;
                    border-radius: 20px;
                    margin-bottom: 1.25rem;

                    .card-header {

                        display: flex;
                        align-items: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        
                        &::after {
                            font-family: 'Font Awesome\ 6 Free';
                            font-weight: 900;
                            content: "\f077";
                            width: 24px;
                            height: 24px;
                            flex-shrink: 0;
                            margin-left: auto;
                            text-align: center;
                        }

                        &.collapsed {
                            &::after {
                                content: "\f078";
                            }
                            .card-title {
                                font-weight: 500;
                            }
                            &.show {
                                height: auto;
                            }
                        }

                        .card-title {
                            font-family: $font-family-base;
                            margin-bottom: 0;
                            font-weight: 500;
                            margin-right: 10px;
                        }
                    }

                    a {
                        color: $black;
                        // font-family: 'montserrat-light';
                        font-weight: 300;
                    }
                }
            }
        }

        .activate-golden-ticket {

            h4 {
                font-weight: 700;
                font-size: rem(22px); 
                margin-bottom: 20px;               
            }

            p {
                font-size: 15.06px;
            }

            @include media-breakpoint-down(sm) {

                h2 {

                    font-size: rem(26px);
                }

                h4 {
                    font-size: rem(18px);
                }
            }
        }

        .grid-structure {
            padding-bottom: $spacer * 3;

            &.price {
                padding-bottom: $spacer * 1.5;
            }

            h2 {
                margin: 0;

                &.title {
                    padding-top: $spacer * 1;
                    padding-bottom: $spacer * 1;
                    text-align: center;
                    font-size: 35px;
                    font-weight: 800;
                }
            }

            .items {
                @include flex-container( $align: flex-start );
                justify-content: space-between;
                flex: 0 0 100%;
                margin-left: -20px;

                .item {
                    margin: 0;
                    margin-left: 20px;
                    flex: 0 0 calc(50% - 20px);
                    padding-bottom: 1em;

                    @include break-from( medium ) {
                        flex: 0 0 calc(25% - 20px);
                    }

                    img {
                        &.icon {
                            object-fit: cover;
                            height: 60px;
                            width: 60px;

                            @include break-until ( small ) {
                                float: left;
                                margin-right: 15px;
                            }
                        }
                    }

                    &.golden {
                        .mobile {
                            display: none;
                        }

                        @include break-until ( small ) {
                            flex: 0 0 calc(100% -20px);

                            .mobile {
                                display: block;
                                float: left;
                                margin-right: 5px;
                            }

                            p {
                                padding-left: 75px;
                            }
                        }
                    }

                    h2 {
                        display: flex;
                        flex-direction: column;
                        height: 85px;
                        justify-content: flex-end;
                        margin-bottom: 0;
                        text-transform: uppercase;
                        padding-bottom: 0;
                        font-size: $font-size-base * 1.2;
                        padding-bottom: $spacer * 1.3;

                        &:after {
                            display: block;
                            content: "";
                            width: 140px;
                            height: 0px;
                            margin: 0;
                            transform: translateY(.5em);
                            border: .5px solid #000000;
                            margin-top: 10px;
                        }

                        @include break-until ( small ) {
                            font-size: 1rem;

                            &:after {

                                width: 100px;
                            }
                        }

                        @include break-from( medium ) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .grid-items {
            @include flex-container( $align: center );
            align-items: stretch;

            @include break-until ( small ) {
                display: block;
            }

            &:nth-child(odd) {
                .item {
                    &.image {
                        padding-right: $spacer;
                        padding-left: $spacer;
                        padding-bottom: $spacer;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        min-height: 380px;
                        width: 100%;

                        @include break-from( medium ) {
                            flex: 0 0 50%;
                            padding-bottom: 0;
                            padding-left: $spacer * 2;
                            padding-right: 0;
                            width: inherit;
                        }

                        @include break-from( large ) {
                            flex: 0 0 53%;
                        }

                        img {
                            object-fit: cover;

                            &.small {
                                @include break-from( medium ) {
                                    height: 490px;
                                }
                            }

                            &.medium {
                                @include break-from( medium ) {
                                    height: 790px;
                                }
                            }

                            &.large {
                                @include break-from( medium ) {
                                    height: 1380px;
                                }
                            }
                        }
                    }

                    &.about {
                        padding-top: $spacer * 2;
                        padding-bottom: $spacer * 2;

                        .text {
                            padding-left: $spacer;
                            padding-right: $spacer;
                            word-break: break-word;
                        }

                        h2 {
                            font-size: 35px;

                            &.border-line {
                                position: relative;
                                padding-bottom: 1em;
                                margin-bottom: 0;

                                &:after {
                                    content: "";
                                    width: 100px;
                                    border-bottom: 1px solid $black;
                                    display: block;
                                    position: absolute;
                                    bottom: 20px;
                                    left: 5px;
                                }
                            }
                        }

                        h4 {
                            font-size: 24px;
                        }

                        a {
                            color: $black;
                        }

                        @include break-from( medium ) {
                            flex: 0 0 50%;
                            padding-right: $spacer * 2.3;
                            padding-left: $spacer;
                            display: flex;
                            align-items: center;
                        }

                        @include break-from( large ) {
                            flex: 0 0 47%;
                        }
                    }
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .item {
                    &.image{
                        padding-right: $spacer;
                        padding-left: $spacer;
                        padding-bottom: $spacer;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        min-height: 380px;
                        width: 100%;

                        @include break-from( medium ) {
                            flex: 0 0 50%;
                            padding-bottom: 0;
                            padding-left: $spacer * 2.5;
                            padding-right: 0;
                            width: inherit;
                        }

                        @include break-from( large ) {
                            flex: 0 0 53%;
                        }

                        img {
                            object-fit: cover;

                            &.small {
                                @include break-from( medium ) {
                                    height: 490px;
                                }
                            }

                            &.medium {
                                @include break-from( medium ) {
                                    height: 790px;
                                }
                            }

                            &.large {
                                @include break-from( medium ) {
                                    height: 1380px;
                                }
                            }
                        }
                    }

                    &.about {
                        padding-top: $spacer * 2;
                        padding-bottom: $spacer * 2;

                        .text {
                            padding-left: $spacer;
                            padding-right: $spacer;
                            word-break: break-word;
                        }

                        h2 {
                            font-size: 35px;

                            &.border-line {
                                position: relative;
                                padding-bottom: 1em;
                                margin-bottom: 0;

                                &:after {
                                    content: "";
                                    width: 100px;
                                    border-bottom: 1px solid $black;
                                    display: block;
                                    position: absolute;
                                    bottom:20px;
                                    left: 5px;
                                }
                            }
                        }

                        h4 {
                            font-size: 24px;
                        }

                        a {
                            color: $black;
                        }

                        @include break-from( medium ) {
                            flex: 0 0 50%;
                            padding-right: $spacer * 2.5;
                            padding-left: $spacer * 2;
                            display: flex;
                            align-items: center;
                        }

                        @include break-from( large ) {
                            flex: 0 0 47%;
                        }
                    }
                }
            }

            .item {

                h3 {
                    font-size: 35px;
                    padding-bottom: $spacer;

                    &.border-line {
                        position: relative;
                        margin-bottom: 0;

                        &:after {
                            content: "";
                            width: 100px;
                            border-bottom: 1px solid $black;
                            display: block;
                            position: absolute;
                            bottom: 15px;
                            left: 5px;
                        }
                    }
                }
            }
        }

        .form {
            .klaviyo-form {
                padding-top: $spacer * 2;
                border: 0 !important;
            }
        }

        .rplg-slider .rplg-slider-prev {
            @include break-until ( small ) {
                left: 0!important;
            }
        }

        .rplg-slider .rplg-slider-next {
            @include break-until ( small ) {
                right: 0!important;
            }
        }
    }
}

.page-template-gticket-page {
    .partner-btn {
        background-color: $yellow;
        color: $black !important;
        border-radius: 0;
        font-weight: 700;
        border: none;
    }

    @include break-until ( small ) {

        .partner-btn {

            background: none;
            border: 1px solid $white;
            color: $white !important;
            border-radius: 30px;
        }

    }
}

.badge-lg {

    display: inline-block;
    font-size: rem(30px);
    line-height: 1.2;
    color: $yellow;
    font-weight: 700;
    padding: 8px 15px;
    border-radius: 10px;
    background: $black;

    @include media-breakpoint-down(sm) {

        font-size: rem(25px);
        padding: 5px 10px;
    }
    
}

.madpass-tab {

    display: flex;
    flex-direction: column;

    .nav-list {
    
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
    
        h2 {
            font-size: rem(34px);
            color: $default-color;
            flex-shrink: 0;
            margin-bottom: 0;
        }
    
    
        .big-tab-nav {
    
            flex-grow: 1;
            margin-left: 10px;
    
            .nav-item {
    
                margin-left: 10px;
                flex-grow: 1;
                flex-wrap: nowrap;
    
                .nav-link {
    
                    position: relative;
                    font-size: rem(20px);
                    font-weight: 300;
                    line-height: 1;
                    padding: 14px 15px 12px;
                    background: $white;
                    border-radius: 32px;
                    color: $default-color;
                    text-align: center;
                    border: 1px solid $default-color;

                    p {
                        margin-bottom: 0;   
                    }
    
                    strong {
    
                        display: block;
                        font-weight: 700;
                        font-size: rem(32px);
                    }

                    &.active {

                        background: $default-color;
                        color: $yellow;
                    }
                }

                @include media-breakpoint-down(lg) {

                    .nav-link {

                        font-size: rem(15px);
                        padding-left: 15px;
                        padding-right: 15px;

                        strong {

                            font-size: rem(20px);
                        }
                    }
                }

                @include media-breakpoint-down(xs) {

                    .nav-link {

                        font-size: rem(13px);

                        strong {

                            font-size: rem(16px);
                        }
                    }
                }
    
            }
        }

        @include media-breakpoint-down(sm) {

            flex-wrap: wrap;

            h2 {

                width: 100%;
                margin-bottom: 20px;
            }

            .big-tab-nav {

                margin-left: 0;

                .nav-item {

                    &:first-child {
                      
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .tab-content {

        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        flex-grow: 1;
        height: 100%;

        .tab-pane {

            height: 100%;

            .description {

                height: 100%;
            }
        }
    }
}


.modal {

    &.terms {

        .modal-tab {

            .nav {

                margin-left: -10px;
                padding: 20px 0;
                background: $white;
                position: sticky;
                top: 0;
                
                .nav-item {
                    flex-basis: 50%;
                }

                .nav-link {

                    position: relative;
                    font-size: rem(18px);
                    font-weight: 500;
                    color: $default-color;
                    padding: 10px;
                    border: 1px solid $yellow;
                    color: $yellow;
                    border-radius: 20px;
                    margin-left: 10px;
                    text-align: center;

                    &.active {

                        background: $yellow;
                        color: $black;
                    }
                    
                }
            }
        }
    }

    .modal-body {
        .card {
            p {
                font-size: rem(16px);
                display: initial;
                line-clamp: initial;
                -webkit-line-clamp: initial;
                margin-bottom: 10px;
            }
        }
    }
}

.activate-golden-ticket {

    .cards {

        display: flex;
        flex-direction: column;
    }

    .card {

        &.card-rounded-lg {

            flex-grow: 1;
            border-radius: 24px;
            padding: 20px 30px;
            box-shadow: none;
            border: 1px solid $gray-200;
        }
    }

    .list-count {
        margin-left: 20px;

        li {
            display: list-item;
            list-style: decimal;
            padding-left: 10px;
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-down(sm) {

        .card {

            &.card-rounded-lg {
                
                padding: 20px;
            }
        }
    }
}

.modal {

    &.cancel-refund {

        .modal-body {
            p {
                padding: 20px;
                border-radius: 20px;
                border: 1px solid $gray-200;
            }

            a {
                color: $blue-500;
            }
        }
    }
}

.price-content {
    .btn-third,
    .btn-blue,
    .btn-purple {
        position: relative;
        @include transition(0.5s all ease);
        width: 100%;

        .spinner-border {

            font-size: rem(14px);
            position: absolute;
            top: 15px;
            width: 20px;
            height: 20px;
            left: calc(50% + 59px);

            @include media-breakpoint-down(sm) {
                top: 10px;
                left: calc(50% + 49px);
            }
        }

        &.loading {

            pointer-events: none;
            opacity: 0.7;
        }
    }

    .btn-blue,
    .btn-purple {

        .spinner-border {

            color: rgba($white, 0.7);
        }
    }
}
