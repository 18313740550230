.popup-panel {

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 940px;
  height: 100%;
  overflow: auto;
  background: $white;
  z-index: 1025;
  @include transition(all 0.3s ease);
  @include transform(translateX(100%));
  box-shadow: 0 0 6px 1px rgba($black, 0.3%);

  @include media-breakpoint-down(sm) {

    top: 45px;
    box-shadow: none;
    height: calc(100% - 45px)
  }

  &.popup-rooms {

    .products .product .product-content .product-content-info {

      margin-top: auto;
      padding-top: 5px;

      .product-info {

        margin-top: 5px;
      }

    }

    @include media-breakpoint-down(sm) {

      .products .product .product-content .product-text {

        height: 67px;
      }
    }

    @include media-breakpoint-down(xs) {

      .products .product .product-content .product-text {

        height: 60px;
      }

      .products .product .product-content .product-content-info {

        margin-top: 0;
      }
    }
  }

  &.popup-tours {

    .products .product .product-content .product-content-top {

      span {

        margin-bottom: 0;
      }
    }
  }

  &.show {
    @include transform(translateX(0));
  }

  .booking-search {

    position: initial;
    top: auto;
    padding: 0;

  }

  .btn {

    width: 100%;
    margin-top: 10px;
    font-weight: 700;
    border-radius: 5px;

    &.btn-yellow {

      background: $yellow;
    }

    &.btn-disable {
      pointer-events: none;
      background: $gray-500;
    }
  }

  .popup-hero {

    position: relative;
    height: 320px;

    .img-objectfit {

      height: 320px;
    }
  
    .popup-hero-content {
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      text-align: center;
      color: $white;

      .popup-hero-content-container {

        position: relative;
        width: 100%;

        #mdatepicker {

          top: auto;
          margin-top: 10px;
        }
      }

      .popup-hero-content-inner {

        display: flex;
        flex-direction: column;
        flex-grow: 1;

        h2 {

          margin-top: auto;
          text-shadow: 0 0 10px rgba($black, 0.6%);
        }
  
        h4 {
  
          font-weight: normal;
          text-transform: uppercase;
          text-shadow: 0 0 10px rgba($black, 0.6%);
        }
  
        span {
  
          display: inline-block;
          font-size: rem(20px);
          font-weight: 700;
          margin-top: auto;
          text-shadow: 0 0 10px rgba($black, 0.6%);
        }

        em  {

          font-style: normal;
        }

        &.mobile-hero {

          @include break-from(1054px) {

            display: none;
          }
        }

      }

      &.popup-hero-content-2 {

        @include break-from(1054px) {

          .mobile-back,
          .popup-hero-content-inner {

            display: none;
          }
        }

        .cart-information {
  
          position: relative;
          top: auto;
          margin-top: 100px;
          color: $default-color;
        }
      }


      @include break-from(1053px) {

        &.popup-hero-content-2 {

          justify-content: center;
        }

        .cart-information {
          display: none !important;
        }
      }

      @include media-breakpoint-down(sm) { 

        &.popup-hero-content-2 {
  
          .cart-information {
    
            margin-top: 20px;
          }
        }
      }

    }

    @include media-breakpoint-down(sm) {

      height: auto;

      .popup-hero-content {

        position: relative;
        padding-top: 30px;
        text-align: left;
        color: $default-color;
        align-items: flex-start;

        .mobile-back {

          cursor: pointer;

          &::before {

            position: absolute;
            top: 35px;
            left: 10px;
            font-family: 'swiper-icons';
            padding: 0 10px;
            display: inline-block;
            content: 'prev';
          }

          @include media-breakpoint-up(md) {

            display: none;
          }
        }

        .popup-hero-content-inner {

          flex-grow: 1;
          width: 100%;


          h2 {

            font-size: rem(25px);
            text-shadow: none;
            padding-left: 30px;
          }
  
          h4 {
  
            font-size: rem(17px);
            text-transform: capitalize;
            text-shadow: none;
            padding-left: 30px;
          }

          span {

            font-size: rem(15px);
            display: block;
            margin-top: 0;
            text-align: center;
            text-shadow: none;
            text-decoration: underline;
            text-underline-offset: 7px;
          }

          .mobile-date-popup {

            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .book-now {

              button {

                font-size: rem(13px);
                background: none;
                border: none;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .img-objectfit {

        height: 250px;
        display: none;
      }
    }
  }

  .products {

    padding: 20px 30px;

    .product {

      position: relative;
      display: flex;
      font-size: rem(13px);
      margin-top: 20px;
      background: $white;
      box-shadow: 0 0 10px 1px rgba($black, 0.3%);
      border-radius: 10px;

      h5 {

        font-size: rem(20px);
        font-weight: 600;
      }

      p {

        margin-bottom: 0;
      }

      .product-image {

        position: relative;
        min-width: 38%;
        max-width: 38%;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        overflow: hidden;
        max-height: 300px;
        background: $gray-250;

        .img-objectfit {

          height: 100%;
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          width: 100%;
          max-width: initial;
        }

        .mobile-product-title {

          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          h5 {

            font-size: rem(20px);
            font-weight: 700;
            margin: 0;
            padding: 10px;
            text-shadow: 0 0 5px rgba($black, 0.6%);
            color: $white;
          }

          .badge {

            position: absolute;
            bottom: 10px;
            left: 10px;
            font-size: rem(11px);
            font-weight: 700;
            background: $default-color;
            border-radius: 5px;
            color: $white;
          }

          @include media-breakpoint-up(md) {

            display: none;
          }
        }
      }

      .product-content {

        display: flex;
        flex-direction: column;
        padding: 20px;
        flex-grow: 1;

        .cancel {

          font-size: rem(11px);
          font-weight: 500;
          display: block;
          color: $green-15;

          svg {

            margin-left: 3px;
            color: $gray-150;
          }
        }

        .product-content-top {

          position: relative;
          width: calc(100% - 90px);
          // z-index: 2;

          span {

            display: inline-block;
            margin-bottom: 10px;
          }
        }

        .product-text {

          overflow: hidden;
          display: -webkit-box;
          line-clamp: 5;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          height: 97px;

          &.show {
            overflow: visible;
            line-clamp: initial;
            -webkit-line-clamp: inherit;
            height: initial !important;
          }

          &.tours-text {

            overflow: initial;
            display: initial;
            line-clamp: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: vertical;
          }
        }

        .product-info {
          
          strong {

            display: block;
          }
        }

        .product-content-info {

          display: flex;
          align-items: flex-end;
          margin-top: -15px;
          width: 100%;

          .product-info {

            width: 42%;
            margin-top: 20px;
          }

          .green {

            color: $green-45;
          }

          .product-count {

            position: relative;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-shrink: 0;

            span {

              font-size: rem(16px);
              font-weight: 700;
              line-height: 1.2;
            }

            small {

              font-weight: normal;
            }

            .woocommerce-Price-currencySymbol {

              display: none !important;
            }

            .currency-name {

              margin-right: 5px;
            }

            .discount {

              position: relative;
              display: inline-block;

              &::after {

                position: absolute;
                width: 100%;
                height: 1px;
                content: '';
                top: 50%;
                left: 0;
                border-top: 1px solid $red;
              }
            }

            em {

              position: absolute;
              bottom: 0;
              right: 100%;
              width: 58px;
              margin-right: 5px;
              font-size: (12px);
              font-weight: normal;
            }

            .amount {

              span {

                font-size: 80%;
                font-weight: normal;
                margin-right: 5px;
              }
            }

            @include media-breakpoint-down(sm){

              em {

                display: none;
              }
            }
          }
        }

        .product-content-footer {

          flex-shrink: 0;
          width: 80px;
          margin-left: 15px;
        }

        .form-control {

          font-weight: 700;
          border-radius: 8px;
          background: $light-gray;
          text-overflow: ellipsis;
        }

        .readmore {

          display: inline-block;
          font-weight: 600;
          margin-top: 5px;
          color: $blue;
        }
      }
    }

    @include media-breakpoint-down(sm) {

      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;

      .product {

        flex-direction: column;
        flex-basis: 100%;        
        margin-left: 20px;

        .product-image {

          min-width: 100%;
          max-width: 100%;
          border-bottom-left-radius: 0;
          border-top-right-radius: inherit;

          img {

            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
          }
        }

        .product-content {

          flex-direction: column;
          align-items: flex-start;
          padding: 15px;

          .product-content-top {

            width: 100%;

            h5 {

              display: none;
            }
          }

          .product-text {

            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 60px;

            &.tours-text {

              overflow: hidden;
              display: -webkit-box;
              line-clamp: 3;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

              &.show {

                overflow: visible;
                line-clamp: initial;
               -webkit-line-clamp: inherit;
               height: initial;
              }
            }
          }

          .readmore {

            margin-top: 7px;
          }

          .product-content-info {

            align-items: flex-end;
            
            .product-count {

              flex-shrink: 0;
              margin-top: auto;
              margin-left: auto;
              text-align: right;
            }

            .product-info {

              .form-control {

                font-size: rem(12px);
              }
            }
          }

          .product-content-footer {

            width: 70px;
            margin-left: 10px;

          }
        }
      }

      .btn {

        border-radius: 20px;
        font-size: rem(14px);
      }
    }

    @include media-breakpoint-down(xs) { 

      .product {

        font-size: rem(11px) !important;
        flex-basis: 100%;

        .product-image {

          height: 150px;

          .img-objectfit {

            height: 150px;
          }
          
          .mobile-product-title {
  
            h5 {
  
              font-size: rem(20px);
            }
          }
        }
        
        .product-content {

          padding: 10px;

          .product-content-footer {

            width: 60px;
          }

          .product-count {

            small {
    
              font-size: rem(10px) !important;
            }
    
            span {
    
              font-size: rem(13px) !important;
            }
          }

          .product-text {
            
            height: 50px;
          }
        }

      }

      .btn {

        border-radius: 20px;
        padding: 5px 10px;
        font-size: rem(12px);
      }
    }
  }

  .popup-footer {

    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    padding: 20px 30px;
    background: $white;    
    box-shadow: 0 0 6px 1px rgba($black, 0.3%);
    z-index: 5;

    .popup-footer-start {

      #alert-loading {
        display: none;
      }

      span {

        display: block;
        line-height: 1;
        font-size: rem(24px);
        font-weight: 700;
      }

      small {

        font-size: rem(13px) !important;
      }
    }

    .popup-footer-end {

      margin-left: auto;

      .btn {

        padding-left: 20px;
        padding-right: 20px;
        font-size: rem(18px);
      }
    }

    .spinner-border {

      width: 25px;
      height: 25px;
    }

    @include media-breakpoint-down(xs) {

      padding: 10px 20px;

      .popup-footer-start {

        span {
          font-size: rem(16px);
        }
  
        small {
  
          font-size: rem(12px) !important;
        }
      }

      .btn {

        border-radius: 20px;
      }
    }
  }
}

.c-select {

  position: relative;
  margin-bottom: 0 !important;

  &.skeleton {

    > .select2 {

      opacity: 0;
    }
  }

  .select2 {

    font-size: rem(14px);
    font-weight: 700;
    border: 1px solid $gray-250;
    background: $light-gray;
    border-radius: 8px;
    height: 37px;
    width: 100% !important;

    .select2-selection--single {

      background: none !important;
      border: none !important;
      height: 37px;
    }

    .select2-selection__rendered {

      line-height: 37px;
    }

    .select2-selection__arrow {

      top: 51% !important;
      right: 8px !important;
      width: 11px;
      height: 11px;
      background: url('../images/arrow-down.png') no-repeat center;
      background-size: 100%;
      @include transform(translateY(-50%));
      opacity: 1 !important;

      b {

        opacity: 0 !important;
      }
    }
  }
}

.qty-dropdown {

  font-size: rem(14px) !important;
  margin-top: 5px;
  border-radius: 8px !important;
  border: 1px solid $gray-250 !important;
  overflow: hidden !important;

  &.select2-dropdown--above {

    margin-top: -5px;
  }
 
  .select2-results__option {

    background-color: $white !important;

    &.select2-results__option--highlighted {

      background: $default-color !important;
      color: $white !important;
    }
  }
  
}