.madloyalty-section {

  .container {
    max-width: 1350px;
    width: 100%;
  }

  h2 {
    font-size: rem(50px);
    margin-bottom: 20px;
  }
  h4 {
    font-size: rem(34px);
    font-weight: normal;
  }

  .btn {
    padding: 0.8em 2em !important;
  }

  .mobile-phone {
    max-width: 70%;
  }

  @include media-breakpoint-down(md) {

    h2 {
      font-size: rem(40px);
    }
    h4 {
      font-size: rem(28px);
      font-weight: normal;
    }
  }

  @include media-breakpoint-down(sm) {

    h2 {
      font-size: rem(30px);
    }
    h4 {
      font-size: rem(24px) !important;
      font-weight: normal;
    }
  }

  &.madloyalty-hero-section {

    padding-top: 80px;
    padding-bottom: 80px;
    background: $violet;
    background:$violet url('../images/mad_loyalty/mad_loyalty_landing_page_design.png') no-repeat;
    background-size: cover;
    background-position: center;

    h1 {
      font-size: rem(70px);
      color: $white;
    }

    h4 {
      font-size: rem(34px);
      font-weight: normal;
      margin-bottom: 20px;
      color: $white;
    }

    .btn {
      margin-top: 50px;

      &.btn-third {
        &:hover {
          background: darken($yellow, 10%) !important;
        }
      }
    }

    @include media-breakpoint-down(md) {

      h1 {
        font-size: rem(60px);
        color: $white;
      }

      h4 {
        font-size: rem(28px);
      }

      .btn {
        margin-top: 30px;
      }
    }

    @include media-breakpoint-down(sm) {

      padding-top: 60px;
      padding-bottom: 0;

      h1 {
        font-size: rem(40px);
        color: $white;
      }
      h4 {
        font-size: rem(24px);
      }
    }
  }

  &.madloyalty-section-gradient {

    @include linear-gradient(to right, $violet, $violet-200);
    color: $white;

    .mad-loyalty-title {
      position: absolute;
      top: 8.5rem;
      left: 0;
      width: 100%;

      h2 {
        margin-bottom: 0;
      }

      h4 {
        font-size: rem(30px);
      }

      @include media-breakpoint-down(lg) {
        position: relative;
        top: 0;
        margin-bottom: 30px;
      }
    }
  }

  &.madloyalty-section-gradient-2 {
    @include linear-gradient(to right, $violet, $yellow);
    color: $white;
  }

  &.madloyalty-section-background {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    background-size: cover !important;
    min-height: 400px;
    height: 50rem;

    .container-lg {
      position: relative;
      z-index: 2;
    }

    @include media-breakpoint-down(lg) {
      height: 40rem;
    }

    @include media-breakpoint-down(md) {
      height: 30rem;
      text-align: center;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.4;
      }
    }

    @include media-breakpoint-down(md) {
      height: auto;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .mad-level-list {

    a {
      display: contents;
      color: $default-color;
  
      &.active, &:hover {
        color: $white !important;
        
        .mad-levels{
          background-size: 160% 100%;
          background-position: left center;
        }
      }
    }
  }

  .mad-levels {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: rem(20px);
    margin-bottom: 20px;
    max-width: 400px;
    min-height: 120px;
    background: $violet;
    overflow: hidden; 
    @include linear-gradient(to right, $violet, lighten($violet-100, 10%));
    @include transition(all 0.3s linear);
    background-size: 200% 100%;
    background-position: right center;
    cursor: pointer;

    h5 {
      position: relative;
      font-size: rem(28px);
      font-weight: 700;
      margin-bottom: 0;
      z-index: 2;
    }

    p {
      position: relative;
      z-index: 2;
      &:last-child {
        margin-bottom: 0;
      }
    }

    // &:nth-child(even) {
    //   @include linear-gradient(to right, $violet, lighten($violet, 10%));
    //   color: $white;
    // }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      
      h5 {
        font-size: rem(24px);
      }
    }
  }

  .card {
    border-radius: rem(24px) !important;
    padding: 30px;
    color: $default-color;

    .card-img  {
      border-radius: rem(20px) !important;
      height: 295px;
      background-color: $gray-60;

      img {
        border-radius: inherit !important;
        height: 295px;
      }

      &::after {
        display: none;
      }
    }

    .card-body { 
      padding: 0;
      padding-top: 20px;
      font-size: rem(16px);

      strong {
        font-weight: 600;
      }
    }

    h4 {
      font-size: rem(20px);
      font-weight: 700;
    }

    @include media-breakpoint-down(lg) {
      .card-img  {
        height: 240px;
  
        img {
          height: 240px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .card-img  {
        height: 200px;
  
        img {
          height: 200px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;

      h4 {
        font-size: rem(18px);
      }
    }
  }

  .mad-loyalty-qr-code {
    width: 130px;
    height: 130px;
  }
}

.img-objectfit-contain {
  object-fit: contain !important;
  object-position: 50% 100% !important;
}