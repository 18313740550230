.madventure-sidebar {

  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 7px 1px rgba($dark-gray, 0.6);

  .sidebar-head {

    display: flex;
    align-items: center;
    font-size: rem(14px);

    img {

      width: 67px !important;
    }

    h3 {

      font-weight: 700;
      font-size: rem(20px);
      margin-bottom: 0;
    }

    p {

      margin-bottom: 0;
    }

    .sidebar-head-content {

      margin-left: 15px;
    }

    @include media-breakpoint-down(lg) {

      font-size: rem(12px);

      img {

        width: 47px !important;
      }

      h3 {

        font-size: rem(18px !important);
      }
    }

    @include media-breakpoint-down(md) {

      font-size: rem(14px);

      img {

        width: 67px !important;
      }

      h3 {

        font-size: rem(24px !important);
      }
    }
  }

  .sidebar-cards {

    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;

    img {

      width: 100% !important;
    }

    .card-rounded {

      position: relative;
      margin-top: 15px;
      margin-left: 15px;
      width: calc(50% - 15px);
      border-radius: 5px;
      overflow: hidden;
      height: 110px;
      background: $gray-250;

      img {
        
        border-radius: 5px;
        @include transition(all 0.3s ease);
        object-fit: cover;
        object-position: center;
        height: 110px !important;
      }

      a {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(14px);
        font-weight: 700;
        line-height: 1.2;
        padding: 10px;
        color: $white;
        text-align: center;
        z-index: 2;
      }

      &::after {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba($black, 0.3);
        @include transition(all 0.3s ease);
      }

      &:hover {

        img {

          @include transform(scale(1.1));
        }

        &::after {

          background: rgba($black, 0.1);
        }
      }

      @include media-breakpoint-up(sm) {

        height: 130px;

        img {
        
          height: 130px !important;
        }
      }
    }
  }
}

.mmxp-sidebar {

  margin-top: 40px;
}

.sidebar .cross-promo,
.sidebar .cross-promo a,
.sidebar .cross-promo img {

  border-radius: 10px;
	overflow: hidden;
  aspect-ratio: 9 / 5.07 auto;
}