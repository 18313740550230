// ==========================================================================
// Home Styles
// ==========================================================================

.home {

    .home {

        @include break-until (small) {
            padding-bottom: 0 !important;
        }
    }

    .content {

        &.home {

            padding-top: 70px;
        }
    }

    h1 {
        margin: 0;
        font-size: $font-size-base * 2.5;

        @include break-from(medium) {
            font-size: $h1-font-size;
        }
    }

    h2 {

        &.sub-title {
            font-size: $font-size-base * 1.563;
            font-weight: 500;

            @include break-from(medium) {
                font-size: $font-size-base * 2.375;
            }
        }
    }

    .destination {
        padding-bottom: $spacer;
        padding-top: 0;

        @include break-until (small) {
            padding-bottom: 0;
        }

        @include break-from(medium) {
            padding-top: $spacer * 3;
        }

        ul.list-country {
            padding: 0;
            margin: 0;
            transition: 0.15s all ease;
            @include flex-container($align: center, $justify: center);

            @include break-until(medium) {
                display: none;
            }

            @include break-from(medium) {
                padding: 0 $spacer 0;
            }

            &.sticky-nav {
                padding: $spacer 0;
                // top: 3em;
                top: 2.8em;
                text-align: center;

                @include break-from(medium) {
                    // top: 2.9em;
                    top: 4.9em;
                }

                @include break-until(1301px) {
                    top: 2.8em;
                }
            }

            li {
                list-style: none;
                padding: 0 $spacer;

                &:first-of-type {
                    a {
                        &.active {
                            &:after {
                                bottom: -10px;
                            }
                        }
                    }
                }

                a {
                    color: #121212;
                    cursor: pointer;
                    position: relative;
                    text-transform: uppercase;
                    font-size: 13px;
                    // font-family: 'montserrat-medium';
                    font-weight: 500;
                    letter-spacing: 0.75px;
                    line-height: 28px;

                    img {
                        width: 25px;
                    }

                    &.active {
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -5px;
                            right: 0;
                            width: 100%;
                            height: 1px;
                            background: $black;
                        }
                    }
                }
            }
        }

        .mnavscroll {
            top: 2.8em;
            padding: 1em 0 0;
            text-align: center;

            #list-country {
                display: block;
                overflow-x: scroll;
                white-space: nowrap;
                padding: $spacer * .5;

                li {
                    padding: 0 ($spacer * .5);
                    display: inline-block;
                }
            }

            @include break-from(medium) {
                display: none;
            }
        }

        .mobile-list-country {
            padding: ($spacer * .3) $spacer 0;
            text-align: center;
            width: 75%;
            margin: 0 auto;

            @include break-from(medium) {
                display: none;
            }

            &.sticky-nav {
                width: 100%;
                top: 2.8em;
                padding-bottom: .5em;
            }

            .form-control {
                width: 100%;
                border-radius: 0.60rem;

                &:hover,
                &:active,
                &:focus {
                    border: 1px solid #ced4da;
                }
            }
        }

        .cities {
            @include flex-container($align: stretch);
            padding-top: $spacer;
            padding-bottom: $spacer;
            flex: 0 0 100%;
            justify-content: center;
            min-height: 380px;

            @include break-until (small) {
                padding-bottom: 0;
            }

            @include break-from(medium) {
                justify-content: center;
            }

            @include break-from(large) {
                justify-content: flex-start;
            }

            .city {
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                flex: 0 0 calc(25% - 14px);
                margin: 7px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:nth-child(1) {

                    @include break-until (small) {
                        flex: 1;
                    }
                }

                @include media-breakpoint-down(md) {

                    margin: 5px;
                    flex: 0 0 calc(33.333% - 10px);
                }

                @include media-breakpoint-down(sm) {

                    flex: 0 0 calc(50% - 10px);
                }

                a {
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    border-radius: inherit;
                }

                &:hover {
                    .overlay {
                        opacity: 1;
                    }
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: .5s ease;
                    background-color: rgba(0, 0, 0, .5);
                    z-index: 9;
                    border-radius: inherit;

                    &.coming-soon {

                        font-size: rem(40px);
                        font-weight: 700;
                        line-height: 1.2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 20px;
                        padding-bottom: 60px;
                        opacity: 1;
                        color: $white;
                        text-align: center;
                        cursor: default;
                        background: none;
                        text-shadow: 0 0 5px rgba($black, 0.8);

                        @include media-breakpoint-down(lg) {

                            font-size: rem(30px);
                            padding-bottom: 80px;
                        }

                        @include media-breakpoint-down(sm) {

                            padding-bottom: 60px;
                        }

                        @include media-breakpoint-down(xs) {

                            font-size: rem(23px);
                            padding-bottom: 45px;
                        }
                    }
                }

                h2 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    font-size: $font-size-base * 0.813;
                    font-family: 'Montserrat';
                    font-weight: 700;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0;
                    width: 100%;
                    color: $white;
                    z-index: 10;
                    order: 13;
                    padding: 15px 10px;
                    background: $black;
                    border-bottom-left-radius: inherit;
                    border-bottom-right-radius: inherit;

                    span {
                        text-transform: uppercase;
                        display: block;
                        font-family: 'Montserrat';
                        font-weight: 400;
                    }

                    @include break-from(medium) {
                        font-size: $font-size-base * 1.25;
                    }

                    @include break-until(1300px) {
                        font-size: $font-size-base * 1.1;
                    }

                    @include break-until(767px) {
                        font-size: $font-size-base * 0.813;
                    }
                    
                    @include media-breakpoint-down(xs) {

                        font-size: 11px;
                        padding: 8px 5px;
                    }

                }

                img {
                    height: 187px;
                    width: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    object-position: center;
                    order: 1;
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;

                    @include break-from(xlarge) {
                        height: 275px;
                    }

                    @include media-breakpoint-down(xs) {

                        height: 150px;
                    }
                }
            }

            .city-list {
                @include flex-container($align: stretch);
                flex: 0 0 100%;

                @include break-from(medium) {
                    flex: 0 0 50%;
                }

                .city {
                    @include break-from(medium) {
                        flex: 0 0 47.4%;
                        margin: 7px;
                    }
                }
            }

            .destination-slider {
                flex: 0 0 34%;

                .carousel-inner {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;

                    @include break-until(600px) {
                        border-radius: 0;
                    }

                    .carousel-item {
                        height: 650px;

                        @include break-until(small) {
                            height: 350px;
                        }

                        .carousel-caption {
                            background-image: linear-gradient(360deg, #000000 85%, rgba(0, 0, 0, 0) 100%);
                            right: auto;
                            bottom: 0;
                            left: auto;
                            height: 62%;
                            width: 100%;

                            h5 {
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 24px;

                                @include break-until(600px) {
                                    font-size: 16px;
                                    line-height: 18px;
                                }
                            }

                            p {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;

                                @include break-until(600px) {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }

                            a {
                                background: #000000;
                                border: 1px solid #FFFFFF;
                                border-radius: 50px;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                text-transform: inherit;
                                padding: 10px 25px;

                                &:hover {
                                    background-color: white;
                                    color: $black;
                                }
                            }
                        }
                    }
                }

                .carousel-control-prev,
                .carousel-control-next {
                    z-index: 999;
                    opacity: 1;
                    top: -85px;

                    @include break-until(small) {
                        top: 170px;
                        bottom: auto;
                    }
                }

                @include media-breakpoint-down(lg) {

                    flex: 0 0 45%;
                }

                @include media-breakpoint-down(md) {

                    flex: 0 0 100%;
                    order: 2;
                    
                    .carousel-inner {

                        border-radius: 0;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        
                    }
                }

                @include media-breakpoint-down(xs) {

                    margin-left: -30px;
                    margin-right: -30px;
                    flex: 0 0 calc(100% + 30px);
                    
                    .carousel-inner {

                        border-radius: 0;
                        
                        .carousel-item {

                            height: auto;
                            min-height: 420px;

                            .carousel-caption {

                                height: auto;
                                padding-top: 40px;
                            }
                        }
                    }
                    
                    .carousel-control-next,
                    .carousel-control-prev {

                        width: 27px;

                        .carousel-control-next-icon,
                        .carousel-control-prev-icon {

                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                // @include break-until(small) {
                //     flex: 0 0 100%;
                //     -webkit-box-ordinal-group: 2;
                //     -moz-box-ordinal-group: 2;
                //     -ms-flex-order: 2;
                //     -webkit-order: 2;
                //     order: 2;
                // }
            }

            .city+.map-information {
                padding-left: 7px;
            }

            .destination-slider+.map-information {
                flex: 0 0 66%;
                //border-radius: 20px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                overflow: hidden;
                padding: 0;

                @include media-breakpoint-down(lg) {

                    flex: 0 0 55%;
                }

                @include media-breakpoint-down(md) {

                    flex: 0 0 100%;
                    order: 1;
                    height: 350px;
                    border-radius: 0;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                @include media-breakpoint-down(xs) {

                    margin-left: -30px;
                    margin-right: -30px;
                    flex: 0 0 calc(100% + 30px);
                    border-radius: 0;
                }

                // @include break-until(small) {
                //     flex: 0 0 100%;
                //     padding: 0;
                //     border-radius: 0;
                //     overflow: hidden;
                //     border-top-right-radius: 0;
                //     border-top-left-radius: 0;
                //     -webkit-box-ordinal-group: 1;
                //     -moz-box-ordinal-group: 1;
                //     -ms-flex-order: 1;
                //     -webkit-order: 1;
                //     order: 1;
                // }

                // @include break-from( medium ) {
                //     flex: 1;
                // }

                .gm-style {
                    .gm-style-iw-c {
                        box-shadow: 0 0 0;
                        background-color: rgba(255, 255, 255, 0.8);
                    }

                    .gm-style-iw-d {
                        p {
                            margin: 0;
                            color: $red;
                            font-size: 15px;
                            font-weight: 800;
                            text-shadow: 1px 1px 10px $white;

                            &.location {
                                padding-top: $spacer * .5;
                                padding-bottom: $spacer * .5;
                                color: $black;
                            }
                        }
                    }
                }

                .acf-map {
                    border-radius: 0;
                }
            }

            .information {
                flex: 0 0 100%;
                margin-top: $spacer * .5;
                padding-top: $spacer * 2;
                padding-bottom: $spacer * 3;
                text-align: center;

                @include break-until(small) {
                    -webkit-box-ordinal-group: 1;
                    -moz-box-ordinal-group: 1;
                    -ms-flex-order: 1;
                    -webkit-order: 1;
                    order: 1;
                    padding-bottom: 0;
                }

                @include break-from(medium) {
                    padding-left: $spacer * 5;
                    padding-right: $spacer * 5;
                }

                p {
                    @include break-from(medium) {
                        max-width: 75ch;
                        margin: 0 auto;
                    }
                }

                h3 {
                    padding: $spacer (
                        $spacer * .5) $spacer;
                }

                ul {
                    padding: 0;

                    li {
                        display: inline-block;
                        font-family: 'moontime';
                        font-size: $font-size-base * 2;
                        position: relative;
                        padding: $spacer * .5;

                        @include break-from(medium) {
                            padding: $spacer (
                                $spacer * .5) $spacer;
                            font-size: $font-size-base * 3;
                        }

                        svg {
                            font-size: .15em;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;
                            right: -7px;
                        }

                        &:last-of-type {
                            svg {
                                display: none;
                            }
                        }

                        &::after {
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\f111";
                            display: none;
                        }
                    }
                }
            }

            .map-information {
                @include break-until(small) {
                    -webkit-box-ordinal-group: 2;
                    -moz-box-ordinal-group: 2;
                    -ms-flex-order: 2;
                    -webkit-order: 2;
                    order: 2;
                }
            }
        }
    }

    .information+.map-information {
        flex: 0 0 100%;
        padding-top: $spacer * 3;

        @include break-from(medium) {
            /*padding-left: $spacer * 5;
            padding-right: $spacer * 5;*/
            padding-left: 1px;
            padding-right: 1px;
        }

        .acf-map {
            flex: 0 0 100%;
            border-radius: 20px;
            overflow: hidden;
            height: 320px;
        }
    }

    #mapBanner {
        &.carousel {
            .carousel-inner {
                img {
                    height: 380px;
                }
            }
        }
    }

    .map-information {
        flex: 1;
        padding: 7px 0;

        @include break-until(small) {
            flex: 0 0 100%;
            padding: 0 7px;
            margin-top: 7px;
        }

        .acf-map {
            flex: 0 0 100%;
            border-radius: 20px;
            overflow: hidden;
            // height: 380px;
            height: 100%;

            @include break-from(medium) {
                height: 100%;
            }

            @include break-until(600px) {
                border-radius: 0;
            }
        }
    }

    .qr-code {
        position: absolute;
        width: 127px;
        height: 80px;
        right: 20px;
        bottom: -100px;
        z-index: 999;
        border-radius: 10px;
        background: #934BDB;
        display: none;

        @include break-until (medium) {
            display: none !important;
        }

        .column {
            float: left;

            &.left {
                width: 60%;
                height: 100%;
                border-radius: 10px 0px 0px 10px;
                padding: 3px;
                background: rgba(253, 253, 253, 0.4);

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px 0px 0px 10px;

                    &.qr-ios,
                    &.qr-android {
                        display: none;
                    }
                }
            }

            &.right {
                width: 40%;
                background: #934BDB;
                border-radius: 0px 10px 10px 0px;
                font-weight: 800;
                font-size: 12px;
                color: $white;
                line-height: 18px;
                text-align: center;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 10px;
                    right: 10px;
                    position: absolute;
                    top: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}

.carousel-multi-item {
    .carousel-indicators {
        bottom: -13px;
    }

    .carousel-item {
        .card-body {
            position: absolute;
            bottom: 0;
            background: $black;
            width: 100%;

            h4 {
                margin: 0;
                font-size: $font-size-base * 1.25;
                font-family: 'Montserrat';
                font-weight: 400;
                color: $white;
                text-align: center;
            }
        }
    }
}

.more-article {
    color: $black;
}