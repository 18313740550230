.paging {

  display: flex;
  align-items: center;
  justify-content: center;

  ul {

    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    li {

      display: inline-block;

    }

    .page-numbers { 

      display: block;
      font-size: rem(15px);
      width: 32px;
      height: 32px;
      padding: 5px;
      border-radius: 50%;
      color: $default-color;

      &:hover {

        text-shadow: 0 0 1px $default-color;
      }

      &.prev,
      &.next {
  
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid $default-color;
        width: auto;
        border-radius: 20px;
        margin-right: 10px;
      }

      &.next {

        margin-left: 10px;
        margin-right: 0;
      }
  
      &.current {
        
        pointer-events: none;
        border: 1px solid $default-color;
        pointer-events: none;
      }

      &.processing {

        pointer-events: none;
      }
    }
  }
}