/* for animation */

.fadeUp,
.fadeUpSelf,
.fadeLeft,
.fadeRight,
.fadeIn,
.fadeInSelf,
.slideDown,
.anims {
    // opacity: 0;
    visibility: hidden;
}

.fadeUp a,
.fadeUpSelf a,
.fadeLeft a,
.fadeRight a,
.fadeIn a,
.fadeInSelf a,
.anims {
    transition: 0s all ease !important;
}

.mobile .fadeUp,
.mobile .fadeUpSelf,
.mobile .fadeLeft,
.mobile .fadeRight,
.mobile .fadeIn,
.mobile .fadeInSelf,
.slideDown,
.mobile .anims {

    // opacity: 1 !important;
    visibility: visible !important;
}

.pulsate {
    
    -webkit-animation: pulsate 1.5s ease-out;
         -webkit-animation-iteration-count: infinite;
         animation: pulsate 1.5s ease-out;
         animation-iteration-count: infinite;
         opacity: 1.0;
         transform: scale(1);
         display:inline-block;
}

@-webkit-keyframes pulsate {
    0% {
        opacity: 0.5;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1.0;
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
    100% {
        opacity: 0.5;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
  }
