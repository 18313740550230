.section-blog {

  padding-top: 50px;

  h2 {

    font-size: rem(48px);
    font-weight: 900;
    margin-bottom: 20px;
  }

  .row {

    margin-left: -10px;
    margin-right: -10px;

    .col {

      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
    }
  }

  .card {

    &.card-blog {

      .card-img {

        height: 220px;

        img {

          height: 220px;
        }

        @include media-breakpoint-down(sm) {

          height: 150px;

          img {

            height: 150px;
          }
        }

        @include media-breakpoint-down(xs) {

          height: 120px;

          img {

            height: 120px;
          }
        }
      }
    }
  }

  .swiper-holder-3 {

    border-top: 0;

    .swiper-slide {

      width: 340px;

      &:first-child {

        margin-left: 0;
      }
    }

    .swiper-button-icon {

      top: 50%;
      width: 50px;
      height: 50px;
    }
  }

  @include media-breakpoint-down(md) {

    h2 {

      font-size: rem(40px);
    }
  }

  @include media-breakpoint-down(sm) {

    h2 {

      font-size: rem(38px);
    }

    .row {

      margin-left: -5px;
      margin-right: -5px;
  
      .col {
  
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
      }
    }

    .swiper-holder-3 {

      .swiper-slide {

        width: 260px;
      }

      .swiper-button-icon {

        display: block;
        right: 0;

        &.swiper-button-prev {

          right: auto;
          left: 0;
        }
      }
    }
    
  }
}

.travel-info {

  margin-bottom: 20px;
  font-size: rem(14px);

  h3 {

    font-size: rem(20px);
    font-weight: 400;
    font-family: $font-family-base;
  }
}