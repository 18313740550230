// ==========================================================================
// Press Release Styles
// ==========================================================================
.site-content {
    .press-page {
        &.content {

            padding-top: 4.75rem;

            @include break-from ( medium ) {
                padding-top: 6.75rem;
            }

            .container {
                margin-bottom: 100px;

                .links {
                    @include break-from ( medium ) {
                        margin-top: -40px;
                    }
                }

                h1 {
                    font-family: 'Playfair Display';
                    font-size: 30px;
                    line-height: 40px;
                    border-bottom: 0.5px solid #000000;
                }

                h2 {
                    font-family: 'Playfair Display';
                    font-size: 16px;
                    line-height: 21px;
                }

                ul {
                    padding-left: 0;

                    li {
                        list-style-type: none;
                        margin-bottom: $spacer * 1.5;

                        &:before {
                            content: "> ";
                        }

                        a {
                            font-size: 16px;
                            line-height: 20px;
                            color: $black;
                        }
                    }
                }

                .posts{
                    padding-bottom: .5rem;
                    margin-bottom: 30px;
                    border-bottom: 0.5px solid $black;

                    .excerpt {
                        font-size: 12px;
                        line-height: 15px;
                        overflow: hidden;
                        display: -webkit-box;
                        line-clamp: 5;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                    }

                    .read-more {
                        a {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            color: $black;
                        }
                    }

                    .date-month {
                        text-align: center;
                        color: $white;
                        width: 48px;
                        height: 55px;
                        background: $black;
                        border-radius: 10px;
                        font-family: 'Playfair Display';
                        font-size: 30px;
                        line-height: 22px;
                        letter-spacing: -2px;

                        @include break-until ( small ) {
                            margin-bottom: 0.75rem;
                        }
                    }

                    .month {
                        font-size: 16px;
                        line-height: 15px;
                        align-items: center;
                        letter-spacing: 0.75px;
                    }
                }
            }
        }
    }
}