// ==========================================================================
// Content Styles
// ==========================================================================
.site-content {
    .content {

        padding-bottom: $spacer;

        &.esg {
            &.page {
                padding-top: $spacer * 3.5;

                @include break-from( medium ) {
                    padding-top: 60px;
                }

                @include media-breakpoint-down(xs) {

                    padding-top: 0;

                    .mobile-banner {

                        position: relative;

                        h1 {

                            display: block;
                            position: absolute;
                            font-size: 2.2rem;
                            width: 100%;
                            left: auto;
                            bottom: 0;
                            padding: 20px;
                            text-align: center;
                            color: $white;
                        }

                        img {

                            width: auto;
                            height: 350px;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    h1 {

                        display: none;
                    }
                }

                h1 {
                    text-align: center;
                    font-size: $h1-font-size * .7;
                    font-family: $font-family-base;
                    font-weight: 800;

                    @include break-from( medium ) {
                        font-size: $font-size-base * 3.75;
                    }
                }

                h2 {
                    font-size: $h2-font-size * .7;
                    padding-top: $spacer * 1.5;
                    padding-bottom: $spacer * 1.5;
                    font-family: $font-family-base;
                    font-weight: 500;

                    @include break-from( medium ) {
                        font-size: $font-size-base * 2.188;
                    }

                    &.title-35 {
                        @include break-from( medium ) {
                            font-size: $font-size-base * 2.188;
                        }
                    }

                    @include media-breakpoint-down(xs) {

                        font-size: 1.8rem;
                    }
                }

                h4 {
                    font-size: $font-size-base * 1.875;
                    font-family: 'Montserrat';
                    font-weight: 500;
                }

                img {
                    @include img-fluid;
                    width: 100% !important;
                }

                .esg-links {
                    position: sticky;
                    background: $white;
                    z-index: 5;
                    padding-top: 20px;
                    padding-bottom: $spacer;
                    margin-bottom: 20px;
                    top: 79px;

                    &.scrolled {
                        box-shadow: 0 1px 5px rgba($gray-900, 0.3);
                    }

                    @include break-until( 1299px ) {          
                        top: 70px;              
                    }

                    @include break-until( 1053px ) {          
                        top: 50px;              
                    }

                    ul {
                        padding: 0;
                        text-align: center;
                        margin-bottom: 0;

                        li {
                            list-style: none;
                            display: inline-block;
                            padding: 0 $spacer;

                            span {
                                display: block;
                                font-style: italic;
                            }

                            a  {
                                color: $black;
                                font-family: 'Montserrat';
                                font-weight: 500;
                            }
                        }
                    }

                    @include media-breakpoint-down(xs) {

                        padding-top: 20px;

                        ul {

                            display: flex;
                            align-items: flex-end;
                            font-size: 12px;

                            li {

                                flex-basis: 33.333333%;
                                padding: 0 10px;
                            }
                        }

                    }
                }

                P {
                    padding-top: $spacer;
                    padding-bottom: $spacer;
                    margin: 0 auto;
                    max-width: 82ch;
                }

                .esp-main {

                    .esp-content {
                        margin-top: $spacer * 2;
                        margin-bottom: $spacer * 2;
                        padding-top: $spacer * 2;
                        padding-bottom: $spacer * 2;
                        text-align: center;

                        .col {
                            @include break-from ( medium ) {
                                max-width: 1024px;

                                h2 {
                                    font-family: $font-family-base;
                                    font-weight: 800;
                                }
                            }
                        }

                        img {
                            margin: 0 auto;

                            &.desktop {
                                @include break-until ( small ) {
                                    display: none;
                                }
                            }

                            &.mobile {
                                display: none;

                                @include break-until ( small ) {
                                    display: block;
                                }
                            }
                        }

                        &:nth-child(even) {
                            background: $white;
                        }

                        &:nth-child(odd) {
                            background: $light-gray;
                        }
                    }
                }

            }
        }

        &.page {

            padding-top: $spacer * 3;
            padding-bottom: 50px;

            @include break-from( medium ) {

                padding-top: $spacer * 3;
            }

            .woocommerce {
                h1 {
                    font-family: inherit;
                }

                h2 {
                    font-family: inherit;

                    b {
                        font-family: inherit;
                    }
                }
            }

            h1 {
                font-family: 'Playfair Display';
            }

            h2 {
                font-family: 'Playfair Display';

                b {
                    font-family: 'Playfair Display';
                }
            }
        }

        &.archive {
            padding-top: $spacer;
        }
    }
}

.single-post {
    .site-content {
        .content {
            img {
                @include img-fluid;

                @include break-from( medium ) {
                    width: inherit !important;
                }
            }
        }
    }
}

.single-destination {
    .modal-dialog {
        .modal-content {

            .modal-header {
                width: 300px;
                flex: 0 0 39%;
                background-position: center !important;
                background-size: cover !important;
            }
            .modal-body {
                flex: 0 0 61%;
            }
        }
    }
}

.page-template-country-page {
    // .site-content {
    //     .content {
    //         @include break-from( medium ) {
    //             padding-top: $spacer * 8.5;
    //         }
    //     }
    // }

    .country-info {

        h3 {

            display: none;
        }
    }
}

.woocommerce .blockUI.blockOverlay {
    opacity: 0.9 !important;
    z-index: 99 !important;
}

.woocommerce .blockUI.blockOverlay:before,
.woocommerce .loader:before {
        height: 6em;
        width: 12em;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include break-from( medium ) {
            background-size: cover;
        }
        margin: auto;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        display: block;
        -webkit-animation: none;
        -moz-animation: none;
        animation: none;
        background-image:url('../images/booking-confirmation.gif');
        background-position: center center;
        background-size: 70%;
        background-repeat: no-repeat;
        line-height: 7;
        text-align: center;
        font-size: 2em;
        color: $white;
        z-index: 10;
}



.woocommerce #order-info .blockUI.blockOverlay:before,
.woocommerce #order-info .loader:before,
.woocommerce .order_payment_review_wrap .blockUI.blockOverlay:before,
.woocommerce .order_payment_review_wrap .loader:before  {
    background-image: inherit;
}

.woocommerce-lost-password {

    .site-content .content.page {

        padding-top: 4.75rem;
    }
}