// ==========================================================================
// Single Styles
// ==========================================================================
.single-post {
    .banner {
        .page-banner {
            &.blog {
                .carousel-item {
                    img {
                        height: 426px;
                    }
                }
            }
        }
    }

    #ez-toc-container {
        padding: 25px 30px;

        .ez-toc-title-container {
            margin-bottom: 15px;
        }

        .ez-toc-title-container {
            .ez-toc-title {
                font-weight: bold;
                font-size: rem(18px);
            }
        }

        ul {

            padding-left: 15px;
            overflow: visible !important;

            li {
                list-style: disc !important;
                overflow: visible !important;
            }
        }
    }
}

.author-info {
    @include flex-container( $align: center );
    padding-top: $spacer * 2;

    .pic,
    .information {
        @include break-from( medium ) {
            @include flex-item();
        }
    }

    .information {

        @include break-from( medium ) {
            flex-grow: 10;
        }

        font-size: $font-size-base * 0.875;
        h2 {
            font-weight: 400;
        }
    }

    .pic {
        padding-bottom: $spacer;

        @include break-from( medium ) {
            flex-grow: 2;
        }

        img {
            border-radius: 50px;
            height: 75px !important;
        }
    }
}

.content-sidebar {
    display: flex;
    flex-direction: column;

    @include break-from( medium ) {
        flex-grow: 2;
        flex-direction: row;
    }

    h2 {
        font-family: 'Montserrat';
        font-weight: 700;
    }

    h3 {
        font-family: 'Montserrat';
        font-weight: 700;
    }

    img {
        @include img-fluid;

        @include break-from( medium ) {
            width: inherit !important;
        }

        @include media-breakpoint-down(xs) {
            width: 100% !important;
        }
    }

    figure {
        width: 100% !important;

        img {
            @include img-fluid;
            width: 100% !important;

            @include break-from( medium ) {
                width: inherit !important;
            }
        }
    }

    .about-author {
        padding-top: 30px;
        flex: 0 0 100%;

        .author {
            border: 0;

            span {
                display: block;

                &:last-of-type {
                    padding-bottom: $spacer * 1.5;
                }
            }

            .at-style-responsive {
                .at-share-btn {
                    margin: 0;
                    padding: 0;
                }
            }
        }

        @include break-from( medium) {
            flex: 0 0 15%;
            flex-shrink: 0;
            padding-right: 20px;
            padding-top: $spacer * 3.5;
        }

        @include media-breakpoint-down(sm) {

            padding-top: 30px;
        }
    }

    .backpacker-guide {

        .guide {
            padding: $spacer * .5;
            border-radius: 10px;
            border: 1px solid $black;

            h2,
            h3 {
                font-family: $font-family-base;
                font-size: rem(18px);
            }

            ul {
                padding: 0 0 0 ($spacer * .7);

                li {
                    font-size: $font-size-base * 0.875;
                    a {
                        color: $black;
                    }
                }
            }

            section {

                &.widget_search {
                    label {
                        width: 100%;
                    }
                    input {
                        width: 100%;
                        background: $light-gray;
                        padding: $spacer * .3;
                        border-radius: 3px;

                        &.search-submit {
                            display: none;
                        }
                    }
                }

                ul {
                    padding: 0 0 0 18px;
                }

                h2,
                h3 {
                    font-size: $font-size-base  * 1.25;
                    font-family: 'Montserrat';
                    font-weight: 700;
                }
            }
        }
    }

    .content {
        font-size: 15px;
        flex: 0 0 100%;
        padding-top: 30px;
        font-weight: 400;

        @include break-from( medium) {
            flex: 0 0 49%;
            padding-top: $spacer * 3.5;
        }

        article {
            border-bottom: 1px solid $black;
            padding-bottom: $spacer * 2;
        }

        h1, h2, h3 {
            font-family: $font-family-base;
        }

        h2 {
            font-size: rem(24px);
        }

        h1, h3 {
            font-size: rem(18px);
        }

        iframe {
            width: 100%;
            margin-top: $spacer;
        }

        table {
            border: 1px solid $gray;
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: auto;
            table-layout: fixed;
            width: 100% !important;

            caption {
                font-size: 1.5em;
                margin: .5em 0 .75em;
            }
            tr {
                background-color: $light-gray;
                border: 1px solid $gray-50;
                padding: .35em;
            }
            th {
                padding: .625em;
                text-align: left;
                font-size: .85em;
                letter-spacing: .1em;
                text-transform: uppercase;
            }
            td {
                padding: .625em;
                text-align: left;
                width: initial !important;
            }
        }

        @media screen and (max-width: 600px) {
            table {
                border: 0;
                caption {
                    font-size: 1.3em;
                }
                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
                tr {
                    border-bottom: 3px solid $gray;
                    display: block;
                    margin-bottom: .625em;
                }
                td {
                    border-bottom: 1px solid $gray;
                    display: block;
                    font-size: .8em;
                    text-align: left;
                    &::before {
                        content: attr(data-label);
                        float: left;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {

            padding-top: 30px;
        }
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        position: relative;
        flex-grow: 1;
        padding-top: 30px;
        padding-bottom: 20px;

        @include break-from( medium) {
            padding-top: $spacer * 3.5;
            padding-left: 43px;
        }

        section {
            padding: $spacer * .5;
            border-radius: 10px;
            border: 1px solid $black;
            margin-top: $spacer * 1.7;

            &.widget_media_image,
            &.widget_travel_advisory,
            &.widget_destination_banner {
                border: 0;
                padding: 0;
            }
            
            &.widget_travel_advisory {

                display: none !important;
            }

            &.widget_custom_html {

                .btn {

                    margin-top: 10px;
                }
            }

            &.widget_views,
            &.widget_recent_entries_thumbnail {
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -15px;

                    li {
                        width: calc(50% - 15px);
                        margin-left: 15px;
                        margin-bottom: 10px;

                        a {
                            font-size: 13px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                            div {

                                height: 100px !important;
                                margin-bottom: 5px;
                            }

                            @include media-breakpoint-up(sm) {
                                
                                div {

                                    height: 120px !important;
                                }
                            }
                        }

                        img {

                            object-fit: cover;
                            object-position: center;
                            width: 100% !important;
                            height: 130px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            ul {
                padding: 0 0 0 18px;

                li {
                    font-size: $font-size-base * 0.875;
                    a{
                        color: $black;
                    }
                }
            }

            h2 {

                font-size: rem(18px);
            }

            h3 {
                font-size: rem(18px !important);
            }

            .thumbnail-post-w {

                max-height: initial;
                min-height: initial;
                margin-bottom: 15px;
            }

            .widget-image {

                max-height: initial;
                min-height: initial;
                padding: 0;
                margin-bottom: 5px;

                a > div {

                    height: 100px !important;

                    @include media-breakpoint-up(sm) {
                                
                        height: 120px !important;
                    }
                }
            }
        }

        .full-height {
            flex-grow: 1;

            @include break-from( medium) {

                margin-top: 20px;
            }
        }

        .cta-banner {

            bottom: 15px;
            position: fixed;
            max-width: 365px;
            max-height: 243px;
            z-index: 999;

            img {

                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }

            .close-cta-popup {

                position: absolute;
                top: 5px;
                right: 10px;
                font-size: rem(20px);
                cursor: pointer;
            }

            @include break-from( medium) {
                max-width: 100%;
                max-height: 100%;
                position: sticky;
            }

            @include media-breakpoint-down(lg) {

                left: 15px;
                margin-left: 0 !important;
            }
            
            @include media-breakpoint-down(sm) {

                left: 50%;
                bottom: 15px !important;
                @include transform(translateX(-50%));
                max-width: 350px;
                height: auto !important;
                width: calc(100% - 30px) !important;
                z-index: 99999 !important;
                

                img {

                    position: relative !important;
                    bottom: 0 !important;
                }

                span {
                    top: 3px !important;
                }
            }
        }
    }
}

.page-template-country-backpackers-page,
.page-template-backpackers-page {
    .site-content {
        .archive {
            padding-top: 0.5rem;

            @include break-until ( small ) {
                .navbar {
                    .sitenav {
                        overflow: auto !important;
                        padding-bottom: 0.5rem;

                        ul {
                            &.nav {
                                &.menu {
                                    display: block;
                                    overflow-x: scroll;
                                    white-space: nowrap;
                                    padding: 0.75rem;

                                    .sub-menu {
                                        li {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            li {
                                padding: 0;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            main {
                article {
                    &::after {
                        background: rgba(0,0,0,0.45);
                    }

                    &:hover {
                        &::after {
                            background: transparent;
                        }
                    }

                    h2 {

                        @include media-breakpoint-down(sm) {

                            font-size: rem(16px !important);

                            time {

                                font-size: rem(13px) !important;
                                line-height: normal !important;
                            }
                        }

                        &.backpacker {
                            text-transform: uppercase;
                        }

                        &.article {
                            font-size: $font-size-base  * 1.875;

                            @include break-until ( small ) {
                                font-size: 20px;
                            }
                        }

                        time {
                            // font-family: 'montserrat-light';
                            font-style: normal;
                            font-weight: 300;
                            font-size: $font-size-base  * 1.25;
                            line-height: 28px;

                            @include break-until ( small ) {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-template-talent-recruitment-page {
    .site-content {
        .desktop {
            @include break-until ( small ) {
                display: none;
            }
        }
        .mobile {
            display: none;

            @include break-until ( small ) {
                display: block;
            }
        }
        .who {
            @include break-until ( small ) {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .content {
        &.page {
            padding-top: 0;
            padding-bottom: 0;

            &.talent-header {

                display: flex;
                align-items: center;
                position: relative;
                min-height: 500px;
                background: url("../images/hiring/header.png") no-repeat;
                background-size: 50%;
                background-position: top right;

                &:after {

                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    height: 100%;
                    width: 60%;
                    background-image: linear-gradient(90deg, #000000 84%, rgba(0, 0, 0, 0) 100%);
                }

                .container {

                    position: relative;
                    z-index: 2;
                    color: #fff;
                }

                h1 {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 70px;
                    line-height: 93px;
                }

                @include break-until ( 1710px ) {
                    background-size: auto 100%;
                }

                @include break-until ( medium ) {

                    background-size: cover;
                    background-position: center;

                    &:after {

                        width: 100%;
                        background-image: linear-gradient(90deg, #000000 5%, rgba(0, 0, 0, 0) 60%);
                    }
                    
                    h1 {

                        font-size: 50px !important;
                    }

                    p {

                        font-size: 20px !important;
                    }
                }

                @include media-breakpoint-down(xs) {

                    align-items: flex-end;

                    .mobile-display {

                        padding: 30px 0;
                    }

                    &:after {

                        background-image: linear-gradient(0deg, #000000 35%, rgba(0, 0, 0, 0) 60%);
                    }

                    h1 {

                        margin-bottom: 50px !important;
                        font-size: 40px;
                        line-height: 45px;
                        margin-bottom: 20px;
                    }

                    .btn {

                        margin-bottom: 20px;
                    }

                    p {
                        font-weight: 300;
                        font-size: 18px !important;
                        line-height: 1.3;
                        -webkit-box-ordinal-group: 2;
                        -moz-box-ordinal-group: 2;
                        -ms-flex-order: 2;
                        -webkit-order: 2;
                        order: 2;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    .looking {
        background: url("../images/hiring/mm.png") no-repeat center right;

        .people {
            @include break-until ( small ) {
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .extrabold {
            @include break-until ( small ) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .embed {
        background-image: url("../images/hiring/mm-official-logo.png");
        background-position: left 0% bottom 0%;
        background-repeat: no-repeat;
        background-size: 40%;

        iframe {
            @include break-until ( small ) {
                height: auto;
            }
        }
    }

    .guest {
        .moontime {
            @include break-until ( small ) {
                font-size: 30px !important;
                line-height: 30px !important;
            }
        }

        .extrabold {
            @include break-until ( small ) {
                font-size: 20px !important;
                line-height: 24px !important;
            }
        }
    }

    .core {
        .col {
            @include break-until ( small ) {
                flex-basis: auto;
                height: 150px;
            }
        }
    }

    #career {
        .extrabold {
            @include break-until ( small ) {
                font-size: 30px !important;
                line-height: 24px !important;
            }
        }
    }

    .moontime {
        font-family: 'MoonTime';
        font-style: normal;
        font-weight: 100;
        font-size: 70px;
        line-height: 35px;

        @include break-until ( small ) {
            font-weight: 400;
            font-size: 40px;
        }
    }

    .extrabold {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 34px;
        line-height: 36px;

        @include break-until ( small ) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .values {
        font-size: 16px;
        line-height: 20px;
    }

    #freshteam-widget {
        flex: 1;

        .content {
            padding-top: 1rem;
        }

        .select2-selection__clear {
            position: initial;
        }

        p {
            font-size: inherit;
            line-height: normal;
        }
    }

    .light-gray {
        color: rgba(0, 0, 0, 0.05);
    }

    @mixin hr-line {
        flex: 1;
        background: #c7c7c7;
        content: "";
        height: 1px;
      }

      $padding: 15px;

      .text-hr {
        display: flex;
        align-items: center;
        // font-family: 'montserrat-thin';
        font-style: normal;
        font-weight: 100;
        font-size: 60px;
        line-height: 35px;
        width: 100%;
        margin: 15px auto;
        color: #444;

        @include break-until ( small ) {
            font-size: 32px;
        }

        &__text {
          padding-right: $padding;

          &:not(:first-child) {
            display: none;
          }
        }

        &::after {
          @include hr-line;
        }

        &--right {
          .text-hr__text {
            order: 1;
            padding: 0 0 0 $padding;
          }
        }

        &--double {
          .text-hr__text {
            &:nth-child(2) {
              order: 1;
              display: inline;
              padding: 0 0 0 $padding;
            }
          }
        }

        &--center {
          &::before {
            @include hr-line;
          }

          .text-hr__text {
            padding: 0 $padding;
          }
        }

        // Added 2016-07-09
        &--triple {
          .text-hr__text {
            &:nth-child(2) {
              order: 2;
              display: inline;
              padding: 0 $padding;
            }

            &:nth-child(3) {
              order: 4;
              display: inline;
              padding: 0 0 0 $padding;
            }
          }

          &::after {
            order: 3;
          }

          &::before {
            @include hr-line;
            order: 1;
          }
        }

        &--loud {
          .text-hr__text {
            font-size: 36px;
            font-weight: bold;

            &:nth-child(2) {
              font-size: 12px;
              font-weight: normal;
            }
          }
        }
    	}

    p {

    }

    button {
        border: 2px solid #fff !important;

        &:hover {
            color: #fff !important;
        }
    }

    @include break-until ( small ) {
        #freshteam-widget .col-xs-8 {
            width: 100% !important;
        }

        .travel-advisory {
            p {
                line-height: inherit;
            }
        }
    }
}

.content-sidebar .social-icons {

    img {

        width: auto !important;
    }
}