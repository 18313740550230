.thankyou-page {

  h1 {

    font-size: rem(24px);
    margin-bottom: 40px;
  }

  .container-xs {

    max-width: 410px;

    .card {

      border-radius: 10px;
      padding: 40px;

      @include media-breakpoint-down(xs) {

        padding: 20px;
      }
    }
  }
}