.mmexperience-section {

  h1, h2 {

    font-size: rem(48px);

    span {
      color: $purple;
    }
  }

  h4 {
    font-size: rem(24px);
    font-weight: normal;
    color: $gray-300;
  }

  &.highlight {
    background: $gray-100;
  }

  &.light-purple {
    background-color: $purple-500;
  }

  .card-plain {

    padding: 20px;
    border-radius: 20px;
    
    h3 {
      font-size: rem(24px);
      font-family: $font-family-base;
      font-weight: 700;
      color: $purple;
    }
    
    .card-body {

      border-top: 1px solid $gray-200;

      h4 {
        font-size: rem(20px);
        font-weight: 700;
        color: $default-color;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    h1,h2 {
      font-size: rem(36px); 
    }

    h4 {
      font-size: rem(20px);
    }
  }
  
}

.mmxp-hero-section {

  h1 {
    color: $purple;
    margin-bottom: 20px;
  }

  h4 {
    font-size: rem(24px);
    font-weight: normal;
    color: $gray-300;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(sm) {
    h4 {
      font-size: rem(20px);
    }
  }
}

.graphics-rounded {

  position: relative;
  border-radius: 24px;
  overflow: hidden;
  min-height: 450px;

  .img-objectfit {
    min-height: 450px;
  }

  &.sm {
    min-height: 300px;

    .img-objectfit {
      min-height: 300px;
    }
  }

  @include media-breakpoint-down(md) {
    border-radius: 20px;
    min-height: 350px;

    .img-objectfit {
      min-height: 350px;
    }
  }
}

.grid-text {

  h2 {
    font-size: rem(36px);
    color: $purple;
  }
}

.list-check {

  font-size: rem(14px) !important;

  ul {
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  li {
    display: flex;
    list-style: none;
    margin-bottom: 10px;

    &:before {
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      background: url('../images/mmxp/icon_check.svg') no-repeat;
      background-size: 100%;
      content: '';
      margin-right: 10px;
    }

    i {
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  p {
    em {
      font-style: normal;
      color: $purple;
      font-size: rem(16px);
      line-height: 1;
      font-weight: 500;
    }
    strong {
      color: $purple;
    }
  }

}

.tooltip {
  font-size: rem(12px) !important;
  text-align: left !important;

  *, p {
    font-size: rem(12px) !important;
    text-align: left !important;
  }
}

.card-lg {

  border: 1px solid $gray-200;
  padding: 30px;
  border-radius: 24px;
}

.mad-level-list {

  justify-content: center;

  &.card-plain {
    h2 {
      font-size: rem(36px);
      font-weight: 700;
      color: $purple;
      padding-bottom: 0 !important;
      margin-bottom: 20px;
    }
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    font-size: rem(24px);

    strong {
      color: $purple;
    }
  }
}

.table-container {

  &.mmxp-table-perks {
    border: 1px solid $gray-200;
    border-radius: 24px;
  
    h4 {
      font-weight: 700;
      color: $purple;
    }

    h5 {
      font-size: rem(20px);
      font-weight: 700;
      margin-bottom: 0;
    }
  
    table {

      th {
        padding: 15px 20px;
        color: $purple;

        span {
          font-weight: normal;
        }
      }
      
      thead {
        background-color: $gray-100;
      }
  
      tbody {
        tr {
          border-bottom: none;
  
          &:nth-child(even) {
            background-color: $gray-100;
          }
        }

        td {
          padding: 15px 20px;
        }
      }

    }

    .icon-check {
      width: 20px;
      height: 20px;
      margin: 0 auto;
      background: url('../images/mmxp/icon_check.svg') no-repeat;
      background-size: 100%;
      content: '';
    }
  }
}


.mobile-perks {
  position: relative;
  overflow: hidden;
  border: 1px solid $gray-200;
  border-radius: rem(24px);

  .mobile-perks-list {

    font-size: rem(14px);

    &:last-child {

      ul {
        li {
          &:last-child {

            border-bottom-left-radius: rem(24px);
            border-bottom-right-radius: rem(24px);
          }
        }
      }
    }

    h4 {
      display: none;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      list-style: none;
      padding: 10px 15px;
      font-size: rem(14px);

      &:nth-child(even) {
        background-color: $gray-100;
      }
    }

    .mobile-perks-title {
      background-color: $gray-100;
      padding: 15px;

      p {
        display: none;
      }

      h5 {
        display: inline-block;
        font-size: rem(18px);
        margin-bottom: 0;
        color: $purple;
        font-weight: 500;
      }
  
      h3 {
        display: inline-block;
        font-size: rem(18px);
        margin-bottom: 0;
        font-weight: 700;
        padding-left: 5px;
        color: $purple;
        font-family: $font-family-base;
      }
    }

    .mobile-perks-content {

      p {
        margin-top: -15px;
        padding-left: 15px;
        margin-bottom: 0;
        background-color: $gray-100;
        padding-bottom: 15px;

        em {
          font-style: normal;
          font-weight: 500;
          color: $purple;
        }

        strong {
          color: $purple;
        }
      }
    }
  }
}