// ==========================================================================
// Navigation Styles
// ==========================================================================
.site-header {

	.has-sub {

		> a {

		}
	}
}

.navbar {
	justify-content: flex-end;
	padding: 0;

	&.navbar-wide {

		.menu {

			> li {

				flex-shrink: 0;
				margin: 0 10px;

				&:first-child {
					margin-left: 10px;
				}

				svg {
					font-size: $font-size-base * .7;
					color: $black;
					top: 0;
					bottom: 0;
					margin: auto 0 auto 4px;

					@include break-until( medium ) {
						font-size: $font-size-base * .7;
						margin-left: 4px;
					}
				}

			}
		}

		@include media-breakpoint-down(lg) {

			.menu {

				> li {
					margin: 0 !important;
				}

			}
		}

		@include media-breakpoint-down(md) {

			.menu {

				flex-wrap: nowrap;
				justify-content: flex-start;

				> li {

					padding-top: 10px;
					padding-bottom: 10px;
				}

			}

			.sitenav {

				position: relative;
				overflow-x: auto;
			}
		}
	}

	&.navbar-mobile {

		.sitenav {

			.arrow {

				display: none;
			}

			.has-sub {

				.arrow {

					position: relative;
					display: inline-block;
					font-size: 75%;
					padding: 5px;
					cursor: pointer;
					z-index: 5;
				}

				a {

					&::after {

						display: none !important;
					}
				}
			}
		}

		@include media-breakpoint-down(md) {

			.sitenav {

				position: relative;
				overflow-x: auto;

				.menu {

					flex-wrap: nowrap;
					justify-content: flex-start;

					li {

						flex-shrink: 0;
					}

					.sub-menu {

						padding: 0;
	
						a {
	
							padding: 10px 20px !important;
							border-bottom: 1px solid $light-gray;
						}
					}
					
					.has-sub {

						> .sub-menu {

							@include transition(all 0.3s ease);
							@include transform-origin(center bottom);
						}

						svg {
	
							padding: 5px;
							margin-bottom: -3px;
						}
					}
				}
			}
	
			.sub-menu {
	
				position: fixed !important;
				bottom: 0 !important;
				left: 0 !important;
				width: 100% !important;
				margin: 0 !important;
				border-radius: 0 !important;
				background: $gray-900 !important;
				max-height: calc(100% - 54px);
    		overflow-y: auto;
				top: auto !important;
	
				a {
	
					color: $white !important;
				}
			}
		}
	}

	.menu {
		margin-bottom: 0;
		padding-left: 0;
		list-style-type: none;
		justify-content: center;
		align-items: center;

		> li {
			padding: 18px 10px;
			display: block;
			margin: 0 0;

			&:first-child {
				margin-left: 0;
			}

			> a {
				color: $black;
				transition: 0.3s;
				position: relative;
				padding: 0;
				border-bottom: 0;
				text-transform: capitalize;
				text-decoration: none;
				font-size:  $font-size-base * 0.875;

				&[aria-current] {
					padding-bottom: 0;
    				border-bottom: 2px solid #000000;
				}
			}

			&.has-sub {

				> a {

					&::after {

						font-family: 'FontAwesome';
						font-weight: 400;
						content: '\f078';
						display: inline-block !important;
						margin-left: 5px;
						font-size: 80%;
					}
				}
			}
		}

		&.inline {
			li {
				display: inline-block;
				margin-right: $spacer;
				margin-bottom: 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.book-now {

			padding-top: 11px;
			padding-bottom: 11px;
			padding-right: 0;
			margin-right: 0;

			&.green {

				> a {

					background-color: $green-60;
				}
			}
			
			> a {
				display: block;
				font-weight: 700;
				background: $yellow;
				border-radius: 10px;
				padding: 10px 10px 10px 10px;
				height: 40px;
			}
			
			.sub-menu {

				a {

					color: $black !important;
				}

				li {

					opacity: 1 !important;
				}
			}

		}

		> .has-sub {
			position: relative;

			li {
				padding-left: 0;
				margin-left: 0;

				a {
					color: $black;
					text-align: left;
					font-size:  rem(13px);

					&:hover {
						text-decoration: none;
					}
				}

				&.active {
					a {
						&::before {
							content: ' ';
							border-bottom: 0;
						}
					}
				}
			}

			svg {
				font-size: $font-size-base * .7;
				color: $black;
				top: 0;
				bottom: 0;
				margin: auto 0 auto 4px;
			}

			&::after {
				display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    text-rendering: auto;
			    -webkit-font-smoothing: antialiased;
			    font-family: "Font Awesome 5 Free";
			    font-weight: 900;
			    content: "\f078";
			    color: $white;
			    font-size: 0.9em;
			    position: relative;
			    right: -5px;
				display: none;

				@include break-until( 992px ) {
					font-size: 0.9em;
				}
			}

			&:hover,
			&:active,
			&:focus {
				> .sub-menu {
					opacity: 1;
					transform: scale( 1, 1 );
					background: $white;
					-webkit-box-shadow: 0 3px 3px 0px rgba(0,0,0,.1);
            		box-shadow: 0 3px 3px 0px rgba(0,0,0,.1);

					@include media-breakpoint-down(md) {
						position: relative;
						width: 100%;
					}

					li {
						visibility: visible;
					}
				}

			}
		}
	}

	.sub-menu {
		margin: $spacer 0 0 (-$spacer / 2);
		padding: 10px;
		top: 35px;
		list-style: none;
		opacity: 0;
		transform: scale( 1, 0 );
		transform-origin: top;
		transition: opacity 0.1s, transform 0 linear;
		position: absolute;
		z-index: 1000;
		background: $white;
		border-bottom-right-radius: $spacer;
		border-bottom-left-radius: $spacer;

		li {
			visibility: hidden;
			width: 100%;
			margin-right: 0;
			padding-right: 0;

			&.text-center {

				a {

					text-align: center !important;
				}
			}

			&.text-indent {
				padding-left: 20%;
			}
		}

		a {
			color: $white;
			white-space: nowrap;
			display: block;
			padding: ($spacer / 12) ($spacer / 2);
			font-family: 'Montserrat';
        	font-weight: 400;
		}
	}
}

.archive .navbar {
	justify-content: center;

	.sitenav {
		.nav {
			&.menu {
				a {
					text-transform: uppercase;
				}
			}
		}
	}
}

.navbar {

	&.top-index {

		z-index: 994 !important;

		@include media-breakpoint-down(md) {

			&:after {

				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				content: '';
				background: rgba($white, 0.5);
				z-index: 6;
			}
		}

		@include break-until(1053px) {

			.menu {

				.has-sub:hover {

					.sub-menu {

						opacity: 1 !important;
						transform: scale(1,1) !important;
					}
				}
			}
		}
	}

	&.pos-sticky {

		position: sticky;
		top: 4.7em;
		justify-content: center;
		margin-top: 10px;
		z-index: 991;
		background: $white;

		&.scrolled {

			box-shadow: 0 1px 5px rgba($gray-900, 0.3);
		}

		.menu {

			a {

				text-transform: uppercase;
			}

			@include break-until(1053px) {

				.has-sub {
	
					.sub-menu {
	
						opacity: 0;
						transform: scaleY(0);
					}
				}
			}
		}

		@include break-until(1299px) {

			top: 60px;
		}

		@include break-until(1054px) {

			top: 44px;
		}
	}

	&.has-shadow {

		box-shadow: 0 1px 5px rgba($gray-900, 0.3);
		padding: 15px 0;

		li {

			&.active {

				a {
					font-weight: 700;
				}
			}
		}

		@include media-breakpoint-down(md) {

			padding: 0;
		}

		@include media-breakpoint-down(sm) {

			.container {

				max-width: 100%;
			}
		}
	}
}

.sitenav {
	.nav {
		&.menu {
			a {
				img {
					width: 25px;
				}
			}
			.has-sub {
				a {
					&::after {
						display: none;
					}
				}

				&.line-spacing {
					
					.sub-menu {

						li {

							margin-bottom: 7px;
						}
					}
				}
			}
		}
	}
}

@include break-from( medium ) {
	.menu {
	  .has-sub.has-mega-menu {

			> .sub-menu {

				display: initial !important;
				width: 497px;
				max-height: 320px;
				top: 35px;
				left: 0;
				padding-bottom: 15px;
				column-count: 3;
				column-gap: 10px;
				-moz-column-count: 3;
				-moz-column-gap: 10px;
				-webkit-column-count: 3;
				-webkit-column-gap: 10px;
				grid-auto-flow: row dense;

				.mega-menu-column {

					width: 100%;
					-webkit-column-break-inside: avoid;
					-webkit-perspective: 1;
					page-break-inside: avoid;
					-moz-column-break-inside: avoid;
					break-inside: avoid;
					-webkit-column-span: none;
					-moz-column-span: none;
					column-span: none;
					display: inline-block;
					margin-bottom: 10px !important;
				}
			}
	  }

	  li {

			&.has-mega-menu .sub-menu {
				width: 50%;
				top: 50px;
				z-index: 100;
			}

			&.has-sub.has-mega-menu:hover > .sub-menu {
				display: flex;
				flex-wrap: wrap;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
			}
	  }
	}
  }

  @include break-from( medium ) {
	.menu li.has-sub.has-mega-menu:hover > .sub-menu {
	  grid-template-columns: repeat(auto-fit, minmax(33.3333%, 1fr));
	}
  }

  @include break-from( large ) {
	.menu li.has-sub.has-mega-menu:hover > .sub-menu {
	  grid-template-columns: repeat(auto-fit, minmax(33.3333%, 1fr));
	}
  }

  @include break-from( medium ) {
	.menu .sub-menu li.mega-menu-column {
	  width: 100%;
	  max-width: 100%;
	  min-height: 1px;
	  padding: 0;
	  flex: 1 0 calc(50%);
		margin-bottom: 15px;

	  > a {
		color: $black;
		text-transform: uppercase;
		font-size:  $font-size-base * 0.875;
		font-family: 'Montserrat';
    	font-weight: 700;
	  }

	  .sub-menu {
		padding: 0;
		margin: 0;
		position: inherit;
		opacity: 1;
		visibility: visible;
		transform: inherit;
		width: inherit;
		transform-origin: inherit;

		li {
			a {
				// font-family: 'montserrat-light';
				font-weight: $font-family-base;
        font-weight: 400;
			}
		}
	  }
	}
  }

  @include break-from( medium ) {
	.menu .sub-menu li.mega-menu-column {
	  flex: 1 0 calc(33.333%);
	}
  }

  @include break-from( large ) {
	.menu .sub-menu li.mega-menu-column {
	  flex: 1 0 calc(33.3333%);
	}
  }

  .page-template-country-backpackers-page,
  .page-template-backpackers-page {
	.navbar {
		.menu {
			> .has-sub {
				&:hover {
					> .sub-menu {
						@include break-until( small ) {
							width: 100%;
							right: auto;
							margin: 0 auto;
							z-index: 9999;
							float: left;
						}
					}
				}
			}
		}
	}
}

.navscroll-big {

	position: sticky;
	top: 4.7em;
	text-align: center;
	background: $white;
	padding: 15px 0;
	z-index: 99;
	overflow: auto;
	@include transition(all 0.3s ease);

	&.scrolled {

			box-shadow: 0 1px 5px rgba($gray-900, 0.3);

			ul {

					li {

							font-size: rem(16px);
							font-weight: 300;
					}
			}
	}

	ul {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			list-style: none;
			margin: 0;

			li {

					font-size: rem(30px);
					font-weight: 200;
					line-height: 1;
					padding: 0 30px;
					@include transition(all 0.3s ease);

					+ li {

							border-left: 1px solid $default-color;
					}

					a {

							color: $default-color;
					}

					&.active {

							font-weight: 700 !important;
							pointer-events: none;
					}
			}

	}

	@include break-until(1299px) {

			top: 60px;
	}

	@include break-until(1054px) {

			top: 44px;
	}


	@include media-breakpoint-down(md) {

			ul {

					li {

							font-size: rem(25px);
							font-weight: 400;
							padding-left: 15px;
							padding-right: 15px;
					}
			}
	}

	@include media-breakpoint-down(sm) {

			ul {

					li {

							font-size: rem(20px);
					}
			}
	}

	@include media-breakpoint-down(xs) {

			ul {

					justify-content: flex-start;

					li {

							font-size: rem(16px);
					}
			}
	}
}

.book-now-green {
	
	margin-right: 20px;

	a {

		display: block;
		font-weight: 700;
		background: $green-60;
		color: $black;
		border-radius: 10px;
		padding: 10px 10px 10px 10px;
		height: 40px;
		line-height: normal;
	}

	@include break-from(1300px) {

		display: none;
	}

	@include media-breakpoint-down(md) {
		
		a {

			font-size: rem(13px);
			line-height: 1.5;
		}
	}
}

.hiring a {
	background: linear-gradient(180deg, #ecf502 0%, #db00ff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-weight: 700;

	.sitenav-mobile & {
		background: linear-gradient(180deg, #0500FF 0%, #DB00FF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-weight: 700;
	}  
	
}


li.hiring:after {
	content: "✨";
}

.my-profile {
	li {
		&.highlight {
			a {
				color: $purple !important;
				font-weight: 600;
			}
		}
	}
}