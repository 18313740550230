.pdf-header {

  position: sticky;
  top: 0;
  padding: 10px 0;
  padding-bottom: 8px;
  background: $yellow;
  box-shadow: 0 1px 5px rgba($gray-900, 0.3);

  .container-md {

    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 840px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pdf-burger-menu {

    padding: 11px 7px;
    width: 36px;
    height: 36px;

    span {

      display: block;
      border-top: 1px solid $default-color;

      &:nth-child(2) {

        margin: 5px 0;
      }

    }
  }

  @include media-breakpoint-down(md) {

    padding-top: 9px;
    padding-bottom: 7px;

    .logo {

      img {

        width: 120px;
        height: 36px;
      }
    }
  }
}

.main-wrapper {

  flex: 100%;
  display: flex;
  flex-direction: column;
}

.pdf-section {

  padding: 0;
  flex: 100%;
}

.m-auto {

  margin: 0 auto;
}

.pdf-navigation {

  ul {

    display: flex;
    margin: 0;
    padding: 0;

    li {

      list-style: none;

      a {

        font-family: $font-family-base;
        font-size: rem(15px);
        font-weight: 500;
        text-decoration: none;
        color: $default-color;
        padding: 10px;
      }
    }
  }
}

.pdf-mobile-menu {

  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 30px);
  max-width: 300px;
  height: 100%;
  overflow: auto;
  background: $white;
  box-shadow: 2px 2px 5px rgba($gray-900, 0.3);
  z-index: 3;
  @include transition(all 0.3s ease);
  @include transform(translateX(101%));

  &.active {

    @include transform(translateX(0));
  }

  ul {

    padding: 0;
    margin: 0;
    text-align: right;

    li {

      a {

        display: block;
        font-size: rem(14px);
        padding: 10px 15px;
        border-top: 1px solid $light-gray;
        text-decoration: none;
        color: $dark-gray;
      }
    }
  }

  .pdf-header-menu {

    display: flex;

    .pdf-menu-close {

      font-size: rem(25px);
      padding: 6px 15px;
      margin-left: auto;
      cursor: pointer;
    }
  }
}

.pdf-backdrop {

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5);
  z-index: 2;

  &.active {  
    display: block;
  }
}

