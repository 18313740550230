.lead {

  font-weight: normal;
}

.lead-3 {

  font-size: rem(18px);
}

.typography {

  max-width: 700px;
  margin: 0 auto;
  
  h3 {

    font-size: rem(40px);
    line-height: 1;
  }

  .text-left {

    font-family: $font-family-base;
    font-weight: 200;
    font-style: italic;
  }

  .text-right {

    font-size: rem(50px);
  }
  
  @include media-breakpoint-down(xs) {

    h3 {

      font-size: rem(25px);
      line-height: 1;
    }

    .text-right {

      font-size: rem(35px);
    }
  }

}

.video-embed {

  position: relative;
  width: 100%;
  padding-bottom: 56.3%;

  iframe {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.section-event {

  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background: url('../images/bar/bg.jpg') no-repeat center;
  background-size: cover;
  color: $white;

  &::after {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba($black, 0.7);
  }
}

.container-xs {

  position: relative;
  max-width: 680px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 2;
}

.event-schedule {

  ul {

    padding: 0;
    list-style: none;
  }

  li {

    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    height: 72px;

    em {

      flex-grow: 1;
      border-top: 1px solid rgba($white, 0.3);
      margin: 17px 10px;
    }

    strong {

      &:first-child {

        font-size: rem(40px);
        line-height: 0.8;
        color: $red-400;
        width: 104px;
        flex-shrink: 0;
      }

      &:last-child {

        font-size: rem(24px);
        font-weight: normal;
        max-width: 50%;
        text-align: right;
        flex-shrink: 0;
      }
    }

    span {

      font-size: rem(24px);
      max-width: 50%;
      text-align: right;
      flex-shrink: 0;
    }
  }

  @include media-breakpoint-down(sm) {

    li {

      height: 52px;

      strong {

        &:first-child {
  
          font-size: rem(20px);
          width: 60px;
        }
  
        &:last-child {
  
          font-size: rem(14px);
        }
      }
  
      span {
        
        font-size: rem(14px);
      }

      em {

        margin: 9px 5px;
      }
    }
  }
}

.btn-red {

  border-radius: 30px;
  background: $red-400;
}

.swiper-holder-auto {

  position: relative;

  .swiper-wrapper {

    transition-timing-function: linear;
    -webkit-transition-timing-function: linear;


    .swiper-slide {

      height: auto !important;
    }
  }

  @include media-breakpoint-down(sm) {

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      content: '';
    }
  }
}


.is-list {
	font-size: rem(24px);

  @include media-breakpoint-down(sm) {

    font-size: rem(14px);
  }
}
