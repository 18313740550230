.zx-3 {
  z-index: 3;
}

.career-page {

  .container {

    max-width: 1350px;
  }

  a {

    @include transition(all 0.3s ease);
  }

  h2 {

    font-size: rem(50px);
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {

      font-size: 42px;
    }
  }

  h4 {

    font-size: rem(45px);
    font-style: italic;
    text-shadow: 0 0 12px rgba($black, 0.7);

    @include media-breakpoint-down(lg) {

      font-size: 30px;
    }
  }

  h5 {

    font-size: rem(35px);
    font-weight: 300;

    @include media-breakpoint-down(md) {

      font-size: 28px;
    }

    @include media-breakpoint-down(xs) {

      font-size: 26px;
    }
  }

  strong, 
  h6 {

    font-size: rem(20px);
    font-weight: 500;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {

      font-size: 18px;
    }
  }

  ul {

    margin: 0;
    padding: 0;
  }

  .btn {

    &.btn-black {

      font-size: rem(14px);
      padding: 10px 15px;
      background: $black;
      color: $yellow;
      border-radius: 10px;

      i,
      svg {

        margin-left: 40px;
      }
    }
  }

  .container-sm {

    max-width: 700px;
  }

  .container-md {

    max-width: 1200px;

    &.right {

      margin-right: 0;
    }
  }

  .container-smd {

    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }

  .yellow-bg {

    padding-top: 40px;
    padding-bottom: 40px;
    background: $yellow;

    P {

      &:last-child {

        margin-bottom: 0;
      }
    }
  }

  .swiper-wrapper {

    height: 100% !important;
  }
  
  .section {

    position: relative;
    padding: 60px 0;

    &.section-hero {
      
      padding: 0;

      @include media-breakpoint-down(lg) {

        height: 500px;
      }

      @include media-breakpoint-down(md) {

        height: 400px;
      }

    }

    &.section-snippet {

      background: $black;
      color: $white;
    }

    &.section-dark {

      background: $black;
      color: $white;
    }

    &.section-career-team {

      h2 {

        margin-right: 40%;
      }

      @include media-breakpoint-down(md) {

        h2 {

          margin-right: 0;
        }
      }
    }

    &.section-bg {

      padding: 80px 0;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      color: $white;

      &::after {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba($black, 0.3);
      }

      .container {

        position: relative;
        z-index: 2;
      }

      h2,
      h5,
      p {

        text-shadow: 0 0 12px rgba($black, 0.7);
      }

      h2 {

        margin-bottom: 0;
      }

      h5 {

        margin-bottom: 20px;
      }

      p {

        font-size: rem(20px);
      }

      @include media-breakpoint-down(sm) {
        padding: 60px 0;
      }
    }
  }

  .country-nav {

    font-size: rem(28px);

    ul {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    li {

      list-style: none;

      a,
      button {

        display: block;
        margin: 5px 20px;
        color: $white;
        font-weight: 700;

        &:hover {

          color: $yellow;
        }
      }
    }

    @include media-breakpoint-down(md) {

      font-size: rem(23px);

      li {

        flex-grow: 1;
        flex-basis: 33.333%;
      }
    }

    @include media-breakpoint-down(sm) {

      font-size: rem(18px);
    }

    @include media-breakpoint-down(xs) {

      li {

        flex-grow: 1;
        flex-basis: 50%;

        a,
        button {

          margin: 5px 5px;
        }
      }
    }
  }

  .button-border {

    position: relative;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    .btn {
      
      position: relative;
      font-weight: 700;
      color: $default-color;
      background: $yellow;
      border: none;
      z-index: 2;
      @include transition(all 0.5s ease);

      &:hover {

        background: $black;
        color: $yellow;
      }
    }

    &:before {

      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      border-top: 1px solid $gray-150;
      z-index: 0;
    }

    @include media-breakpoint-down(sm) {

      .btn {

        font-size: rem(16px);
      }
    }
    
  }

  .slide-content {

    font-size: rem(20px);
    padding: 70px 0;
    padding-top: 150px;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: 50px 100% !important;

    .container {

      position: relative;
      z-index: 2;
    }

    &:after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.8);
    }

    @include media-breakpoint-down(lg) {

      background-position: 0 100% !important;
    }

    @include media-breakpoint-down(md) {

      padding-top: 50px;
      padding-bottom: 100px;
      background-size: cover !important
    }

  }

  .swiper-slide {

    .col-left,
    .sec-left-bottom,
    .sec-left-top {

      @include transition(all 1.2s ease);
      opacity: 0;
      @include transform(translateX(-200px));
    }

    .col-right,
    .sec-right-bottom {

      @include transition(all 1.2s ease);
      opacity: 0;
      @include transform(translateX(200px));
    }

    &.swiper-slide-active {

      .col-left,
      .sec-left-bottom,
      .sec-left-top {

        opacity: 1;
        @include transform(translateX(0));
      }

      .col-right,
      .sec-right-bottom {

        opacity: 1;
        @include transform(translateX(0));
      }
    }

    @include media-breakpoint-down(md) {

      .col-left,
      .sec-left-bottom {

        margin-bottom: 20px;
      }
    }
  }

  .section-stripes {
    
    padding: 0;
    margin-top: 100px;
    margin-bottom: 50px;

    &::after  {

      position: absolute;
      top: 0;
      right: 0;
      width: 64%;
      height: 100%;
      content: '';
      background: $yellow;
    }

    &::before {

      position: absolute;
      top: 290px;
      right: 0;
      width: calc(64% - 20px);
      height: calc(100% - 290px);
      content: '';
      background: $gray-900;
      z-index: 1;
    }

    .swiper-holder-2 {

      z-index: 3;
    }

    .slide-content-stripe {

      position: relative;
      z-index: 2;
      padding-bottom: 50px;

      .sec-left-top {
  
        width: 55%;
        min-height: 200px;
        text-align: right;
        padding-top: 50px;
        padding-bottom: 40px;
  
        h6 {
  
          width: 100%;
          max-width: 200px;
          margin-left: auto;
          font-weight: 300;
          font-style: italic;
        }
      }
  
      .sec-right-top {
  
        padding-top: 50px;
        padding-bottom: 50px;
      }
  
      .sec-right-bottom {
  
        padding-top: 100px;
        padding-right: 40px;
        color: $white;
  
        h6,
        strong {
  
          font-weight: 700;
        }
      }
    }

    @include break-until(1500px) {

      &::after  {

        width: 71%;
      }
  
      &::before {
  
        width: calc(71% - 20px);
      }
    }

    @include media-breakpoint-down(md) {

      margin-top: 0;
      margin-bottom: 0;

      &::after {

        width: 100%;
      }

      &::before {

        width: 100%;
        height: 100%;
        top: 0;
      }

      .slide-content-stripe {

        .sec-right-top {
  
          width: calc(100% + 30px);
          margin-left: -15px;
          margin-right: -15px;
          padding-left: 15px;
          padding-right: 15px;
          background: $yellow;
        }

        .sec-right-bottom {

          padding-top: 50px;
          padding-bottom: 20px;
          padding-right: 0;
        }

        .sec-left {

          display: flex;
          flex-direction: column;

          .sec-left-top {

            order: 2;
            padding: 20px 15px;
            width: 100%;
            min-height: initial;
            color: $white;
            text-align: center;

            h6 {

              max-width: 100%;
            }
          }

          .sec-left-bottom {

            order: 1;
          }

        }
      }
    }
  }

  .col-black-box {

    display: flex;
    position: relative;
    padding: 50px;
    padding-right: 0;
    height: 100%;

    img {

      position: relative;
      z-index: 2;
      margin-top: auto;
      margin-left: auto;
      @include transition(all 0.5s ease);

    }

    &:hover {

      img {
        @include transform(translateX(-25px));
      }
    }

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 50px);
      height: 100%;
      content: '';
      background: $default-color;
    }

    @include media-breakpoint-down(md) {

      margin-top: 30px;

      &:hover {

        img {
          @include transform(translateX(0));
        }
      }
    }

    @include media-breakpoint-down(sm) {

      padding: 20px;
      padding-right: 0;

      &::after {

        width: calc(100% - 20px);
      }
    }
  }

  .form {

    form {

      .needsclick {
  
        .needsclick {

          &:first-child {

            max-width: 100%;  
          }

          max-width: 450px;
          border-radius: 8px !important;

          &[role="option"] {
            
            border-radius: 0 !important;  
          } 

          p {

            text-align: left !important;
          }

        }

        input,
        select {

          font-size: 14px !important;
          border-radius: 8px !important;
        }
      }
    }

    .klaviyo-form.klaviyo-form.klaviyo-form {

      padding: 0 !important;
    }

    button.kl-private-reset-css-Xuajs1 {

      max-width: 200px !important;
      border-radius: 8px !important;
    }

    @include media-breakpoint-down(md) {

      form {

        .needsclick {
    
          .needsclick {
  
            &:first-child {
  
              max-width: 100%;  
            }
  
            max-width: 100%;
          }
        }
      }
    }
  }

  .team-cards {

    display: flex;
    flex-wrap: wrap;
    margin-left: - 20px;
    align-items: flex-start;
    height: 412px;

    .card {

      max-width: 320px;
      background: $light-gray;
      border: none;
      padding: 15px;
      border-radius: 15px;
      flex-grow: 1;
      margin-left: 20px;
      flex-basis: calc(33.333% - 20px);
      cursor: default;
      height: initial !important;

      .card-img-circle {

        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 20px;

        img {

          border-radius: inherit;
        }
      }

      .card-content {

        font-size: rem(14px);

        p {

          position: relative;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 4;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin-bottom: 0;
        }
        
      }

      .card-footer {

        padding-top: 20px !important;
        margin-top: auto;
        background: none;
        padding: 0;
        border: none;

        strong {
          
          font-size: inherit;
          font-weight: 700;
        }
      }

      &:hover {

        .card-content {

          p {

            overflow: visible;
            line-clamp: initial;
            -webkit-line-clamp: initial;
          }
          
        }
      }
    }

    @include media-breakpoint-down(md) {

      height: 460px;

      .card {

        height: 100%;
        margin-top: 20px;

        &:hover {

          height: auto;

          .card-content {
  
            line-clamp: initial;
            -webkit-line-clamp: initial;
            
          }
        }

      }
    }

    @include media-breakpoint-down(sm) {

      height: 400px;
    }

    @include media-breakpoint-down(xs) {

      height: auto;
      align-items: initial;
      justify-content: center;

      .card {

        height: auto;
        flex-basis: calc(50% - 20px);
        max-width: calc(50% - 20px);
      }

      &:hover {

        align-items: flex-start;
      }
    }
  }

  .filter-nav {

    ul {

      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      justify-content: center;

      li {
        margin-left: 20px;
        margin-top: 15px;
        list-style: none;
        max-width: calc(20% - 20px);
        flex-basis: calc(20% - 20px);
      }
    }

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(20px);
      font-weight: 700;
      line-height: 20px;
      height: 100%;
      padding: 10px 20px;
      color: $default-color;
      background: $yellow;
      border-radius: 30px;
      text-align: center;      

      &:hover {

        background: $black;
        color: $yellow;
      }
    }

    @include media-breakpoint-down(md) {

      ul {
  
        li {
          height: 60px;
          max-width: calc(33.333% - 20px);
          flex-basis: calc(33.333% - 20px);

          a {

            font-size: rem(23px);
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {

      ul {
  
        li {
          height: 50px;
          max-width: calc(50% - 20px);
          flex-basis: calc(50% - 20px);

          a {

            font-size: rem(16px);
            line-height: 1;
          }
        }
      }
    }
  }
}

.swiper-holder-2 {

  .swiper-pagination-bullet {

    background: $yellow;
    margin-left: 15px !important;
    margin-right: 15px !important;
    opacity: 1;

    &.swiper-pagination-bullet-active {

      box-shadow: 0 0 0 5px $lblue;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {

    &.swiper-button-disabled {

      opacity: 0;
    }

    color: $white;
    width: 25px;
    height: 25px;

    &::after {
      
      font-size: 25px;
    }
  }

  .swiper-pagination-top {

    position: absolute;
    top: 100px;
    left: 50%;
    max-width: 1350px;
    width: 100%;
    text-align: center;
    @include transform(translateX(-50%));
    z-index: 2;

    .swiper-pagination {

      position: relative;
      max-width: 50%;
      margin-left: auto;
      padding-left: 3rem;

    }

    @include media-breakpoint-down(md) {

      top: auto;
      bottom: 30px;
      left: 0;
      @include transform(translateX(0));

      .swiper-pagination {

        max-width: 100%;
        padding-left: 0;
  
      }
    }

  }

  .swiper-pagination-mid {

    position: absolute;
    top: 330px;
    left: 50%;
    max-width: 1350px;
    width: 100%;
    text-align: center;
    @include transform(translateX(-50%));
    z-index: 2;

    .swiper-pagination {

      position: relative;
      margin-left: auto;
      padding-left: 3rem;
      width: 41.66667%;
      margin-right: 9%;

    }

    @include media-breakpoint-down(md) {

      top: auto;
      bottom: 30px;
      left: 0;
      max-width: 100%;
      @include transform(translateX(0));

      .swiper-pagination {

        padding-left: 0;
        width: 100%;
        margin-right: 0;
  
      }
    }
  }

  .swiper-button-b-left {

    position: absolute;
    width: 100%;
    max-width: 1350px;
    bottom: 65px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 2;

    .swiper-button-inner {

      position: relative;
      max-width: calc(41.66667% - 5rem);
      margin-left: calc(8.33333% + 2.5rem);
      margin-right: 2.5rem;
      height: 25px;
    }

    @include media-breakpoint-down(lg) {

      bottom: 25px;

      .swiper-button-inner {

        max-width: calc(41.66667%);
        margin-left: calc(8.33333%);
        margin-right: 0;
      }
    }

    @include media-breakpoint-down(md) {

      top: auto;
      bottom: 40%;
      left: 0;
      @include transform(translateX(0));

      .swiper-button-inner {

        display: none;

        max-width: 100%;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    
  }

  .swiper-button-b-right {

    position: absolute;
    width: 100%;
    max-width: 1350px;
    bottom: 50px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 2;

    .swiper-button-inner {

      position: relative;
      max-width: 41.66667%;
      height: 25px;
      margin-left: auto;
      margin-right: 8.33333%;
    }

    @include media-breakpoint-down(lg) {

      bottom: 20px;
    }

    @include media-breakpoint-down(md) {

      max-width: 100%;
      bottom: auto;
      top: calc(50% + 100px);
      left: 0;
      @include transform(translateX(0));

      .swiper-button-inner {

        display: none;

        max-width: 100%;
        margin: 0 10px;

      }
    }
    
  }

}
