.featured-banner {

  position: relative;
  min-height: 569px;

  @include media-breakpoint-up(lg) {

    max-height: 80vh;
  }

  &::after {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background: rgba($black, 0.4);
  }

  > img {

    min-height: 569px;
  }

  @include media-breakpoint-down(sm) {

    min-height: 58px;

    > img {

      min-height: 680px;
    }
  }

  @include media-breakpoint-down(xs) {

    > img {

      min-height: 720px;
    }

    .card {

      &.card-blog {

        .card-img, 
        img {

          height: 210px !important;
        }
      }
    }
  }

  .featured-banner-inner {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    
    .featured-flex {

      display: flex;
      align-items: center;
      height: 100%;
      max-width: 1350px;
    }

    .featured-title {

      color: $white;
      min-width: 50%;

      h1 {
        
        font-size: rem(60px);
        font-weight: 200;
      }
    }

    .featured-carousel {

      min-width: 50%;
    }

    @include media-breakpoint-up(md) {

      .card {

        &.card-blog {

          .card-body {

            .card-content {

              h3 {

                font-size: rem(14px !important);
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {

      .featured-title {
  
        h1 {
          
          font-size: rem(30px);
        }
      }
    }

    @include media-breakpoint-down(sm) {

      .featured-title {

        text-align: center;
  
        h1 {
          
          font-size: rem(30px);
        }
      }

      .featured-flex {

        flex-direction: column;
        justify-content: center;
      }

      .featured-carousel {

        max-width: 400px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .swiper-holder-3 {

    border-top: 0;

    .swiper-slide {

      max-width: 420px;

      .card-blog {

        @include transition(all 0.3s ease);
        @include transform(scale(0.88));
      }

      &.swiper-slide-active,
      &.swiper-slide-prev {

        .card-blog {

          @include transform(scale(1));
        }
      }

      &:first-child {

        margin-left: 0;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {

      width: 50px;
      height: 50px;
      right: 10px;
    }

    .swiper-button-prev {

      right: auto;
      left: 0;
    }

    @include media-breakpoint-down(lg) {

      .swiper-button-prev {

        left: 15px;
      }

      .swiper-button-next {

        right: px;
      }
    }

    @include media-breakpoint-down(md) {

      .swiper-button-next {

        right: 15px;
      }
    }

    @include media-breakpoint-down(sm) {

      .swiper-button-prev,
      .swiper-button-next {

        display: block;
      }

    }

    @include media-breakpoint-down(xs) {

      .swiper-button-prev {

        left: -10px;
      }

      .swiper-button-next {
        right: -10px;
      }

    }
  }

  .card {

    &.card-blog {

      .card-body {

        .card-body-flex {

          flex-direction: column;
          align-items: flex-start;

          .card-content {

            max-width: 75%;

            @include media-breakpoint-down(sm) {

              max-width: 100%;
            }
          }
        }
      }
    }

  }
}