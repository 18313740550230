.mini-search-header {

  align-items: center !important;

  .logo-mini {

    display: none;
    margin-top: -4px;
    width: 54px;
    height: 44px;
  }

  .branding {

    flex-grow: initial;
    margin-right: 15px;
  }

  &.show {

    .logo-mini {

      display: block;
    }

    .logo {

      display: none;
    }

    .mini-search {

      display: flex;
    }
  }

  @include break-until(1054px) {

    .logo-mini {

      display: none !important;
    }
  
    .logo {
  
      display: block !important;
      width: 120px !important;
    }
  }
}


.mini-search {

  display: none;
  font-size: rem(12px);
  padding: 8px 0;
  border-radius: 30px;
  background: $white;
  width: 260px;
  box-shadow: 0 0 5px 1px rgba($gray-900, .5);
  cursor: pointer;

  .col-mini {

    display: flex;
    align-items: center;
    padding: 0 11px;
    border-left: 1px solid $gray-150;

    span {

      display: block;
      position: relative;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.2;
    }

    &:first-child {

      flex-grow: 1;
      border-left: 0;
      max-width: 163px;
      padding-left: 25px;
    }
  }

  @include break-until(1054px) {

    display: none !important;
  }
}

.bookig-search-holder {

  &.fixed {
    
    position: fixed;
    top: 79px;
    z-index: 999;
    background: $white;
    bottom: auto;
    max-width: 100%;
    padding: 20px;

    .booking-search {

      position: relative;
      max-width: 890px;
      width: 100%;
      margin: 0 auto;
      z-index: 5;
      opacity: 1;
    }

    .booking-search-title {

      display: none;
    }

    @include break-until(1300px) {

      top: 62px;
    }
  }
}

.content-destination .entry-wrap ul#navscroll,
.navscroll-big {

  @include transition(all 0.3s ease);

  &.adjust {

    top: 12.4rem;

    @include break-until(1300px) {

      top: 11.3rem;
    }
  }
}