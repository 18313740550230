.fslightbox-container {

  background: rgba($black,.8) !important;

  .fslightbox-slide-number-container {

    font-size: rem(14px);
    z-index: 1;
    max-width: 100%;
    width: 100%;
    margin-top: 15px;
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 5px rgba($black, 0.5);
    
    .fslightbox-slash {

      margin: 0 8px;
      height: 13px;
      width: 2px;
      box-shadow: 1px 1px 5px rgba($black, 0.5);
    }
  }

  .fslightbox-toolbar {

    background: none;
    z-index: 2;

    .fslightbox-toolbar-button {

      width: 35px;
      height: 35px;
      margin: 20px;
      background: $white;
      color: $default-color;
      border-radius: 50%;
      box-shadow: 0 0 10px 1px rgba($default-color, 0.4);

      &:first-child {

        display: none;
      }

      svg {

        width: 15px;
        height: 15px;
      }

      .fslightbox-svg-path {
        fill: $default-color;
      }
    }
  }

  .fslightbox-slide-btn-container {

    &.fslightbox-slide-btn-container-previous {

      left: 20px;
    }

    &.fslightbox-slide-btn-container-next {

      right: 20px;
    }

    .fslightbox-slide-btn {

      font-size: rem(20px);
      width: 35px;
      height: 35px;
      padding: 9px;
      border-radius: 50%;
      background: $white;
      box-shadow: 0 0 10px 1px rgba(18,18,18,.4);
        
      svg {

        .fslightbox-svg-path {
          
          fill: $default-color;
        }
      }
    }

    @include media-breakpoint-down(sm) {

      &.fslightbox-slide-btn-container-previous {

        left: 5px;
      }
  
      &.fslightbox-slide-btn-container-next {
  
        right: 5px;
      }
    }
  }

  > .fslightbox-full-dimension {

    pointer-events: none;

    .fslightbox-fade-in,
    .fslightbox-fade-out,
    .fslightbox-fade-in-strong {
      // animation: initial-animation 0s ease !important;
      // animation: initial-animation 0.3s ease !important;

      .fslightbox-source {

        @include transition(all 0.5s ease);    
        // animation: initial-animation 0s ease !important;
        // animation: initial-animation 0.3s ease !important;
      }
    }
  }  

  .fslightbox-full-dimension {

    overflow: hidden;
  }

  .fslightbox-bg {

    position: absolute;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;

    bottom: -10em;
    filter: blur(25px) saturate(175%) brightness(90%);
    left: -10em;
    position: absolute;
    right: -10em;
    top: -10em;
  }
}

.custom-dialog {

  max-width: 560px;

  &.modal-lg {

    max-width: 830px;
  }

  .modal-content {

    padding: 10px 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0 0 7px 1px rgba($black,.3);
    border: none;
  }

  .modal-header {

    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {

    padding-top: 0;
  }

  .close {

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: -35px;
    background: $white;
    border-radius: 50%;
    opacity: 1;
    box-shadow: 0 0 7px 1px rgba($black,.5);
    z-index: 22;

    img {

      width: 13px;
      height: 14px;
    }

    @include media-breakpoint-down(md) {

      top: 10px;
      right: 10px;
    }
  }

  @include media-breakpoint-down(sm) {

    .modal-content {

      padding: 0;
    }
  }
}

.rooms-dialog {

  max-width: 500px;

  .card {

    overflow: visible !important;
    
    .card-img {

      height: auto;
      min-height: 300px;

      .swiper-slide {

        img {

          height: auto;
          @include transform(scale(1) !important);
          width: calc(100% + 1px);
  
          height: 364px;
        }
      }

      .book-room {

        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 2;

        .btn {

          font-weight: 700;
          padding: 9px 17px;
          border-radius: 5px;
          box-shadow: 0 0 7px 1px rgba($black, 0.3);
        }
      }

      @include media-breakpoint-down(sm) {

        min-height: 230px;

        .book-room {

          .btn {

            font-size: rem(12px);
          }
        }

        .swiper-slide {

          img {
    
            height: 230px;
          }
        }
      }
    }

    .card-body {

      h4 {

        font-size: rem(20px);
      }

      p {

        display: block;
        line-clamp: initial !important;
        -webkit-line-clamp: initial !important;
        -webkit-box-orient: initial !important;
        overflow: visible;
      }

      span {

        display: block;
        font-size: rem(11px);
        margin-bottom: 10px;
      }

      .cancellation {

        font-size: rem(10px);
        color: $green-15;

        #cancellation-desc p {

          display: block;
        }
      }

      .card-body-flex {

        align-items: flex-end;
        flex-direction: row !important;
        padding-top: 8px !important;

        .promo-text {

          color: $green-45;
          font-weight: 700;
          margin-bottom: 0;
        }

        .room-price {

          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-left: auto;
          flex-shrink: 0;

          em {

            font-size: rem(11px);
            margin-right: 5px;
          }

          .room-rates {

            
            span {
              display: block;
              font-size: rem(16px);
              font-weight: 700;
              margin-bottom: 0;
              line-height: normal;

              &.st-line {

                position: relative;

                &::after {

                  position: absolute;
                  top: 50%;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  content: '';
                  border-top: 2px solid $red;
                  z-index: 2;
                }
              }

              small {

                font-size: rem(11px);
                font-weight: normal;
                margin-right: 1px;
              }
            }

            @include media-breakpoint-down(sm) {

              span {
  
                font-size: rem(13px);
              }
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {

        h4 {

          font-size: rem(16px);
        }
      }
    }
  }

  .swiper-wrapper {

    height: auto !important;
  }

  .swiper-slide {

    width: 100% !important;
  }

  .swiper-button-icon {
    
    img {

      width: 45px;
      height: 45px;
    }
  }
}

@keyframes  initial-animation {
	0% {
		opacity: 0 !important;
	}

	100% {
		opacity: 1 !important;
	}
}

.campaign-dialog {

  .card {

    .card-img {

      height: auto;
      max-height: 600px;
      
      img {

        @include transform(scale(1) !important);
        height: auto;
        max-height: 600px;
      }

      .btn {

        position: absolute;
        bottom: 20px;
        right: 20px;
        text-transform: uppercase;
        border-radius: 30px;
      }

      @include media-breakpoint-down(sm) {

        .btn {

          font-size: rem(12px);
          bottom: 15px;
          right: 10px;
        }
      }
    }

    .card-body {

      padding-bottom: 20px;

      h4 {

        font-size: rem(20px);
        margin-bottom: 20px;
      }

      span,p {

        display: block !important;
        padding-right: 170px;
      }
      

      .btn {

        margin-left: auto;
        flex-shrink: 0;
        background: $yellow;
        color: $black;
        padding: 7px 15px;
        text-transform: uppercase;
        margin-top: -40px;
        width: 145px;
      }

      @include media-breakpoint-down(sm) {

        span,p {

          padding-right: 0 !important;
        }

        .btn {

          font-size: rem(12px) !important;
          padding: 7px 15px !important;
          opacity: 1 !important;
          margin-left: auto !important;
          margin-top: 15px !important;
          width: 124px;
        }
      }
    }
  }
}

.modal-plain {

  border-radius: 10px;

  h4 {

    font-size: rem(30px);
  }

  .modal-header {

    padding-bottom: 0;
    border: none;
  }

  .modal-body {

    padding-top: 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  .modal-footer {
    
    padding: 20px;
    padding-top: 0;
    border: none;
  }

  .btn {

    font-weight: bold;
  }

  @include media-breakpoint-down(xs) {

    .modal-body {
      
      padding-left: 20px;
      padding-right: 20px;
    } 
  }
}

.deals-modal {

  .modal-body {
    
    padding: 50px;
    padding-top: 0 !important;

    @include media-breakpoint-down(sm) {

      padding: 15px;
    }
  }
}