.grid-flex {

  display: flex;

  .col-border {

    flex-basis: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include media-breakpoint-down(md) {

    flex-direction: column;
    align-items: center;

    .col-border {

      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-info {

  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 50%;

  &.is-plain {

    &:first-child {

      padding-left: 0;
    }

    &:last-child {

      padding-right: 0;
    }

    .grid-body {

      h4 {

        padding-bottom: 0;

        &::after {

          display: none;
        }
      }

      li {

        margin-bottom: 5px;
        margin-left: 13px;
        list-style: outside;
      }
    }
  }

  .grid-icon {

    width: auto;
    height: 83px;
    margin-bottom: 20px;

    img {

      width: auto !important;
      height: 83px !important;
    }
  }

  .grid-body {

    h4 {

      position: relative;
      font-size: rem(18px);
      padding-bottom: 15px;
      margin-bottom: 20px;

      &:after {

        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        @include transform(translateX(-50%));
        content: '';
        width: 49px;
        height: 5px;
        border-top: 5px solid $default-color;
      }
    }

    ul {

      padding: 0;
      list-style-position: inside;

      li {

        &::marker {

          font-size: rem(9px);
          margin-right: 5px;
          margin-top: -5px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {

    padding-left: 0 !important;
    padding-right: 0 !important;
    flex-basis: 100%;
  }
}

.col-border {

  border-left: 2px solid $light-gray;
  max-width: 450px;

  &:first-child {

    border-left: 0;
  }

  @include media-breakpoint-down(md) {

    border-left: 0;
    margin-bottom: 20px;
  }
  
}

.grid-plain {

  font-size: rem(20px);

  img {

    margin-bottom: 30px;
  }
  
  h4 {

    font-size: rem(20px);
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {

    font-size: rem(14px);

    img {
      
      max-width: 75px;
    }

    h4 {

      font-size: rem(16px);
    }
  }

}

.grid-square {

  display: flex;
  flex-direction: column;
  font-size: rem(21px);
  position: relative;
  padding: 20px 15px;
  padding-top: 30px;
  border-radius: 20px;
  text-align: center;
  border: 1px solid $red-500;
  color: $white;
  height: 100%;

  &.is-green {
    
    border-color: $green-60;
  }

  &.is-maxwidth {

    max-width: 390px;

    small {

      font-size: rem(16px);
    }
  }

  span {

    display: block;

    &.lead {

      font-size: rem(40px);
      font-weight: 300;
    }
  }

  small {

    font-size: rem(13px);
    line-height: 1.1;
  }
  

  .btn {

    font-size: rem(18px);
    font-weight: 700;
    padding: 7px 20px;
  }

  .badge {

    position: absolute;
    top: 0;
    left: 20px;
    font-size: rem(13px);
    font-weight: normal;
    padding: 6px 13px;
    border: 1px solid $gray-150;
    background: $black;
    color: $yellow;
    margin-top: 0 !important;
    @include transform(translateY(-50%));

  }

  .grid-square-inner {

    position: relative;

    @include media-breakpoint-up(xl) {

      padding-left: 15px;
      padding-right: 15px;
    }

    & + .grid-square-inner {

      &::before {

        display: block;
        content: '';
        width: 70%;
        height: 1px;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 25px;
        border-top: 1px solid $gray-150;
      }
      
    }
  }

  @include media-breakpoint-down(sm) {

    font-size: rem(20px);

    span {

      &.lead {
        
        font-size: rem(35px);
      }
    }
  }
}

.mt-negative {

  margin-top: -90px;

  @include media-breakpoint-down(lg) {

    margin-top: -50px;
  }

  @include media-breakpoint-down(md) {

    margin-top: 30px;
  }
}