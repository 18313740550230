.alert {

  position: fixed;
  left: 50%;
  top: 20px;
  @include transform(translateX(-50%));
  width: calc(100% - 30px);
  max-width: 1360px;
  z-index: 1026;

  a {

    color: inherit;
    text-decoration: underline;
  }

  .close {

    top: 50%;
    @include transform(translateY(-50%));
  }
}
