// ==========================================================================
// Skeleton loading Styles
// ==========================================================================

.skeleton,
.skk-loading,
.skeleton select::before {
    color: transparent !important;
    border: none !important;
    border-radius: 8px !important;
    -webkit-animation: skeleton-loading 1s linear infinite alternate;
        animation: skeleton-loading 1s linear infinite alternate;
}

.sk-loader {

    &.skeleton {

        display: inline-block !important;
    }
}
  
@-webkit-keyframes skeleton-loading {
0% {
    background-color: #eff0f1;
}
100% {
    background-color: #f6f7f8;
}
}

@keyframes skeleton-loading {
0% {
    background-color: #eff0f1;
}
100% {
    background-color: #f6f7f8;
}
}

.skeleton .hide-text a {
    visibility: hidden;
}

.skeleton {

    * {

        visibility: hidden !important;
    }
}

.skeleton .hide-text,
.skeleton .hide-text a,
.skeleton .hide-text button,
.skeleton .hide-text input,
.skeleton.select {
    background: #f6f7f8;
    color: transparent !important;
    position: relative;
    overflow: hidden;    
    height: 30px;
    padding: 0 !important;
    width: 100%;
}

@include break-from ( medium ) {
    .count-wrapper.skeleton {
        margin-top: -40px;
    }
    .count-wrapper.skeleton .hide-text,
    .select.skeleton {
        width: 68px;
    }
}

@include break-until ( small ) {
    .select.skeleton {
        height: auto;
        #qty,
        .qty {
            visibility: hidden !important;
        }
    }
}

@include media-breakpoint-down(xs) {
    .skeleton.select {
        width: 66px;
    }
}
.price.skeleton,
.cancellation.skeleton,
.total.skeleton,
.skeleton small,
.skeleton small span,
.mm-orders.skeleton,
.mm-amount.skeleton {
    color: transparent !important;
}

.cancellation.skeleton img,
.skeleton small svg,
.closed.skeleton svg {
    display: none !important;
}

.skeleton #promo-code,
.skeleton #apply-code {
    visibility: hidden !important;
}

.total .skeleton {
    display: inline-block !important;
}

@keyframes gradient-animation {
    from {
        left: 0%;
    }

    to {
        left: 100%;
    }
}

@keyframes shimmer {
    0% {
        background-position: -450px 0px;
    }

    100% {
        background-position: 450px 0px;
    }
}

.hide-text .cancellation.skeleton  {
    margin-top: 5px;
}

.skeleton-2 {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .skeleton-loading-img {

        position: relative;
        overflow: hidden;
        max-width: 65%;
        flex-grow: 1;
        background: $gray-250;

        @include animation('shine 1s linear infinite');
    }

    .skeleton-loading-text {
        
        padding: 30px 20px;
        max-width: 35%;
        flex-grow: 1;

        .line {

            position: relative;
            overflow: hidden;
            height: 13px;
            margin-bottom: 12px;
            background: $gray-200;
            border-radius: 3px;

            &.h-30 {

                height: 30px;
            }

            @include animation('shine 1s linear infinite');
        }
    }
}

.skeleton-img {

    position: relative;
    overflow: hidden;
    background: $gray-50 !important;

    img {

        position: relative;
        z-index: 2;
    }

    &::after {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.7;
        content: '';
        @include transform(translateX(-100%));
        @include linear-gradient(90deg, rgba($white, 0) 0, rgba($white, 0.3) 20%, rgba($white, 0.4) 60%, rgba($white, 0));
        @include animation(shimmer 2s cubic-bezier(.4,0,.6,1) infinite);
    }

}

@include keyframes(shine) {
  0% { background: $gray-200; }
  50% { background: $gray; }
  100% { background: $gray-200; }
}

@include keyframes(shimmer) {
   100%{ 
    @include transform(translateX(100%));
   }
  }

@include keyframes(sk-loading) {
    
    0%, 100% {
    opacity: 1;
    }
    50% {
    opacity: .5;
    }
        
}

@include keyframes(sk-loading-dark) {
    
    0% {
        background-color: lighten($gray-200, 10%);
    }
    100% {
        background-color: lighten($gray-250, 10%);
    }
        
}

.skeleton {

    a {

        &::after {
    
            opacity: 0;
        }
    }

    i {
        opacity: 0;
    }
}

.skeleton-rooms,
.skeleton-cart,
.card-list {

    .sk-loading {

        display: block;
        width: 50%;
        height: 19px !important;
        background: $light-gray;
        margin: 8px 0;
        border-radius: 20px !important;
        @include animation(skeleton-loading 1s linear infinite alternate);

        &.dark {

            margin: 5px 0;
            background: $gray-200;
            @include animation(sk-loading-dark 1s linear infinite alternate);
        }

        &.circle {

            display: none;
        }

        &.lg {

            height: 30px;
        }

        &.w-8 {

            width: 80px;
        }

        &.w-10 {

            width: 100px;
        }

        &.w-40 {

            width: 40%;
        }

        &.w-30 {

            width: 30%;
        }

        &.w-50 {

            width: 50%;
        }

        &.w-60 {

            width: 60%;
        }

        &.w-70 {

            width: 70%;
        }

        &.w-80 {

            width: 80%;
        }

        &.w-90 {

            width: 90%;
        }
    }

    .room-bottom {

        flex-direction: row !important;
        align-items: flex-end;
        justify-content: flex-end;
    }

    @include break-until(medium) {

        .item-book {

            display: none !important;
        }

        .sk-loading {

            &.circle {

                display: block;
            }
        }
    }
}

@include break-until(medium) {

    .cart-information {

        &.cart-skeleton {
            
            .head {

                .sk-loading {
                    display: block !important;
                    height: 11px !important;

                    &.circle {

                        position: absolute;
                        top: 44%;
                        right: 15px;
                        display: block;
                        width: 25px;
                        height: 25px !important;
                        border-radius: 50%;
                        @include transform(translateY(-50%));
                    }
                }
            }
        }
    }
}

.sk-loader {

    .item {

        @extend .skeleton;
        border-radius: 20px !important;

        .hide-text {

            a {
    
                height: auto !important;
                padding: 7px 20px !important;
            }
        }

    }
}

.skk-loading {

    height: 25px;
    width: 90px;

    @include media-breakpoint-down(xs) {

        width: 60px;
    }
}

