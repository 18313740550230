// ==========================================================================
// Woocommerce mobile Styles
// ==========================================================================

.woocommerce {    

    p {
        a {
            &.btn {
                display: none;

                @include break-from( medium ) {     
                    display: inline-block;
                }
            }
            
            &.ticket-item {

                display: inline-block;
            }
        }
    }

    table {
        &.shop_table_responsive {
            tbody {
                tr {
                    td {
                        @include break-until( medium ) {
                            text-align: left !important;
                        }
                    }
                } 
            }            
        } 
    }

    .mobile-booking {        
        position: fixed;
        width: 100%;
        bottom: 0;  
        left: 0;      
        z-index: 10;

        @include break-from( medium ) {
            display: none;
        }

        .btn {
            &.btn-third {
                border-radius: 0;
                width: 100%;
                color: $white;
                font-weight: 500;

                &:hover {
                    background: $primary;
                    color: $black;
                }
            }
        }
    }
}
