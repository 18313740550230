.creative-section {

  position: relative;
  padding-top: 60px;

  &:last-child {

    padding-bottom: 60px;
  }

  h2 {

    font-size: rem(40px);
    margin-bottom: 30px;
  }

  h3 {

    font-family: $font-family-base;
  }

  .container {

    max-width: 1350px;
    width: 100%;
  }

  &.section-process {

    .process {

      .process-grid {

        max-width: 230px;

        strong, span {

          display: block;

          em {

            background: $white;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    h2 {

      font-size: rem(30px);
    }
  } 

}

.flex-imgs {

  display: flex;

  .flex-img {

    position: relative;
    flex-basis: 50%;
  }

  @include media-breakpoint-down(sm) {

    flex-wrap: wrap;

    .flex-img {

      width: 50%;
    }
  }
}

.section-highlight {

  background: $light-gray-3;
}

.kl-form {

  display: inline-block;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px rgba($dark-gray, 0.8);
  max-width: 100%;

  .klaviyo-form-RrKkQ8 {

    .needsclick {

      > .needsclick {

        button {

          margin-left: 10px;
          margin-right: 10px;
          border-radius: 30px !important;
          font-size: rem(18px !important);
        }
      }
    }

    input {

      border-radius: 8px !important;
    }
  }

  @include media-breakpoint-down(sm) {

    padding: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

.kl-form-sm {

  max-width: 430px;
  margin: 0 auto;

  form {

    &.klaviyo-form {

      float: none !important;
      clear: both !important;
      padding-bottom: 0 !important;
    }
  }

  input {

    width: 100% !important;
    border-radius: 8px !important;
  }
}