.experience-hero-banner {

  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 65vh;
  color: $white;

  + .slogan {

    margin-top: 0 !important;
  }

  &.fullscreen-banner {

    max-height: 100vh;

    .img-objectfit {

      max-height: 100vh;
    }
  }

  &.overlay {

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.4);
    }

    .exp-hero-content {

      z-index: 2;
    }

    .container {

      padding-top: 70px;
      max-width: 1080px;
      width: 100%;
    }
  }

  .img-objectfit {

    min-height: 500px;
    max-height: 65vh;
    object-position: 64% 50%;
  }

  .exp-hero-content {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 0;
    text-align: center;

    .booking-search {

      opacity: 0;
      z-index: -1;

      .container {

        max-width: 890px;
        padding-top: 0.75rem;
      }

      &.fixed {

        opacity: 1;
        z-index: 999;
      }
    }

    h1 {

      font-size: rem(80px);
      text-shadow: 0 0 12px rgba($black, 0.7);
    }

    h3 {

      font-family: $font-family-base;
      font-size: rem(45px);
      font-weight: 300;
      text-transform: uppercase;
      text-shadow: 0 0 12px rgba($black, 0.7);
    }

    h4 {

      font-family: $font-family-base;
      font-size: rem(30px);
      font-weight: 400;
      text-shadow: 0 0 12px rgba($black, 0.7);
    }

    .container {

      padding-top: 70px;
      width: 100%;
    }

    &.custom-campaign-banner {

      h1 {

        font-size: rem(70px);
        text-shadow: 0 0 12px rgba($black, 0.7);
      }
  
      h3 {
  
        font-size: rem(35px);
        font-weight: 300;
      }
    }
  }

  @include media-breakpoint-down(lg){

    .exp-hero-content {

      padding: 20px 0;

      h1 {
  
        font-size: rem(60px) !important;
      }
  
      h3 {
  
        font-size: rem(35px) !important;
      }

      h4 {
  
        font-size: rem(25px) !important;
      }

      .hero-btns {

        .btn {

          font-size: rem(14px);
        }
      }

    }
  }

  @include media-breakpoint-down(sm){

    min-height: 300px;

    .img-objectfit {
      
      min-height: 300px;
    }

    .exp-hero-content {

      h1 {
  
        font-size: rem(35px !important);
      }
  
      h3, h4 {
  
        font-size: rem(14px) !important;
      }

      .container {

        padding-top: 130px;
      }

      .lead {

        font-size: rem(16px !important);
      }
    }
  }

}

.hero-btns {

  .btn {

    position: relative;
    margin: 5px;
    padding: 10px 30px;
    background: rgba($black, 0.4);
    border-color: rgba($black, 0);
    box-shadow: none !important;

    &:hover,
    &.active {

      color: $white;
      background-color: rgba($black, 0.8) !important;
      border-color: rgba($black, 0.8) !important;
    }

    &.active {

      cursor: default;
      pointer-events: none;

      &:after {

        position: absolute;
        bottom: 6px;
        left: 50%;
        @include transform(translateX(-50%));
        content: '';
        width: 30px;
        height: 2px;
        border-top: 2px solid $gray-150;
        border-radius: 5px;
      }

    }
  }

  @include media-breakpoint-down(sm){

    position: relative;
    margin-bottom: 10px;
    margin-right: 60px;
    overflow: auto;

    .btn {

      font-size: rem(10px);
      padding: 5px 8px;
      margin: 2px;

      &:after {

        display: none;
      }
    }
  }

  @include media-breakpoint-down(xs){

    margin-left: -2px;
  }

}


.experiences-list {

  max-width: 1100px;
  margin: 0 auto;
}

.card-list {

  .cards {

    margin-bottom: 30px;
  }

  @include media-breakpoint-down(md) {

    margin-left: -10px;
    margin-right: -10px;

    .cards {

      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-down(xs) {

    margin-left: -5px;
    margin-right: -5px;

    .cards {

      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }
  }
  
}

.exp-detail {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;  

  &.overlay {
    &::after {
      content: ""; 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(120deg, #fff, #fff);
      opacity: .7;
    }
  }

  .loading {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0; 
    bottom: 0;
    right: 0;
    display: none;
  }

  h2 {

    font-size: rem(40px);
    margin-bottom: 20px;

    + strong {

      display: block;
      margin-bottom: 30px;
    }
  }

  h3 {

    font-size: rem(18px) !important;
    font-weight: 700;
  }


  &.container-sm {

    max-width: 920px;
  }

  .exp-rates,
  .exp-info {

    position: relative;
    margin-top: 40px;
    
    &:before {

      display: block;
      margin-bottom: 40px;
      max-width: 410px;
      height: 1px;
      content: '';
      border-top: 1px solid $gray-250;
    }

    span {

      display: block;

      em {

        font-style: normal;
        font-weight: 700;
        margin-left: 10px;
        color: $green-45;

        a {

          color: $blue;
        }
      }
    }
  }

  .exp-info {

    max-width: 410px;
    
    ul {

      padding-left: 20px;
    }
  }

  .col-lg-8 {

    span {

      + b {

        display: block;
        margin-top: 30px;
      }
    }
  }

  @include media-breakpoint-down(md) {

    padding-top: 50px !important;
  }

  @include media-breakpoint-down(sm) {

    margin-top: 30px !important;
    margin-bottom: 30px;

    h2 {

      font-size: rem(30px);
    }

    .exp-rates,
    .exp-info {
      
      &:before {

        max-width: 100%;
      }

      .exp-info {

        max-width: 100%;
      
      }
    } 
  }
}

.container {

  &.archive {

    position: relative;
  }
}

.currency-right {

  display: inline-block;
  position: absolute;
  top: 44px;
  right: 15px;
  z-index: 5;

  @include media-breakpoint-down(md) {

    top: 30px;
  }

  @include media-breakpoint-down(sm) {

    top: 22px;

    .select2-selection__rendered {

      font-size: rem(14px);
      font-weight: 500;
    }
  }
}

.currency-end {

  display: flex;
  justify-content: flex-end;
}

.navbar {

  &.exp-nav {

    @include media-breakpoint-down(lg) {

      justify-content: flex-start;
      padding-right: 120px;
  
      .menu {
        
        li {

          margin: 0 20px;
        }

        .sub-menu {

          li {
    
            margin: 0;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {

      padding-right: 0;
    }

  }
}

@include media-breakpoint-down(md) {

  .col-initial {

    position: initial;
  }

  .currency-end {
    
    &:not(.no-absolute) {
      position: absolute;
      top: -10px;
      right: 15px;
    }
  }
}

.mad-pic {

  height: calc(100% - 30px);

  @include media-breakpoint-down(md) {
    height: calc(100% - 20px);
  }

  @include media-breakpoint-down(xs) {
    height: calc(100% - 10px);
  }
}

.nav-flex {

  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {

    font-size: rem(20px);
    margin-bottom: 0;

    span {

      font-weight: normal;
    }
  }

  .navbar {

    margin-left: auto;
    padding-left: 20px;

    .menu {

      > li {

        margin: 0 15px;

        &:first-child {

          margin-left: 0;
        }

        &:last-child {

          margin-right: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {

    flex-direction: column;
    
    h4 {

      text-align: center;
    }

    .navbar {

      width: 100%;
      margin-left: 0;
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(sm) {

    flex-direction: column;

    .navbar {

      width: calc(100% + 30px);
      margin-left: -15px;
      
    }
  }
}

.madventure-country-thumb {

  &.slider-holder-overflow {

    overflow: visible;
  }

  .swiper-holder-3 {

    border-top: 0;
    margin-left: -7px;
    margin-right: -7px;

    .swiper-slide {

      &:first-child {

        margin-left: initial !important;

      }
    }

    .swiper-button-next,
    .swiper-button-prev {
  
      width: 50px;
      height: 50px;
      top: 50%;

      @include media-breakpoint-down(md) {

        display: block;
      }
    }
  }

  &.opacity {

    .swiper-wrapper {

      .swiper-slide {

        img {

          opacity: 0.5;
        }
  
        &.active,
        &:hover {
  
          img {
  
            opacity: 1;
          }
        }
  
        &.active {
  
          img {
  
            @include transform(scale(1.1));
          }
  
          a {
  
            // pointer-events: none;
            // cursor: default;
          }
  
        }
      }
    }
  }

  .swiper {

    padding-left: 7px;
    padding-right: 7px;
  }

  .swiper-wrapper {
    
    &.swiper-wrapper-center {

      @include media-breakpoint-up(lg) {

        justify-content: center;
      }
    }
  }

  .swiper-button-next {

    right: -10px;
  }

  .swiper-button-prev {

    left: -10px;
  }

  .swiper-button-disabled {

    opacity: 0;
  }

  .card {

    margin: auto;

    &.card-sm {

      padding-bottom: 100%;
  
      .card-img {
  
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
  
        img {
  
          height: inherit;
        }
  
        .card-img-txt {
  
          h4 {
  
            font-size: rem(24px);
          }

          @include media-breakpoint-down(lg) {

            h4 {
  
              font-size: rem(14px);
            }
  
          }
        }
      }
    }
  }
}

.flex-currency {

  display: flex;

  .currency-right {

    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    margin-bottom: 0;
  }
}
