// ==========================================================================
// Blog Styles
// ==========================================================================
.single-post {
    .social-icons {
        display: block;

        img {
            width: 20px !important;
        }
    }
}

.archive {
    main {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
        margin-top: 10px !important;

        @include media-breakpoint-down(sm) {

            margin: -5px;
        }

        article {
            margin: 10px;
            width: calc(33.3333% - 20px);
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(md) {

                width: calc(50% - 20px);
            }

            @include media-breakpoint-down(sm) {

                margin: 5px;
                width: calc(50% - 10px);
            }


            h2 {
                font-size: rem(30px);
                font-family: 'Playfair Display';
                font-weight: normal;
                padding: 0;
                margin: 0;

                &.entry-title {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    color: #fff;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 20px;
                    z-index: 10;
                    text-align: center;
                }


                @include media-breakpoint-down(sm) {

                    font-size: rem(13px);

                    &.entry-title {

                        padding: 10px;
                    }
                }

                a {
                    color: $white;
                }

                time {
                    padding-top: 1em;
                    display: block;
                    font-size: $font-size-base;
                    font-family: $font-family-base;
                    font-weight: 300;
                    text-transform: uppercase;
                    border-top: 1px $white solid;   
                    margin-top: 1em;
                    color: $white;
                    width: 100%;

                    @include media-breakpoint-down(sm) {

                        font-size: rem(11px);
                    }
                }
            }

            h3 {

                font-size: rem(18px);
                // font-family: 'Playfair Display';
                font-family: $font-family-base;
                font-weight: normal;

                @include media-breakpoint-down(sm) {

                    font-size: rem(12px);
                }
            }

            img {
                height: 395px;
                width: 100%;
                object-fit: cover;

                @include break-from(medium) {
                    width: 100%;
                }

                @include break-until ( small ) {
                    height: 156px;
                }
            }
        }

        nav {
            padding: $spacer 0;
            flex: 0 0 100%;
        }
    }
}

/* pagination */
nav.navigation {
    text-align: center;

    &.pagination {
        display: block;

        .nav-links {
            .page-numbers {
                // font-family: 'montserrat-light';
                font-weight: 300;
                &.current {
                    background: $white;
                    border: 0.5px solid $black;
                    border-radius: 50%;
                    width: 32px;
                    display: inline-block;
                    height: 32px;
                    color: #121212;
                    padding: 3px;
                }
            }
            .prev {
                background: url('../images/icons8-double-left.png');
                background-repeat: no-repeat;
                width: 21px;
            }
            .next {
                background: url('../images/icons8-double-right.png');
                background-repeat: no-repeat;
                width: 21px;
            }
            .prev, .next {
                color: transparent;
                font-weight: bold;
            }
            .first-last {
                background: $white;
                border: 0.5px solid $black;
                border-radius: 20px;
                color: $black;
                padding: 6px 15px;
                text-transform: uppercase;
            }
        }
    }

    .nav-links {
        a {
            margin: 1rem .6rem;
        }
        a.page-numbers {
            color: #121212;
            font-size: 15px;
        }
        a.next {
            display: inline-block;
            background-color: $black;
            color: #fff;
            -webkit-border-radius: 2px;
            border-radius: 2px;
        }
        a.prev {
            display: inline-block;
            background-color: $black;
            color: #fff;
            -webkit-border-radius: 2px;
            border-radius: 2px;
        }

        @include media-breakpoint-down(xs) {

            a {
                margin: 1rem 5px;
            }
        }
    }

}


.card {

    &.card-blog {
    
        @include media-breakpoint-up(lg) {
    
            .card-img {
    
                height: 270px;
    
                img {
    
                    height: 270px;
                }
                
            }
        }
    
        .card-body {
    
            .card-body-flex {
    
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 0;
                height: 100%;
            }

            .card-content {
    
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                margin-right: 10px;

                h3 {

                    font-size: rem(16px) !important;
                    font-family: $font-family-base;
                    font-weight: 400;
                }
            }

            .btn {

                font-size: rem(12px);
                padding: 5px 15px;
                flex-shrink: 0;
                
            }
    
            span {
    
                display: block !important; 
                font-size: rem(16px);
            }
    
            @include media-breakpoint-down(md) {
    
                span {
    
                    font-size: rem(11px);
                }
            }
    
            @include media-breakpoint-down(sm) {
    
                .card-body-flex { 
    
                    align-items: flex-start;
                }

                .card-content {
    
                    h3 {

                        font-size: rem(12px) !important;
                    }
                }
    
            }
        }
    
        .card-badge {
    
            font-size: rem(12px);
            position: absolute;
            top: 15px;
            right: 15px;
            display: inline-block;
            color: $white;
            padding: 4px 12px;
            border-radius: 10px;
            font-style: italic;
            background: $default-color;
    
            @include media-breakpoint-down(sm) {
    
                font-size: rem(10px);
                padding: 3px 10px;
    
            }
        }
    }

    &.card-plain {                

        h2 {

            font-size: rem(40px);
            font-family: $font-family-base;
            font-weight: 200;

            justify-content: flex-end !important;
            padding-bottom: 40px !important;

            @include media-breakpoint-down(md) {

                font-size: rem(30px);
                padding-bottom: 15px !important;
            }
        }

        .card-img {

            &::after {

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                @include linear-gradient(to top, rgba($black, 0.8) 0, transparent 100%);
            }

            @include media-breakpoint-up(lg) {

                height: 385px;

                img {

                    height: 385px;
                }
            }

            @include media-breakpoint-down(sm) {

                height: 170px;

                img {

                    height: 170px;
                }
            }
        }
    }

    &.card-lg {

        height: auto;

        .card-img {

            height: 430px !important;

            img {

                height: 100% !important;
            }

            &::after {

                display: none;
            }
        }

        .card-body {

            padding: 0;
            margin-top: 20px;

            .card-content {

                h3 {

                    font-size: rem(20px) !important;
                    margin-bottom: 15px;
                }

                p {

                    position: relative;
                    overflow: hidden;
                    display: -webkit-box;
                    line-clamp: 5;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                }

                @include media-breakpoint-up(lg) {

                    p, span {

                        font-size: rem(13px !important);
                    }
                    
                }
            }
        }

        @include media-breakpoint-down(md) {

            .card-img {

                height: 300px !important;

            }
        }

        @include media-breakpoint-down(sm) {

            .card-img {

                height: 200px !important;
            }
        }
    }

    &.card-sq {

        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        .card-img {

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100% !important;

            img {

                width: 100%;
                height: 100% !important;
            }
        }

        .card-text-overlap {

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 20px;
            color: $white;
            text-align: center;

            h2 {

                text-transform: uppercase;
                padding-bottom: 0 !important;
                margin-bottom: 0;
            }

            span {

                display: block;
                width: 100%;
                border-top: 1px solid $white;
                margin-top: 20px;
                padding-top: 20px;
            }

        }
    }
}

.article-title {


    width: 100%;

    h2 {

        font-size: rem(48px);
        font-weight: 900;
        margin-bottom: 20px;
    }

    @include media-breakpoint-down(md) {

        h2 {

            font-size: rem(40px);
        }
    }

    @include media-breakpoint-down(sm) {

        h2 {

            font-size: rem(38px);
        }
    }
}
