.exp-booking {

  border-radius: 30px;
  background: $white;
  position: sticky;
  top: 97px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, .2);

  &.is-plain {

    background: none;
    border: 0;

    strong {

      font-weight: 700;
    }

    .book-body {

      .exp-booking-list {

        border-color: $gray-150;

        .form-control {

          background: none;
          color: $light-gray;
          border-color: $gray-150;
          border-radius: 6px;


          option { 
            
            color: black; 
          }
    
          &.md {
            
            max-width: 238px;
          }
        }
      }
    }
  }

  .book-head {

    padding: 25px 15px;
    background: $light-gray;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    text-align: center;

    h3 {

      font-family: $font-family-base;
      font-size: rem(18px);
      margin-bottom: 0;
    }
  }

  .book-body {

    padding: 15px;
    font-size: rem(14px);

    .exp-booking-list {

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-top: 1px solid $gray-250;

      &:first-child {

        margin-top: -15px;
        border-top: 0;
      }

      strong {

        padding-right: 10px;
      }

      .form-control {

        font-size: rem(14px);
        text-overflow:ellipsis;

        &.sm {

          max-width: 68px;
        }

        &.md {

          max-width: 188px;
        }
      }

      @include media-breakpoint-down(md) {

        &.exp-booking-total {

          justify-content: flex-start;
        }
      }
    }
  }

  .book-footer {

    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    &#no-avail {
      display: none;

      &.active {
        display: block;

        a {
          background: $light-gray;
          pointer-events: none;
        } 
      }

      &.deactivate {
        display: none;        
      }
    }

    &#minimum {
      display: none;

      &.active {
        display: block;

        a {
          background: $light-gray;
          pointer-events: none;
        } 
      }

      &.deactivate {
        display: none;        
      }
    }

    &#avail { 
      &.active {
        display: block;
      }

      &.deactivate {
        display: none;
      }
    }

    a {

      display: block;
      font-size: rem(24px);
      font-weight: 700;
      padding: 20px;
      color: $black;
      background: $yellow;
      border-radius: inherit;
      text-align: center;

      &:hover {

        background: darken($yellow, 10%);
      }
      
      &.disabled {

        pointer-events: none;
        background: $light-gray;
      }
    }
  }

  .btn-close {

    display: none;
  }

  @include media-breakpoint-down(md) {

    &:not(.is-plain) {

      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 10;
      box-shadow: 0px 0px 5px rgba($black, 0.4);
      @include transition(all 0.5s ease);
      @include transform(translateY(100%));
  
      &.show {
  
        @include transform(translateY(0));
      }
  
      .book-head {
  
        padding: 0;
        background: $white;
  
        h3 {
  
          display: none;
        }
  
        .btn-close {
  
          display: block;
          margin: 0 auto;
          width: 90px;
          padding: 10px;
          border: none;
          background: none;
  
          span {
  
            display: block;
            height: 5px;
            background: $gray-250;
            border-radius: 10px;
          }
        }
      }
  
      .book-body {
  
        padding: 20px;
      }
  
      .book-footer {
  
        margin: 20px;
        margin-top: 0;
  
        a {
  
          font-size: rem(20px);
          padding: 12px;
          border-radius: 5px;
        }
      }
    }

  }
}

.exp-booknow {

  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
  font-size: 20px;
  font-weight: 700;
  z-index: 8;
  background: $yellow;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 6px 1px rgba($black, 0.3);
}


