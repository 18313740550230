// ==========================================================================
// MMXP PAGE Styles
// ==========================================================================

.img-objectfit {

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-auto {

  width: 100%;
  height: auto;
}

.dot {

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $purple;
}

.mmxp-page {

  padding-bottom: 0 !important;

  .container {

    max-width: 1200px;
  }
}

.mmxp-section {

  position: relative;
  padding: 70px 0;

  &.purple {

    background: $purple;
    color: $white;
  }

  &.section-highlight {

    background: $gray-50;
  }

  &.light-purple {

    background: $purple-500;
  }

  @include media-breakpoint-down(xs) {

    padding: 50px 0;
  }
}

.mmxp-hero-banner {

  .mxxp-hero-content {

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 70px;
    color: $white;

    h1 {

      font-size: rem(135px);
      line-height: 0.9;
    }

    h4 {

      font-size: rem(34px);
      font-weight: normal;
    }

    h3 {

      font-size: rem(37px);
      font-family: $font-family-base;
      text-align: right;
    }
  
  }


  @include media-breakpoint-down(lg) {

    height: 500px;

    .mxxp-hero-content {

      padding-left: 30px;
      padding-right: 30px;


      h1 {
  
        font-size: rem(100px);
      } 
    }
  
  }

  @include media-breakpoint-down(md) {

    .mxxp-hero-content {

      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding-top: 150px;

      h1 {
  
        font-size: rem(80px);
        text-shadow: 0 0 10px rgba($black, 0.5);
      } 

      h4 {

        font-size: rem(25px);
        text-shadow: 0 0 10px rgba($black, 0.5);
      }

      h3 {

        font-size: rem(28px);
        text-align: center;
        margin-top: 30px;
        text-shadow: 0 0 10px rgba($black, 0.5);
      }

    }    
  }

}

.section-snippets {

  text-align: center;

  h2 {

    text-shadow: 0 0 10px rgba($black, 0.5);
  }

  h4 {

    font-weight: normal;
  }
}

.section-process {

  .process {

    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: center;    

    &::before {

      position: absolute;
      top: 30px;
      left: 50%;
      @include transform(translateX(-50%));
      width: calc(100% - 200px);
      height: 1px;
      content: '';
      border-top: 1px solid $default-color;
    }

    .process-grid {

      position: relative;
      max-width: 200px;
      z-index: 2;
      padding: 0 10px;
      
      strong {

        display: block;
        font-size: rem(35px);

        em {

          padding: 0 20px;
          display: inline-block;
          font-style: normal;
          font-weight: 900;
          background: $gray-50;
        }
      }
    }

    @include media-breakpoint-up(xl) {

      margin-left: -50px;
      max-width: calc(100% + 100px);
    }

    @include media-breakpoint-down(md) {

      &::before {

        top: 19px;
      }

      .process-grid {
        
        strong {
  
          font-size: rem(25px);

        }
      }
    }

    @include media-breakpoint-down(xs) {

      flex-wrap: wrap;
      justify-content: center;

      &::before {

        display: none;
      }

      .process-grid {

        width: 50%;
        padding: 20px 10px;
      }
    }
  }
}

.section-grid {

  .col-graphics {

    min-height: 400px;
  }

  @include break-from(1400px) {

    .pos-inherit {
  
      position: inherit;
    }
  
    .col-container-right {
    
      display: flex;
      align-items: center;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  
    .col-content-right {
  
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
    }
  
    .col-content-right-inner {
  
      max-width: 700px;
      padding: 40px 0;
      margin-left: 0;  

      &.is-left {

        margin-left: auto;
      }
    }
  }


  @include break-until(1400px) {

    .col-container-right {
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px;
    }
  }

  @include media-breakpoint-down(lg) {

    .col-graphics {

      min-height: 200px;
    }
  }

  h2 {

    font-size: rem(70px);
    color: $purple;
  }

  @include media-breakpoint-down(md) {

    h2 {

      font-size: rem(50px);
      color: $purple;
    }
  }

  @include media-breakpoint-down(xs) {

    .col-container-right {

      padding-left: 20px;
      padding-right: 20px;
    }

    .col-content-right {

      padding-left: 15px;
      padding-right: 15px;
    }

    .col-content-right {
  
      padding: 0;
    }

  }
}

.circles {

  display: flex;
  margin-left: -40px;

  .circle {

    position: relative;
    margin-left: 40px;
    width: calc(20% - 40px);
    border-radius: 50%;
    padding-bottom: calc(20% - 40px);
    background: $purple;

    .circle-inner {

      position: absolute;  
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        font-size: rem(14px);
        font-weight: 700;
        margin-bottom: rem(4px);
      }

      img {
        width: 52px;
        height: 51px;
        margin-bottom: rem(12px);
      }

      strong {
        font-size: rem(18px);
        color: $white;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    .circle {

      .circle-inner {

        img {

          width: auto;
          height: 70%;
        }

        span,strong {

          font-size: 1.5vw;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {

    margin-left: -20px;

    .circle {

      margin-left: 20px;
      width: calc(25% - 20px);
      padding-bottom: calc(25% - 20px);
    }
  }

  @include media-breakpoint-down(sm) {

    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;

    .circle {

      width: calc(50% - 20px);
      margin: 10px 10px;
      padding-bottom: calc(50% - 20px);

      .circle-inner {

        span,strong {

          font-size: 16px;
        }

        img {

          margin-top: 0;
          margin-bottom: 0;

        }
      }
    }
  }

  @include media-breakpoint-down(xs) {

    margin-left: 0;

    .circle {      

      width: calc(50% - 16px);
      margin: 8px;
      padding-bottom: calc(50% - 16px);

      .circle-inner {
        padding: 18%;

        span,strong {

          font-size: 3.2vw;
          line-height: 1.2;
        }
      }
    }
  }

}

.checklist {

  padding-left: 0;
  font-size: rem(28px);
  
  li {

    position: relative;
    padding-left: 40px;
    list-style: none;

    &:before {

      position: absolute;
      top: 10px;
      left: 0;
      content: '';
      width: 29px;
      height: 24px;
      background: url('../images/mmxp/arrow.png') no-repeat;
      background-size: 100%;
    }
  }
}

.table-container {

  position: relative;
  width: 100%;
  overflow: auto;

  h3 {

    font-family: $font-family-base;
    font-size: rem(28px);
    font-weight: normal;
    margin-bottom: 0;
  }

  .dot {

    margin: 0 auto;
  }

  table {

    width: 100%;
    min-width: 700px;

    th,td {

      padding: 20px 0;

      &:first-child {

        max-width: 50%;
      }

      + th,td {
        text-align: center;
        width: 18%;
      }
    }

    tbody {

      tr {

        border-bottom: 1px solid $light-black;

        &:last-child {

          border-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    
    &:before {

      display: none;
    }
  }

  @include media-breakpoint-down(sm) {

    border: 1px solid $default-color;

    h3 {

      font-size: rem(25px);
    }

    table {

      th, td {

        padding: 15px;
      }
    }

    &:before {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.3) url('../images/mmxp/hand.gif') no-repeat center;
      z-index: 2;
      background-size: 110px;

    }

    &.touch {

      &:before {

        display: none;
      }
    }

    .dot {

      width: 15px;
      height: 15px;
    }
  }
}

.section-download {

  h4 {

    font-size: rem(24px);
    font-weight: normal;
    color: $default-color !important;
  }

  h3 {

    font-size: rem(40px);
    font-family: $font-family-base !important;
    font-weight: 900;
    margin-bottom: 20px;

    span {

      font-size: rem(30px);
      line-height: normal;
    }
  }

  .downloads {

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-button {

    a {

      display: block;
      margin: 10px 0;
    }
  }

  .deals-klaviyo-form {

    span {
  
      font-size: rem(40px) !important;
      color: #212529 !important;
    }

    p span {
  
      font-size: rem(16px) !important;
      color: #212529 !important;
    }

    .needsclick {
      
      &[role="alert"] {

        font-size: rem(14px) !important;
      }
    }
  }
  

  @include media-breakpoint-down(sm) {

    h3 {

      font-size: rem(35px);
  
      span {
  
        font-size: rem(25px);
      }
    }

    .deals-klaviyo-form {

      span {
    
        font-size: rem(35px) !important;
      }
  
      p span {
    
        font-size: rem(16px) !important;
      }
    }
    
  }

  @include media-breakpoint-down(xs) {

    
    h3 {

      font-size: rem(25px);
  
      span {
  
        font-size: rem(20px);
      }
    }

    .deals-klaviyo-form {

      span {
    
        font-size: rem(25px) !important;
      }
  
      p span {
    
        font-size: rem(16px) !important;
      }
    }

    .downloads {

      flex-direction: column;
    }

    .download-button {

      margin-top: 20px;
    }
  }
}