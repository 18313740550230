.card {

  position: relative;
  height: 100%;
  border: none;
  box-shadow: 0 0 7px 1px rgba($black, 0.3);
  overflow: hidden;

  &:hover {

    .card-img {

      img {

        @include transform(scale(1.1));
      }
    }
  }

  &.card-rounded {

    border-radius: 30px;
    overflow: hidden;
  }

  &.card-sm-rounded {

    border-radius: 10px;
    overflow: hidden;

    .card-img {
    
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  
      img {
  
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  &.card-plain {

    box-shadow: none;
  }

  &.card-none {

    padding: 20px;
    background: none;
    border: none;
    border-top: 1px solid $gray-150;
    border-radius: 0;
  }

  &.card-flex {

    min-height: 350px;

    .card-img {

      position: relative;
      height: 200px;
      max-height: 500px;
      z-index: 2;

      img {

        height: 200px;
        max-height: 500px;
      }
    }

    .card-body {

      position: relative;
      z-index: 2;

      p {

        overflow: visible;
        display: block;
        line-clamp: initial;
        -webkit-line-clamp: initial;
      }
    }


    @include media-breakpoint-up(sm) {

      display: flex;
      flex-direction: row;

      .card-img {

        flex-grow: 1;
        max-width: 65%;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        height: auto;
  
        img {
  
          height: 100%;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 0;
          @include transform(scale(1) !important);
        }
      }

      .card-body {

        flex-grow: 1;
        max-width: 35%;
        padding: 30px 25px;
  
      }

      h4 {

        margin-bottom: 20px;
      }
    }

    @include media-breakpoint-down(xs) {

      h4 {

        font-size: rem(16px);
      }
    }

  }

  &.card-rounded-0 {

    border-radius: 0 !important;
  }

  &.card-gradient {

    padding-bottom: 110%;

    &::before {

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: '';
      @include linear-gradient(to top, rgba($black, 0.8) 0, transparent 80%);
      z-index: 1;
    }

    > a {

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.card-lg-rounded {

    border-radius: 24px;
    border: 1px solid $gray-200;
    padding: 30px;
    box-shadow: none;
  }

  &.card-outline {
    position: relative;
    padding: 20px;
    border: 1px solid $gray-200;
    border-radius: 24px;
    box-shadow: none;
  }

  .card-img {
    
    position: relative;
    height: 240px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: $white;
    background: $gray-250;
    overflow: hidden;

    img {

      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      @include transition(all 0.5s ease);
      
      width: 100%;
      height: 240px;
      object-fit: cover;
    }

    .card-img-txt {

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      text-align: center;

      h4 {

        text-shadow: 0 0 12px rgba($black, 0.7);
        font-size: rem(25px);
      }
    }

    .card-price {

      font-size: rem(11px);
      font-weight: 700;
      position: absolute;
      bottom: 0;
      left: 0;

      .card-price-black {

        display: inline-block;
        padding: 4px 12px;
        background: $black;
        color: $white;

        em {

          font-style: normal;
          font-weight: normal;
        }
      }

      .sq-badge {

        color: $default-color;
      }
    }

    @include media-breakpoint-down(md) {

      .card-img-txt {

        h4 {
  
          font-size: rem(20px);
        }
      }

    }

    @include media-breakpoint-down(sm) {
      
      height: 150px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      img {

        height: 150px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .card-img-txt {

        h4 {
  
          text-transform: uppercase;
          font-size: rem(16px);
        }
      }
    }

    @include media-breakpoint-down(xs) {

      height: 120px;

      img {

        height: 120px;
      }

      .card-img-txt {

        h4 {
  
          font-size: rem(14px);
        }
      }
    }
  }

  .card-body {

    font-size: rem(12px);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 15px;

    p {

      overflow: hidden;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }

    h5 {

      font-size: rem(16px);
      font-weight: 700;
    }

    .card-body-flex {

      display: flex;
      align-items: center;
      margin-top: auto;
      padding-top: 12px;

      .btn {

        font-weight: 500;
        padding: 5px 20px;
        margin-left: auto;
        border-radius: 30px;
        background: $green-45;
        color: $white;

        &:hover {

          background: darken($green-45, 10%);
        }
      }

      .amount {

        font-weight: 700;
      }
    }
  }

  .sq-badge {

    font-style: rem(14px);
    font-weight: 700;
    display: inline-block;
    padding: 4px 12px;
    background: $green-45;
  }

  .card-caption-overlay {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    color: $white;
    z-index: 2;
    
    span {

      font-size: rem(48px);
      font-weight: 700;
      display: block;
      line-height: normal;
    }

    strong {

      font-size: rem(32px);
      font-weight: 700;
      display: block;
      line-height: normal;
      margin-bottom: 10px;
    }

    p {

      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {

      span {
        
        font-size: rem(32px);
      }

      strong {

        font-size: rem(22px);
      }

      p {

        font-size: rem(13px);
      }
    }

    @include media-breakpoint-down(sm) {

      padding: 15px;
      
      span {
        
        font-size: rem(28px);
      }

      strong {

        font-size: rem(20px);
      }

      p {

        font-size: rem(13px);
      }

    }
  }

  @include media-breakpoint-down(sm) {

    &.card-rounded {

      border-radius: 10px;
    }

    .card-body {

      padding: 15px;
      font-size: rem(12px);

      p {

        overflow: hidden;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
      }

      h5 {

        font-size: rem(12px);
      }

      .card-body-flex {

        margin: 0;
        padding: 0;

        span {

          display: none;
        }

        .btn {

          font-size: 0 !important;
          margin: 0 !important;
          padding: 0 !important;
          opacity: 0 !important;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {

    .card-body {

      padding: 8px 10px;
      font-size: rem(11px);

      .card-body-flex {

        flex-direction: column;
        justify-content: center;

        .btn {

          margin-left: initial;
          margin-top: 10px;
          font-size: rem(12px);
        }
      }
    }
  }
}

.shadow-nav {

  box-shadow: 0 5px 10px -5px rgba($black, 0.3);
}

.card {

  .klaviyo_form_trigger {

    cursor: pointer;
  }
}