.campaign-page {

  h2 {

    font-size: rem(50px);
  }

  .container-llg {

    max-width: 1130px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {

    h2 {

      font-size: rem(40px);
    }
  }

  @include media-breakpoint-down(sm) {

    h2 {

      font-size: rem(35px);
    }
  }

  .section-grid {

    padding-left: 0;
    padding-right: 0;

    &.section-grid-green {

      .container-fluid {
        
        background: rgba($green-45, .6);
      }
    
      h2 {
        
        color: $default-color;
      }

      @include media-breakpoint-up(xl) {
        
        .col-content-right {
      
          max-width: 1130px;
        }
      
        .col-content-right-inner {
      
          max-width: 730px;
        }
      }
    
    }
    
    &.section-grid-gray,
    &.section-grid-dgreen {
    
      .container-fluid {
    
        background: $gray-800;
      }
    
      h2 {
        
        color: $white;
        margin-bottom: 20px;
      }

      @include break-from(1300px) {
        
        .col-content-right {
      
          max-width: 1340px;
        }
      
        .col-content-right-inner {
      
          max-width: 620px;
        }
      }

    }

    &.section-grid-dgreen {

      padding: 0 !important;

      .container-fluid {

        background: darken($green-60, 25%);
      }
    }
  }

  .section-gray {

    background: $gray-800 !important;

    &.big {

      @include media-breakpoint-up(md) {


        padding-top: 90px;
        padding-bottom: 90px;
      }
    }

    h4 {

      font-size: rem(30px);
      margin-bottom: 30px;
    }
    
    strong {

      font-size: rem(18px);
    }

    @include media-breakpoint-down(md) {

      h4 {

        font-size: rem(22px);
      }
    }
  }

  .section-dark {

    background: $black;
  }
}

.is-arrow-list {

  ul {
    list-style: none;
  }

  li {

    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 20px;
    
    &:before {
      
      font-family: FontAwesome;
      font-weight: 400;
      content: '\f054';
      display: inline-block !important;
      margin-left: 5px;
      font-size: 80%;
      margin-right: 30px;
      margin-top: 2px;
    }

    strong {

      font-size: rem(20px);
      display: block;
      margin-bottom: 15px;
      color: $green-60;
    }
  }

  @include media-breakpoint-down(sm) {

    ul {

      padding-left: 0;
    }
  }
}

.campaign-process {

  .process {

    &::before {

      display: none;
    }

    .process-grid {

      max-width: initial;
      flex-grow: 1;
      padding: 0;

      .process-grid-inner {

        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
      }

      em {

        display: block;
        position: relative;
        font-size: rem(35px);
        font-weight: 900;
        font-style: normal;

        &::before {

          position: absolute;
          top: calc(50% - 1px);
          left: 0;
          width: calc(50% - 30px);
          height: 1px;
          content: '';
          border-top: 1px solid rgba($white, 0.3);
        }

        &::after {

          position: absolute;
          top: calc(50% - 1px);
          right: 0;
          width: calc(50% - 30px);
          height: 1px;
          content: '';
          border-top: 1px solid rgba($white, 0.3);
        }
      }

      strong {

        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 80px;
        margin: 10px 0;
      }

      &:first-child {

        em {

          &::before {

            display: none;
          }
        }
      }

      &:last-child {

        em {

          &::after {

            display: none;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {

      flex-direction: column;
      text-align: left;

      .process-grid {

        width: 100%;
        display: flex;

        em {

          padding-bottom: 50px;
          width: 50px;
          text-align: center;

          &::before {

            display: block;
          }

          &::after {

            width: 1px;
            height: calc(100% - 48px);
            border-top: 0;
            border-left: 1px solid rgba($white, 0.3);
            left: calc(50% - 1px);
            top: 48px;
          }
        }

        .process-grid-inner {

          max-width: 100%;
          margin: 0;
          padding-left: 20px;

          strong {

            height: auto;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0;
            text-align: left;
          }

        }
      }
    }
  }
}

.circles-green {

  .circle {

    background: $green-45;
    
    .circle-inner {

      line-height: 1.2;
  
      img {
  
        margin-top: 20px;
        max-height: 55%;
      }
    }

    @include media-breakpoint-up(lg) {

      width: calc(33.333333% - 40px);
      padding-bottom: calc(25% - 40px);

      .circle-inner {
    
        img {
    
          width: auto;
          height: 80px;
          max-height: 55%;
        }
      }
    }

    @include media-breakpoint-down(sm) {

      .circle-inner {

        img {

          margin-top: 10px;
        }
      }
    }
  }

}

.content-left {

  max-width: 790px;
}

.campaign-form {

  display: inline-block;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0px rgba($dark-gray, 0.9);
  max-width: 100%;
  background: $white;

  button {

    &.needsclick {

      width: 280px;
    }
  }

  @include media-breakpoint-down(sm) {

    padding: 20px 0;
  }
}

.section-print {

  h2 {

    margin-bottom: 20px;
  }

  ul {

    padding-left: 25px;
  }
}

.custom-campaign {

  padding-bottom: 0 !important;

  .slogan {

    margin-top: 60px;

    @include media-breakpoint-down(sm) {

      padding: 20px 0 !important;
      
      h2 {

        font-size: rem(33px !important);
      }
    }
  }
}


// Camgaign Dark Page
.campaign-page {

  &.dark {
  
    background: $black;
    color: $white;
    padding-bottom: 0;
  
    .section-hero {
  
      @include media-breakpoint-down(sm) {
        
        min-height: 400px;

        > img {

          min-height: 400px;
        }

        .campaign-logo {

          max-width: 270px;
          width: 70%;

          &.w-sm-25 {

            width: 25%;
          }
        }
      }
    }

    .section {

      background: $black;
    }
  
    @include break-from(1300px) {
  
      margin-top: -96px;
    
      .section-hero {
  
        .hero-content {
  
          h4 {
  
            font-weight: 200;
            letter-spacing: 2px;
          }
        }
      }
    }
  
  }
}

@include break-from(1300px) {

  .site-header {

    &.header-light {
  
      background: initial;
      box-shadow: none;

      .container {

        position: relative;
        z-index: 2;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        @include linear-gradient(to bottom, rgba($black, 1) 0, transparent 90%);
        @include transition(all 0.3s ease);
        opacity: 0;
      }
  
      &.sticky-top {
        
        &::after {
          opacity: 1;
        }
  
        .menu {
  
          li {
  
            position: relative;
            z-index: -2;
            opacity: 0;
  
            &:last-child {
  
              z-index: initial;
              opacity: 1;
            }
          }
        }
      }
    }
  }
      
}

.campaign-tab {

  .title-nav {

    display: flex;
    align-items: center;

    h2 {

      flex-shrink: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      
      flex-wrap: wrap;
      
      h2 {

        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .nav-pills {

    flex-grow: 1;
    margin-left: auto;
    position: relative;

    @include break-from(1301px) {

      z-index: 1021;
    }

    .nav-item {

      flex-grow: 1;
      flex-basis: 50%;

      .nav-link {
  
        font-size: rem(22px);
        font-weight: 500;
        color: $white;
        border-radius: 30px;
        text-align: center;
        margin-left: 20px;
        padding: 12px 15px;
  
        &.is-red {
  
          border: 1px solid $red-500;
          
          &.active {
  
            background: $red-500;
            color: $black;
          }
        }
  
        &.is-green {
          
          border: 1px solid $green-60;
  
          &.active {
            
            background: $green-60;
            color: $black;
          }
        }
      }

      @include media-breakpoint-down(lg) {

        &:first-child {

          .nav-link {

            margin-left: 0;
          }
        }

        .nav-link {

          font-size: rem(16px);
        }
      }

      @include media-breakpoint-down(sm) {

        .nav-link {

          font-size: rem(14px);
        }
      }

      @include media-breakpoint-down(xs) {

        .nav-link {

          font-size: rem(13px);
          padding: 10px;
          margin-left: 10px;
        }
      }
    }

  }
}

.campaign-faq {

  .card {

    .card-header {

      font-size: rem(20px);
      display: flex;
      align-items: center;
      cursor: pointer;

      strong {

        margin-right: 50px;
        flex-shrink: 0;
      }

      &:before {

        display: block;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        border-radius: 50%;
        background: $gray-150;
        content: '';
        margin-right: 25px;

      }

      &:after {

        font-family: FontAwesome;
        font-weight: 400;
        content: "\f078";
        flex-shrink: 0;
        display: inline-block !important;
        margin-left: 5px;
        font-size: 80%;
        margin-left: auto;
        padding-left: 10px;
        
      }
    }

    &.active {

      .card-header {

        &:before {

          background: $green-60;
        }
  
        &:after {
  
          content: "\f077";
          color: $green-60;          
        }
      }
    }

    @include media-breakpoint-down(md) {

      padding: 10px;
      
      .card-header {
        
        font-size: rem(16px);
        padding: 10px;

        &::before {

          margin-left: 0;
          margin-right: 15px;
        }

        strong {

          margin-right: 30px;
        }
      }

      .col-graphics {

        margin-bottom: 20px;
      }
    }
  }
}

.flex-grid-list {

  ul {

    padding-left: 0;

    @include media-breakpoint-up(md) {
      
      margin-top: 30px;
      width: 100%;
      top: 0;
      left: 0;
      padding-bottom: 15px;
      column-count: 2;
      column-gap: 10px;
      -moz-column-count: 2;
      -moz-column-gap: 10px;
      -webkit-column-count: 2;
      -webkit-column-gap: 10px;
      grid-auto-flow: row dense;
  
  
      li {
  
        -webkit-column-break-inside: avoid;
        -webkit-perspective: 1;
        page-break-inside: avoid;
        -moz-column-break-inside: avoid;
        break-inside: avoid;
        -webkit-column-span: none;
        -moz-column-span: none;
        column-span: none;
        margin-top: 0;
        margin-left: 40px;
        margin-bottom: 20px;
      }
    }

  }
}

.section-dgreen {

  .container-fluid {
  
    background: darken($green-60, 25%);
  }

  h2 {

    margin-bottom: 20px;
  }
  
  .content {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 60px;

    @include media-breakpoint-down(lg) {
      
      padding: 30px;
    }

    @include media-breakpoint-down(xs) {
      
      padding: 30px 20px;
    }
  }
}

.currency-end.no-absolute {

  position: relative;
  width: 200px;
  margin-left: auto;
  z-index: 2;
}

.tab-content {

  .tab-pane {
    
    &:not(.active){

      display: none;
    }
  }
}

