// ==========================================================================
// Content Styles
// ==========================================================================

.currency-modal {
    .modal-body {
        ul {
            @include flex-container( $align: stretch );
			justify-content: left;
			flex: 0 0 100%;

            li {
                flex: 0 0 25%;
                padding-bottom: $spacer;
            }
        }
    }
}

@include break-until(1053px) {

    .page-template-booking-archive {

        .site-content {
    
            overflow-x: hidden;
        }
    }
}

.site-content .content {
    &.mmkbooking-archive {

        padding-top: $spacer * 3;
        padding-bottom: $spacer * 5.5;

        .container {

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }

            @include media-breakpoint-down(md) {
                max-width: 768px;
            }
        }

        #mmkcalendar-search .booking-search {
                        
            padding-top: 10px;
        }

        @include break-from( medium ) {
            padding-bottom: $spacer * 3;
            min-height: 1024px;
        }
    }
}

.mmkbooking-archive {
   #mmkcalendar-search {
       .booking-search {
           position: relative;
           padding: ($spacer * 2.5) $spacer 0;

           .container {
            .item {

                @include break-until( medium ) {
                    border: 1px solid $gray;
                }
            }
           }
       }
   }

   #spinner-ajax {
        text-align: center;
        display: none;
        padding: $spacer * 2;
   }

   .container {
       &.item-list {
           h2 {
               &.destination {
                   text-transform: uppercase;
                   padding-top: 0;
                   padding-bottom: 0;
                   font-size: rem(35px);
                   font-family: 'Montserrat';
                   font-weight: 500;
               }

               + p {

                &.skeleton {

                    display: inline-block;
                }
                
               }
           }
       }
   }

   h1 {
       padding: $spacer 0;
   }

   .mmkcalendar-search {
       border: 1px $gray solid;
       padding: ($spacer * .5) ($spacer * 3);
       border-radius: $spacer * 4;

        @include break-from( medium ) {
            @include flex-container( $align: center );
            justify-content: space-around;
        }

        .item {
			@include flex-item();
            @include flex-container( $align: center );
            align-items: stretch;
            justify-content: space-around;
            flex-direction: column;
            position: relative;

            input,
            select {
                padding: ($spacer * .4) 0 0;
                border: 0;

                &:focus {
                    border-color: inherit;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            p {
                position: relative;
                top: 0;
                font-weight: 500;
            }

            button {
                position: absolute;
                right: -4.5em;
                border-radius: 5em;
                padding: 2em .9em;
                border: 0;
                cursor: pointer;
                background-color: $black;
                background-image: url('../images/search-icon.png');
                background-size: 38%;
                background-repeat: no-repeat;
                background-position: center center;
                color: transparent;
                top: -5em;

                @include break-from( medium ) {
                    top: inherit;
                }

            }

            &:nth-of-type(1) {
                padding-right: $spacer;
                margin-right: $spacer;

                @include break-from( medium ) {
                    border-right: 1px solid $gray;
                }
            }

            &:nth-of-type(2) {
                padding-right: $spacer;
                margin-right: $spacer;

                @include break-from( medium ) {
                    border-right: 1px solid $gray;
                }
            }
        }
    }

    .room-type {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        padding: ($spacer * .5) 0;

        .item {
            display: inline-block;
            margin-bottom: 1em;
            width: auto;
            margin-right: 5px;

            @include break-from( medium ) {
                width: 48%;
            }

            &.skeleton {

                height: auto;
                border-radius: 30px !important;

                * {

                    opacity: 0;
                    height: auto;
                }

                a {

                    padding: .5em 1.3em !important;
                }
            }

            @include break-from( medium ) {
                margin-bottom: inherit;
                margin-right: 20px;
                width: inherit;
            }


            a {
                display: none;
                font-size: $font-size-base * 0.813;

                &.btn.btn-secondary {
                    padding: 7px 20px;
                }

                @include break-from( medium ) {
                    font-size: inherit;
                }

                @include media-breakpoint-down(xs) {

                    font-size: rem(11px);
                }
            }
        }

        @include media-breakpoint-down(md) {

            padding-bottom: 0;
        }

    }

    .rooms {
        position: relative;
        padding-top: 30px;

        @include break-from( medium ) {
            @include flex-container( $align: stretch );
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            padding-top: $spacer * 3;
        }

        .book-now-mobile {
            position: fixed;
            left: 15px;
            right: 15px;
            bottom: 15px;
            color: $white;
            text-align: center;
            z-index: 99;
            background: $gray;
            border-radius: 5px;
            box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.3);

            &.active {
                background: $yellow;
            }

            h2 {
                margin: 0;
                font-size: $font-size-base * 1.2;

                a {
                    display: block;
                    padding: $spacer * .7;
                    color: $black;
                }
            }

            @include break-from( medium ) {
                display: none;
            }

            .spinner-border {

                width: 20px;
                height: 20px;
                color: $gray-150;
                margin-left: 5px;
            }
        }

        .right {
            @include flex-item(9);

            @include break-from( medium ) {
                padding-right: 50px;
            }

            h2 {
                &.destination {
                    display: none;

                    @include break-from( medium ) {
                        display: block;

                        &.skeleton {
                            
                            display: inline-block;
                        }
                    }
                }
            }

            .btn-secondary {

                border-color: $default-color;
            }

            .room {
                
                display: flex;
                position: relative;
                box-shadow: 0 0 8px 1px rgba($black, 0.2);
                margin-bottom: 0;
                border-radius: 10px;
                margin-top: 25px;

                @include media-breakpoint-down(md) {

                    flex-direction: column;
                    margin-top: 20px;
                    width: 100%
                }

                .item {
                    @include flex-item();

                    &.room-img {

                        position: relative;
                        @include flex-item($grow: 4);
                        min-height: 270px;
                        // max-height: 360px;
                        background: $gray-50;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        overflow: hidden;

                        .hide-text {

                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }

                        img {

                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }

                        .mobile-room-title {

                            position: absolute;
                            top: 0;
                            left: 50px;
                            width: calc(100% - 100px);
                            height: 100%;
                            padding: 10px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 2;

                            h2 {

                                font-size: rem(30px);
                                text-transform: uppercase;
                                text-align: center;
                                color: $white;
                                text-shadow: 0 0 5px rgba($black, 0.6);
                                margin-bottom: 0;
                            }
                        }

                        .swiper-button-icon {

                            width: 45px;
                            height: 45px;

                            img {

                                width: 50px;
                                height: 50px;
                            }
                        }

                        .swiper-button-next {

                            right: 0;
                        }

                        .swiper-button-prev {

                            left: 0;
                        }

                        @include media-breakpoint-up(lg) {

                            max-width: 317px;

                            img {

                                // height: 300px;
                                // max-height: 360px;
                            }

                            .mobile-room-title {

                                display: none;
                            }
                        }

                        @include media-breakpoint-down(md) {

                            border-bottom-left-radius: 0;
                            border-top-right-radius: 10px;
                            height: 270px;

                            img {

                                border-bottom-left-radius: 0;
                                border-top-right-radius: 10px;
                                height: 270px;
                            }
                        }

                        @include media-breakpoint-down(xs) {

                            height: 180px;
                            min-height: 180px;

                            img {

                                height: 180px;
                            }

                            .mobile-room-title {

                                h2 {

                                    font-size: rem(20px);
                                }
                            }
                        }
                    }

                    &.room-content {
                        @include flex-item($grow: 7.5);
                        padding: 20px;
                        position: relative;
                        display: flex;
                        flex-direction: column;

                        @include media-breakpoint-down(sm) {

                            padding: 15px;
                        }

                        .room-left {
                            position: absolute;
                            right: 20px;
                            top: 0;
                            padding: .5em;
                            background: $black;
                            color: $white;
                            font-size: 12px;

                            &.skeleton {
                                padding: 0;
                                background: transparent;
                            }

                            @include media-breakpoint-down(md) {

                                right: auto;
                                left: 10px;
                                top: -40px;
                                padding-left: 12px;
                                padding-right: 12px;
                                border-radius: 5px;
                            }
                        }

                        .about {
                            // padding-bottom: $spacer * .5;
                            font-size: rem(13px);
                            width: calc(100% - 80px);

                            h2 {
                                margin-bottom: .5em;
                                font-size: rem(20px);
                                text-transform: uppercase;
                            }

                            ul {
                                padding: 0;

                                li {
                                    display: inline-block;
                                    padding-right: 0;
                                    font-size: $font-size-base * 0.813;
                                }
                            }

                            p {

                                margin-bottom: 10px;
                            }

                            .cancellation {

                                font-size: rem(12px);
                                color: $green-15;
                                cursor: pointer;

                                img {

                                    @include transform(translateY(3px));
                                }
                            }

                            @include media-breakpoint-down(md) {

                                width: 100%;
                                overflow: hidden;
                                display: -webkit-box;
                                line-clamp: 3;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                padding-bottom: 0;
                                height: 63px;

                                &.show {

                                    overflow: visible;
                                    line-clamp: initial;
                                    -webkit-line-clamp: initial;
                                    height: auto;
                                }

                                h2 {

                                    display: none;
                                }

                                ul {

                                    margin-bottom: 5px;
                                }

                                p {

                                    margin-bottom: 0;
                                }

                                .cancellation-container {

                                    margin-top: 5px;
                                    
                                    .fee-text-desc {

                                        width: 250px !important;
                                    }
                                }
                            }

                            @include media-breakpoint-down(sm) {

                                display: block;
                                font-size: rem(12px);
                                height: 60px;
                            }
                        }

                        .readmore {

                            font-size: rem(13px);
                            font-weight: 700;
                            margin: 5px 0;
                        }

                        .room-bottom {

                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            flex-grow: 1;
                            width: 100%;

                            &.loading {

                                flex-direction: row;

                                .skk-loading {

                                    margin-left: 8px;
                                }
                            }
                        }

                        .night {
                            p {
                                @include break-from( medium ) {
                                    @include flex-container( $align: center );
                                }

                                span {
                                    @include flex-item($grow: 4);
                                }
                            }
                        }

                        .room-allowed {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            text-align: left;
                            padding-left: 0;
                            width: 100%;

                            &#special-promo {                                
                                span{
                                    a {
                                        color: $black;
                                        font-style: italic;                                                                            
                                    }
                                    a::after {
                                        border: 0;
                                    }
                                } 
                            }
 
                            @include break-from( medium ) {
                                padding-left: $spacer * 1.5;
                                padding-top: $spacer;
                            }

                            @include break-from( medium ) {
                                text-align: center;
                                padding-left: 0;
                                padding-top: 0;
                                // @include flex-container( $align: flex-end );
                                // justify-content: flex-end;
                                position: relative;
                                flex-grow: 1;
                                width: auto;
                            }

                            span {
                                @include flex-container( $align: center );
                                justify-content: flex-end;
                                font-size: rem(16px);
                                font-family: 'Montserrat';
                                font-weight: 700;
                                padding: 0 ($spacer * .2);

                                @include media-breakpoint-down(sm) {

                                    font-size: rem(14px);
                                }

                                @include media-breakpoint-down(xs) {

                                    font-size: rem(12px);
                                }
                            }

                            .cancellation-container {

                            //    margin-bottom: 10px;
                            }

                            .count-wrapper {

                                width: 100%;
                                align-items: flex-end;
                                justify-content: flex-end;
                                margin-left: 0;
                                margin-top: auto;
                                padding-top: 10px;

                                .hide-text,
                                span {

                                    position: relative;
                                    flex-shrink: 0;
                                    flex-direction: column;
                                    align-items: flex-end;

                                    i {

                                        position: absolute;
                                        right: 100%;
                                        width: 70px;
                                        font-size: rem(12px);
                                        font-weight: normal;
                                        text-align: right;
                                    }

                                    &.price {

                                        flex-direction: row;
                                        align-items: baseline;
                                    }

                                    .currency-name,
                                    a {

                                        padding: 0;
                                        flex-direction: row;
                                    }

                                    @include media-breakpoint-down(md) {

                                        i {
    
                                            display: none;
                                        }
                                    }
                                }

                                .currency-name {

                                    padding: 0;
                                    flex-direction: row;
                                }

                                small {

                                    margin-right: 5px;
                                }
                            }

                            .promo-title {

                                font-weight: 700;
                                position: initial;
                                top: initial;
                                left: initial;
                                min-width: initial;
                                width: initial;
                                background: none;
                                padding: 0;
                                color: $green-45;
                                flex-shrink: 0;
                                margin-right: 5px;
                                order: 1;
                                margin-top: 5px;

                                @include media-breakpoint-down(xs) {

                                    font-size: 11px;
                                    margin-top: 5px;
                                }
                            }

                            .promo-off {

                                font-weight: 700;
                                position: initial;
                                top: initial;
                                left: initial;
                                min-width: initial;
                                width: initial;
                                background: none;
                                padding: 0;
                                color: $green-45;
                                flex-shrink: 0;
                                order: 2;
                                margin-right: auto;
                                margin-top: 5px;

                                @include media-breakpoint-down(xs) {

                                    font-size: 11px;
                                    margin-top: 0;
                                }
                            }

                            .hide-text {

                                order: 3;

                                .price {

                                    padding: 0;
                                }
                            }

                            select,
                            #qty {

                                order: 4;
                                width: 66px;
                            }

                            .select {

                                order: 4;
                                margin-left: 8px;
                            }

                        }

                        #promo-rate {

                            display: flex;
                            flex-grow: 1;
                        }

                        #promo-rate,
                        #regular-rate {
                            width: 100%;

                            span {
                                a {
                                    position: relative;
                                    flex: 0 0 100%;
                                    text-align: right;

                                    &::after {

                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        width: 100%;
                                        height: 2px;
                                        content: '';
                                        border-top: 2px solid $red;
                                        z-index: 2;
                                    }

                                    &.non-refund,
                                    &.cancellation {
                                        font-size: $font-size-base * 0.75;
                                        text-decoration: inherit;
                                        color: $green-15;
                                        font-weight: 400;

                                        &.skeleton {
                                            margin-top: 5px;
                                        }

                                        @include break-until( medium ) {
                                            position: absolute;
                                            right: 0;
                                            bottom: -2em;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btn {
                        &.btn-primary {
                            &:hover {
                                color: $black !important;
                            }
                        }
                    }
                }
            }
        }

        .left {
            @include flex-item(3);
            min-width: 270px;

            @include break-from( medium ) {
                margin-top: 70px;
            }

            .currency-exchange {
                padding-bottom: 0;
                text-align: center;
                float: right;
                font-size: rem(13px);

                @include break-until( medium ) {
                    margin-top: 9px;
                }

                .currency {
                    padding: $spacer * .5;
                    border-radius: $spacer * .8;
                    border: 1px solid $gray;
                    background: $white;
                }
            }
        }
    }
}

.cart-information {
    
    font-size: rem(13px);
    clear: both;
    padding: 0;
    margin-bottom: $spacer;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 1px 1px $gray-400;
    width: 95%;
    z-index: 10;
    margin: 0 auto;
    top: 65px;
    left: 0;
    right: 0;
    background: $white; 
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);          
    z-index: 100;      

    &.active {
        overflow: visible; 

        .head {

            background: $light-gray !important;
            color: $gray-250;
        }
    }

    @include break-from( medium ) {
        right: 0;
        top: 100px;
        border-radius: 30px;
        box-shadow: 0 0 8px 1px rgba($black, 0.2);                    
        width: 100%;
    }

    #filter-calendar {
        cursor: pointer;
        padding-right: 12px;
        display: none;

        @include break-from( medium ) {
            display: inline-block;
        }
    }

    .head {
        padding: $spacer * .7;
        background: $light-gray;
        text-align: center;
        position: relative;
        padding-right: 50px;

        h2 {
            display: block;
            font-size: $font-size-base * 0.875 !important;
            font-weight: 700 !important;
            padding: 0;
            text-align: left;
            margin: 0;

            @include break-from( medium ) {
                display: none;
            }
        }

        a {
            &.more-order-detais {
                box-shadow: 1px 1px 1px 1px $dark-gray;
                border-radius: 15px;
                padding: 1px 4px;
                height: 26px;
                display: inline-block;
                position: absolute;
                right: 20px;
                top: 18px;
                background: $white;
                cursor: pointer;

                &.active { 
                    background: $yellow;
                    @include transform(translateY(-50%) rotate(180deg));
                }

                @include break-from( medium ) {
                    display: none;
                }
            }
        }

        p {
            font-size: rem(15px);
            font-weight: 700;
            margin: 0;

            &.search {
                font-size: $font-size-base * .7;

                a {
                    color: $black;
                }

                @include break-from( medium ) {
                    position: relative;
                    left: -4em;
                }
            }

            @include break-until( medium ) {
                font-weight: 400;
                font-size: rem(14px);
            }
        }

        @include break-until(medium) {

            background: $white;
            border-radius: 10px;

            .more-order-detais {

                display: flex !important;
                align-items: center;
                justify-content: center;
                width: 26px;
                height: 26px;
                background: $yellow !important;
                top: 50% !important;
                @include transform(translateY(-50%));
                box-shadow: 0 0 4px 0 rgba($black, 0.6) !important;
                color: $default-color;

                img {

                    width: 15px;
                    height: auto;
                    margin-top: 4px;
                }
            }
        }
    }

    .item-book {
        @include break-until( medium ) {
            display: none;

            &.hidden {

                display: none !important;
            }
        }
    }

    .policy-terms {
        font-size: rem(9px);
        border-top: 1px solid $gray;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0 !important;
        padding-right: 0 !important;

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    .tail {
        position: relative;
        background: $gray-250;
        padding: 20px;
        text-align: center;

        @include break-until( medium ) {
            display: none;
        }

        &.active {
            background: $yellow;

            a {

                cursor: pointer;
            }

            &:hover {

                background-color: darken($yellow, 5%);
            }
        }

        a {
            color: $black;
            font-size: rem(24px);
            font-family: 'Montserrat';
            font-weight: 900;
            cursor: default;
            
            &::before {

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: '';
                pointer-events: auto;
                background: transparent;
            }
        }
    }


    p {
        margin: 0;

        &.item-book {
            padding: 12px 15px;

            @include break-until( medium ) {
                padding-top: 7px;
                padding-bottom: 7px;
            }

            &:first-of-type {

                margin-top: 4px;

                @include break-until( medium ) {
                    margin-top: 0;
                }
            }

            &.border-top {
                border-top: 1px solid $gray;
            }

            &.label {

                border-top: 1px solid $gray;
                margin-top: 10px;
                padding-top: 19px;

                a {
                    color: $black; 
                }                            
            }

            &.promo {

                flex-wrap: wrap;

                @include break-from( medium ) {
                    display: flex;
                }

                .inputxx {

                    flex-grow: 1;

                    #promo-code {

                        width: calc(100% - 10px);
                    }
                }
            }

            .add-ons {

                + small {

                    .mm-orders {

                        font-weight: 700;
                    }
                }
            }

            small {
                font-size: 100%;
                display: flex;

                span {
                    font-style: normal;

                    a,
                    svg {
                        color: $red;
                    }
                }

                .mm-orders {

                    padding-left: 10px;
                    text-align: left;
                }

                .mm-amount {

                    margin-left: auto;
                    padding-left: 10px;
                }

                .add-ons {

                    font-weight: 700;                       
                }
            }

            &.promo {

                input {
                    border: 1px solid $gray;
                    border-radius: $spacer * 2;
                    padding: $spacer * .3;
                    font-size: $font-size-base * 0.75;
                    text-align: center;
                    flex-grow: 1;
                    margin-right: 10px;
                    text-transform: uppercase;
                }

                a {
                    border: 1px solid $gray-50;
                    border-radius: $spacer * 2;
                    padding: ($spacer * .3) ($spacer * .5);
                    float: right;
                    font-size: $font-size-base * 0.75;
                    text-align: center;
                    font-style: italic;
                    background: $gray-50;
                    color: $black;
                    cursor: pointer;

                    &.yellow {
                        background: $primary;
                        border: 1px solid $primary;
                    }
                }
            }

            &.check-in {
                display: none;

                @include break-from( medium ) {
                    display: block;
                }
            }

            &.check-out {
                display: none;

                @include break-from( medium ) {
                    display: block;
                }
            }
        }

        &.total {
            font-weight: 700;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-top: 10px;
            border-top: 1px solid $gray;
            margin-left: 15px;
            margin-right: 15px;
            padding-left: 0 !important;
            padding-right: 0 !important;

            @include break-from( medium ) {
                display: flex;
            }

            .total-label {

                display: inline-block !important;
                font-style: normal !important;
                margin-right: auto !important;
            }

            .xtotal {

                display: flex;
                flex-grow: 1;
                font-style: normal !important;
            }
        }

        &.destination-city,
        &.date,
        &.submit {
            border-bottom: 0;
        }

        &.notice {
            &.destination {
                padding: .5em 0 0;
                margin-bottom: 0;
                text-align: left;
                color: $red;
                margin-top: 0;
                font-size: $font-size-base;
                font-style: inherit;
                padding: 1em;

                b {
                    font-weight: 300;
                }
            }
        }

        &.last {
            border-bottom: 0;
        }

        span {
            display: block;
            font-style: italic;
            padding-left: 0;

            svg {
                color: $black;
                margin-left: $spacer * .1;
            }

            &.normal {
                text-align: left;
                font-style: inherit;
                color: $blue;
                font-size: $font-size-base * 0.75;
                display: none;

                @include break-from( medium ) {
                    display: inline-block;
                    padding-left: 5px;
                }
            }

            &.date {
                display: inline-block;
                font-family: 'Montserrat';
                font-style: inherit;
                float: right;
            }

            &.total-promo {
                display: inline-block;
                font-style: normal;
                font-size: inherit;
                padding-left: $spacer * .5;
                text-decoration: line-through;
            }

            &.notice {
                display: flex;
                align-items: baseline;
                line-height: normal;
                padding: .5em 0 0;
                margin-bottom: 0;
                text-align: left;
                color: $red;
                margin-top: 0;
                font-size: rem(12px);
                font-style: inherit;
                width: 100%;

                svg,
                i {
                    color: $red;
                    margin-right: 5px;
                }
            }

            &.notice-valid {
                padding: .5em 0 0;
                margin-bottom: 0;
                text-align: left;
                color: $dark-gray;
                margin-top: 0;
                font-size: $font-size-base;
                font-style: inherit;
            }

            &.float-left {
                font-size: inherit;
                font-style: inherit;

                .closed {

                    svg,
                    .fa-times {
                        color: $red;
                        margin-right: .5em;
                    }

                }
            }

            &.float-right {
                font-size: inherit;
                font-style: inherit;
                color: $green;
                margin-left: auto;
            }

            &.float-left {
                color: $green;
            }

        }

        a {
            &.closed {
                color: $red;

                &:hover {
                    color: $black;
                    cursor: pointer;
                }
            }
        }

        &.checkout-btn {
            text-align: center;
        }
    }

    h2 {
        padding-top: $spacer;
        padding-bottom: $spacer;
    }
}

.addReadMore.showlesscontent .SecSec,
.addReadMore.showlesscontent .readLess {
    display: none;
}

.addReadMore.showmorecontent .readMore {
    display: none;
}

.addReadMore .readMore,
.addReadMore .readLess {
    font-weight: bold;
    margin-left: 2px;
    color: $blue;
    cursor: pointer;
}

.addReadMoreWrapTxt.showmorecontent .SecSec,
.addReadMoreWrapTxt.showmorecontent .readLess {
    display: block;
}

#kl_newsletter_checkbox_field {

    width: 100%;
    float: right;
    margin-top: 10px;
    margin-bottom: 0;
}

.cart-information {
    
    .ui-datepicker-prev {
        left: 24px !important;
        top: 63px !important;
    }
    .ui-datepicker-next {
        right: 24px !important;
        top: 63px !important;
    }

    .ui-datepicker {

        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
}

.ui-datepicker-prev {

    text-indent: initial !important;
    width: 18px !important;
    height: 18px !important;

    span {

        display: none;
    }
}

.ui-datepicker-next {

    text-indent: initial !important;
    width: 18px !important;
    height: 18px !important;

    span {

        display: none;
    }
}


.swiper-holder-1 {

    .swiper-button-next,
    .swiper-button-prev {

        &.swiper-button-disabled {

            opacity: 0;
        }
    }

}

.sk-loading {

    .item-book {

        display: flex;
        padding-top: 7px !important;
        padding-bottom: 7px !important;

        &.policy-terms,
        &.total {

            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }

        &.promo,
        &.policy-terms {

            .skeleton {

                height: 30px;
            }
        }

        .skeleton {

            height: 22px;
            border-radius: 10px !important;

            &.lg {

                width: 75% !important;
            }

            &:first-child {

                width: 70%;
                margin-right: 10px;
            }

            &:last-child {

                flex-grow: 1;
                margin-left: auto;
            }
        }
    }
}

.cart-information {

    @include break-until(1053px) {

        &:not(.active) {

            .sk-loading {
    
                display: none !important;
            }
        }

        // .head + .item-book {

        //     display: none !important;
        // }
    }
}

.room-promos-holder {

    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-right: 65px;

    @include media-breakpoint-down(md) {

        padding-right: 0;
    }

    @include media-breakpoint-down(xs) {

        flex-direction: column;
        align-items: flex-start;
    }
}

.cancellation-container {

    flex-shrink: 0;
    width: 100%;
    text-align: left;

    @include media-breakpoint-down(xs) {
        
        .cancellation {

            font-size: rem(10px) !important;
        }
    }
    
    .cancellation {
        font-size: $font-size-base * 0.75;
        text-decoration: inherit;
        color: $green-15;
        font-weight: 400;
    
        &.skeleton {
            margin-top: 5px;
        }
    
        // @include break-until( medium ) {
        //     position: absolute;
        //     right: 0;
        //     bottom: -2em;
        // }
    }
}

.no-result-left {

    display: block;

    @include break-from(1053px) {
    
        display: none;
    }
}
