// ==========================================================================
// Search Results Styles
// ==========================================================================
.search-results {
    .site-content {
        margin-bottom: 6rem;

        .archive {
            main {
                &.blog-list {
                    flex: 0 0 70%;
                    height: 100%;
                    padding-top: 5.25rem;

                    @include break-until ( small ) {
                        flex: 1;
                    }

                    .result-heading {
                        width: 100%;
                        margin: 0 0.45rem;
                        font-family: 'Playfair Display';
                        font-style: italic;
                        font-size: 20px;
                        line-height: 27px;

                        .result-count {
                            font-weight: 400;
                        }

                        .search-item {
                            font-weight: 700;
                        }
                    }

                    article {

                        @include break-from ( medium ) {
                            flex: 0 0 47.8%;
                        }

                        @include break-until ( small ) {
                            max-height: 300px;
                            flex: 0 0 auto;
                        }

                        img {
                            width: 100%!important;
                        }

                        h2 {
                            font-family: 'Playfair Display';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 30px;
                            line-height: 35px;
                            top: 80%;
                            transform: translate(-50%, -80%);
                        }
                    }

                    nav {
                        &.pagination {
                            display: block;

                            .nav-links {
                                .page-numbers {
                                    &.current {
                                        background: $white;
                                        border: 0.5px solid $black;
                                        border-radius: 50%;
                                        padding: 5px 10px;
                                    }
                                }
                                .prev, .next {
                                    background: $white;
                                    border: 0.5px solid $black;
                                    border-radius: 20px;
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }

            .sidebar {
                .widget_recent_entries {
                    display: none;
                }
            }
        }
    }
}

.search-no-results {
    .site-content {
        margin-bottom: 6rem;

        .archive {
            main {
                &.blog-list {
                    .no-result {
                        width: 100%;

                        .backpacker-guide {
                            max-width: 640px;
                            margin: 0 auto;
                        }

                        .result-heading {
                            width: 100%;
                            margin-bottom: 1rem;
                            font-family: 'Playfair Display';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }

                    article {

                        img {
                            width: 100%!important;
                        }

                        h2 {
                            font-family: 'Playfair Display';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 30px;
                            line-height: 35px;
                            top: 80%;
                            transform: translate(-50%, -80%);
                        }
                    }

                    h3 {
                        font-family: 'Playfair Display';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 40px;
                        width: 100%;
                    }
                }
            }
        }
    }
}