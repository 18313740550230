// ==========================================================================
// Banner Styles
// ==========================================================================
// mobile debug condtion

.banner {
    overflow: hidden;
    
    &.revamp {
        overflow: visible;
        background: $gray-250;
    }

    .page-banner {

        .carousel-item  {
            img {
                height: 380px;
                object-fit: cover;

                @include break-until ( small ) {
                    height: auto;
                }

                @include break-from( medium ) {
                    height: inherit;
                }
            }
        }

        .carousel-caption  {
            padding-left: 0;
            padding-right: 0;
            bottom: 0;
            text-align: center;
            z-index: 9;

            @include break-from( medium ) {
                padding-left: $spacer * 3;
                padding-right: $spacer * 3;
                bottom: 0;
                right: 0;
                left: 0;
            }

            h2 {
                text-align: left;
                font-weight: 700;
                text-align: center;
                padding-top: $spacer * 3;
                font-size: $font-size-base * 2.125;

                @include break-from( medium ) {
                    text-align: left;
                    padding-top: 0;
                    font-size: $font-size-base * 2.5;
                    font-weight: 500;
                }
            }

            p {
                text-align: center;

                @include break-from( medium ) {
                    text-align: left;
                }

                .btn {
                    &.btn-primary {
                        font-size: $font-size-base * 1.0625;

                        @include break-from( medium ) {
                            font-size: inherit;
                        }
                    }
                }
            }
        }

        &.blog {
            position: relative;

            .carousel-item  {

                &:after {
                    content:"";
                    display:block;
                    position:absolute;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    background:rgba(0,0,0,0.4);
                }

                img {
                    height: 526px;
                    object-fit: cover;
                }
            }

            .carousel-caption  {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding-left: 15px;
                padding-right: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                h1 {
                    padding: 0;
                    margin: 0;
                    font-size: $font-size-base * 1.875;
                    // font-family: 'Playfair Display';
                    // font-weight: 400;
                    font-family: $font-family-base;
                    font-weight: 200;
                    color: $white;

                    @include break-from( medium ) {
                        font-size: $font-size-base * 3.75;
                    }
                }

                p {
                    text-align: center;
                    color: $white;
                }

                @include media-breakpoint-down(sm) {

                    p {

                        font-size: rem(14px);
                    }
                }
            }
        }
    }
}

